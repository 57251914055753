import BlockTypes from './BlockTypes';
import Wysh from './Wysh';

/**
 * OrderedWyshList provides a data structure with supporting methods for an ordered
 * list of Wyshes. Each Wysh has a nextWyshId member that is used to place the Wyshes
 * in the proper order in this class.
 *
 * The
 */

export default class OrderedWyshList
{
  constructor(wysh) {
    this.myWysh = null; // OPTIONAL: If this is the OrderedWyshList for a Block, this containing Wysh represents the continaing Block Wysh
    if (wysh) {
      this.myWysh = wysh;
    }

    this.wyshes = [];
    this.randomize = false;
    this.randomizedWyshes = [];
  }

  toJsonObject() {

    var wyshesAsJsonArray = [];
    for (const wysh of this.wyshes) {
      wyshesAsJsonArray.push(wysh.toJsonObject());
    }

    return {
      my_mysh_id: this.myWysh ? this.myWysh.wyshId : null,
      wyshes: wyshesAsJsonArray,
      randomize: this.randomize
    }
  }

  static removeDuplicates(wyshArray) {
    // remove duplicates
    const seen = new Set();

    let uniqueWyshes = wyshArray.filter(wysh => {
      const hash = wysh.wyshId;

      if (seen.has(hash)) {
        return false;
      }
      seen.add(hash);
      return true;
    });

    return uniqueWyshes;
  }

  isRandomized(randomize) {
    this.randomize = randomize;
  }

  contains(wysh) {

    var currentWysh = this.getHeadWysh();
    var found = false;

    var searchLimit = this.wyshes.length * 2;

    for (var i = 0;currentWysh !== null && found !== true && i < searchLimit; i++) {
      if (currentWysh.wyshId === wysh.wyshId) {
        found = true;
      }
      else {
        currentWysh = currentWysh.nextWysh;
      }
    }

    return found;
  }

  findWyshIndex(wysh) {
    var currentWysh = this.getHeadWysh();
    var found = false;

    var searchLimit = this.wyshes.length * 2;

    for (var i = 0;currentWysh !== null && found !== true && i < searchLimit; i++) {
      if (currentWysh.wyshId === wysh.wyshId) {
        found = true;
      }
      else {
        currentWysh = currentWysh.nextWysh;
      }
    }

    return i;
  }

  getWysh(productId) {
    for (const i in this.wyshes) {
      if (this.wyshes[i].product.productId === productId) {
        return this.wyshes[i]
      }
    }

    return null;
  }

  getUniqueParentWyshes(wyshes) {

    var parentWyshes = [];

    for (var i = 0; i < wyshes.length; i++) {
      if (wyshes[i].parentWysh) {
        parentWyshes.push(wyshes[i].parentWysh);
      }
    }

    var wyshIdArray = [];

    for (i = 0; i < wyshes.length; i++) {
      wyshIdArray.push(wyshes[i].wyshId);
    }

    var parentWyshArray = []

    for (i = 0; i < parentWyshes.length; i++) {
      if (wyshIdArray.includes(parentWyshes[i].wyshId)) {
        parentWyshArray.push(parentWyshes[i]);
      }
    }


    // remove duplicates
    const seen = new Set();

    let uniqueParentWyshes = parentWyshArray.filter(wysh => {
      const hash = wysh.wyshId;

      if (seen.has(hash)) {
        return false;
      }
      seen.add(hash);
      return true;
    });

    return uniqueParentWyshes;
  }

  _findChildWyshes(parentWyshArray, allWyshes) {

    var parentMap = new Map();

    for (var i = 0; i < parentWyshArray.length; i++) {
      parentMap.set(parentWyshArray[i].wyshId, []);
    }

    parentMap.set("mine", []);

    for (i = 0; i < allWyshes.length; i++) {
      if (allWyshes[i].parentWysh) {
        var childWyshesArray = parentMap.get(allWyshes[i].parentWysh.wyshId);
        if (childWyshesArray) {
          childWyshesArray.push(allWyshes[i]);
        }
        else {
          parentMap.get("mine").push(allWyshes[i]);
        }
      }
      else {
        parentMap.get("mine").push(allWyshes[i]);
      }
    }

    return parentMap;
  }

  setWyshes(wyshes) {

    // if I go through the wyshes and wyshes have parents that are not in the
    // array, I assume they belong to THIS wysh. The others are passed down to
    // the parent Wyshes present

    this.wyshes = [];
    // 1. get an array of parentWyshIds
    var uniqueParentWyshes = this.getUniqueParentWyshes(wyshes);
    var parentMap = this._findChildWyshes(uniqueParentWyshes, wyshes);
    var myWyshes = parentMap.get("mine");

    for (var i = 0; i < uniqueParentWyshes.length; i++) {
      var childWyshes = parentMap.get(uniqueParentWyshes[i].wyshId);
      uniqueParentWyshes[i].orderedWyshList.setWyshes(childWyshes);
    }

    // this.wyshes = wyshes;
    this.wyshes = myWyshes;

    if (this.randomize === true) {
      this.shuffle();
    }




    // for (var i = 0; i < this.wyshes.length; i++) {
    //   if (this.contains(this.wyshes[i]) === false) {
    //     // 1. get Tail Wysh
    //     var tailWysh = this.getTailWysh();
    //
    //     // 2. Make this Wysh the next Wysh of the now former tail wysh
    //     tailWysh.nextWysh = this.wyshes[i];
    //
    //     // 3. Set the previous Wysh to this NEW tail wysh to the former tail wysh
    //     // this.wyshes[i].previousWysh = tailWysh;
    //   }
    // }
  }

  findPreviousWysh(wysh) {
    // Go through all the Wyshes and find the Wysh whose nextWysh is the argument Wysh.
    for (var i = 0; i < this.wyshes.length; i++) {
       if (this.wyshes[i].nextWysh) {
         if (this.wyshes[i].nextWysh.equals(wysh)) {
           return this.wyshes[i];
         }
      }
    }

    return null;
    // Head Wysh
    // If the arg Wysh has a nextWysh and the arg Wysh is NOT a nextWysh for any other Wysh,
    // the arg Wysh is the HEAD.

    // Tail Wysh
    // If the arg Wysh does NOT have a nextWysh and another Wysh has the arg Wysh as its nextWysh,
    // the arg Wysh is the TAIL

  }

  logList() {
    var current = this.getHeadWysh();

    for(;(current !== null);) {
      current = current.nextWysh;
    }
  }

  _containsWysh(wysh, wyshes) {

    var exists = false;

    for (var i = 0; i < wyshes.length; i++) {
      if (wysh.wyshId === wyshes[i].wyshId) {
        exists = true;
        break;
      }
    }

    return exists;
  }

  getWyshes() {

    if (this.randomize === true) {

      var wyshes = [];

      // right here, we can apply the sub group and limit free response logic.
      if (this.myWysh && this.myWysh.issueSubGroups === true) {
        wyshes = this.randomizedWyshes.splice(0,this.myWysh.subGroupCount);
      }
      else {
        wyshes = this.randomizedWyshes;
      }

      if (this.myWysh && this.myWysh.limitFreeResponse === true) {
        if (this.myWysh.limitFreeResponseCount > this.myWysh.subGroupCount) {
          this.myWysh.limitFreeResponseCount = this.myWysh.subGroupCount;
        }

        let startingIndex = (wyshes.length - this.myWysh.limitFreeResponseCount < 0) ? 0 : wyshes.length - this.myWysh.limitFreeResponseCount;

        for (var i = startingIndex; i < wyshes.length; i++) {
          if (wyshes[i].freeResponseQuestions && wyshes[i].freeResponseQuestions.length > 0) {
            wyshes[i].gatherFreeResponse = true;
          }
        }
      }

      return wyshes
    }
    else {
      return this.getOrderedWyshes();
    }
  }

  getSortedWyshes(sortDirection) {

    var orderedWyshes = this.getOrderedWyshes();

    if (sortDirection === "sort" || sortDirection === "trueOrder") {
      return orderedWyshes;
    }

    var binaryWyhes = [];
    var scalarWyshes = [];
    var allWyshes = [];

    for (var i = 0; i < orderedWyshes.length; i++) {
      if (orderedWyshes[i].questionType && orderedWyshes[i].questionType === "scalar") {
        scalarWyshes.push(orderedWyshes[i]);
      }
      else {
        binaryWyhes.push(orderedWyshes[i]);
      }
    }

    if (sortDirection === "asc") {
      binaryWyhes.sort(Wysh.compareAverageNormalizedAsc);
      scalarWyshes.sort(Wysh.compareAverageNormalizedAsc);
    }
    else {
      binaryWyhes.sort(Wysh.compareAverageNormalizedDesc);
      scalarWyshes.sort(Wysh.compareAverageNormalizedDesc);
    }

    allWyshes = binaryWyhes.concat(scalarWyshes);

    return allWyshes;
  }

  getWyshesTwo(randomize, issueSubGroups, subGroupCount, limitFreeResponse, limitFreeResponseCount) {

    if (randomize === true) {

      var wyshes = [];

      // Gather the randomized wyshes based on if we are limiting the sub groups (batching)
      if (issueSubGroups === true) {
        wyshes = this.randomizedWyshes.splice(0, subGroupCount);
      }
      else {
        wyshes = this.randomizedWyshes;
      }

      if (limitFreeResponse === true) {

        var lfrc = limitFreeResponseCount;

        if (issueSubGroups && lfrc > subGroupCount) {
          lfrc = subGroupCount;
        }

        for (var i = wyshes.length - lfrc; i < wyshes.length; i++) {
          if (wyshes[i] &&wyshes[i].freeResponseQuestions && wyshes[i].freeResponseQuestions.length > 0) {
            wyshes[i].gatherFreeResponse = true;
          }
        }
      }
      else {
        for (var i = 0; i < wyshes.length; i++) {
          if (wyshes[i] &&wyshes[i].freeResponseQuestions && wyshes[i].freeResponseQuestions.length > 0) {
            wyshes[i].gatherFreeResponse = true;
          }
        }
      }

      return wyshes
    }
    else {
      return this.getOrderedWyshes();
    }
  }

  getOrderedWyshes() {

    var wyshes = [];

    var current = this.getHeadWysh();

    for(;(current !== null);) {
      if (this._containsWysh(current, wyshes) === false) {
        if (current.freeResponseQuestions && current.freeResponseQuestions.length > 0) {
          current.gatherFreeResponse = true;
        }
        wyshes.push(current);
        current = current.nextWysh;
      }
      else {
        // The current wysh is already in the array. Let's find the previous wysh and set his next
        // Wysh to null
        var tailWysh = this.findPreviousWysh(current);
        if (tailWysh) {
          tailWysh.nextWysh = null;
        }
        current = null;
        break;
      }
    }

    return wyshes;
  }

  /*
  moveWyshDown

  Moves the argument Wysh one place down the list of Wyshes. The
  Wysh is travelling away from the HEAD and toward the TAIL.

  Who needs to get updated?
  1. The Wysh who had the argument Wysh as his next Wysh
  2. The agument Wysh
  */
  moveWyshDown(wysh) {

    var mutatedWyshes = [];

    if (wysh.nextWysh && wysh.wyshRouter.isInBranchLogic(wysh.nextWysh) === false) {

      mutatedWyshes.push(wysh.nextWysh);

      // 1. Connect the previous Wysh to the Wysh in front of the agument Wysh
      var previousWysh = this.findPreviousWysh(wysh);
      if (previousWysh) {
        previousWysh.nextWysh = wysh.nextWysh;
        mutatedWyshes.push(previousWysh);
      }

      if (wysh.nextWysh.nextWysh) {
        // Set aside a reference to the Wysh in front of the Wysh in front of the argument Wysh
        var myNewNextWysh = wysh.nextWysh.nextWysh;

        // 2. Connect the next Wysh of the Wysh in front of the argument TO the argument Wysh
        wysh.nextWysh.nextWysh = wysh;

        // 3. Connect the argument Wysh's nextWysh to the Wysh that was in front of the Wysh in front of the argument Wysh
        wysh.nextWysh = myNewNextWysh;

        mutatedWyshes.push(wysh.nextWysh);
        mutatedWyshes.push(myNewNextWysh);
      }
      else {
        // End of the line! The argument Wysh becomes the TAIL (ie, nestWysh === null and some other Wysh has argument Wysh as his nextWysh)
        wysh.nextWysh.nextWysh = wysh;
        wysh.nextWysh = null;
      }

      mutatedWyshes.push(wysh);

    }

    return mutatedWyshes;
  }

  /*
  moveWyshUp

  Moves the argument Wysh one place up the list of Wyshes. The Wysh is
  travelling away from the TAIL and toward the HEAD.
  */
  moveWyshUp(wysh) {

    var mutatedWyshes = [];

    // Get the previous Wysh. If there is not a previous Wysh, the argument
    // Wysh is at the HEAD and this method does nothing.
    var previousWysh = this.findPreviousWysh(wysh);

    if (previousWysh) {

      mutatedWyshes.push(wysh, previousWysh);

      // Since the arg Wysh is not the HEAD, see if there is a Wysh before
      // the previous Wysh.
      var previousWyshPreviousWysh = this.findPreviousWysh(previousWysh);

      if (previousWyshPreviousWysh) {
        // Connect the Wysh before the Wysh before the arg Wysh to the arg Wysh
        previousWyshPreviousWysh.nextWysh = wysh;
        mutatedWyshes.push(previousWyshPreviousWysh);
      }

      // Since the arg Wysh is not the HEAD, connect the previous Wysh to the
      // next Wysh of the argument Wysh.
      var myNextWysh = wysh.nextWysh;
      previousWysh.nextWysh = myNextWysh;

      // Connect the arg Wysh's nextWysh to the previous Wysh.
      wysh.nextWysh = previousWysh;
    }

    return mutatedWyshes;
  }

  /**
  * Removes the argument Wysh from the OrderedWyshList:
  * 1. sets argument wysh nextWysh value to null
  * 2. sets argument wysh previousWysh value to null
  * 3. sets nextWysh of argument wysh's previous wysh to the argument wysh's nextWysh
  * for this Events content filter.
  *
  * @param wysh
  * @returns mutatedWyshes
  */
  remove(wysh) {

    var mutatedWyshes = [];

    if (this.contains(wysh) === false) {
      // Wysh not found. Nothing to do
      return [];
    }

    var previousWysh = this.findPreviousWysh(wysh);
    var nextWysh = wysh.nextWysh;

    if (previousWysh !== null) {
      previousWysh.nextWysh = nextWysh;
      mutatedWyshes.push(previousWysh);
    }

    wysh.nextWysh = null;
    wysh.parentWysh = null;

    // find the index for this wysh
    let pos = this.wyshes.map(function(w) { return w.wyshId; }).indexOf(wysh.wyshId);
    this.wyshes.splice(pos, 1);

    mutatedWyshes.push(wysh);
    return mutatedWyshes;
  }

  getAllDescendantWyshes() {

    var childArray = [];

    for (const child of this.wyshes) {
      childArray.push(child);
      childArray = childArray.concat(child.orderedWyshList.getAllDescendantWyshes());
    }

    return childArray
  }

  /**
  * Add the argument Wysh from the OrderedWyshList:
  *
  *
  * @param wysh
  * @returns mutatedWyshes
  */
  add(wysh) {

    var mutatedWyshes = [];

    // Remove the wysh from any current WyshList
    if (wysh.parentWysh) {
      // The Wysh was a member of a BLOCK
      var mutWyshes = wysh.parentWysh.orderedWyshList.remove(wysh);
      mutatedWyshes = mutatedWyshes.concat(mutWyshes);
    }
    else {
      // The Wysh was a member of the Swydget's top level OrderedWyshList
      mutWyshes = wysh.event.orderedWyshList.remove(wysh);
      mutatedWyshes = mutatedWyshes.concat(mutWyshes);
    }

    if (this.myWysh) {
      // we are the orderedWyshList for a BLOCK:
      wysh.parentWysh = this.myWysh;
    }
    else {
      wysh.parentWysh = null;
    }

    if (wysh.nextWysh) {
      console.log(wysh.nextWysh);
      console.log("splice the guy in");
    }

    var tailWysh = this.getTailWysh();
    if (tailWysh) {
      tailWysh.nextWysh = wysh;
      mutatedWyshes.push(tailWysh);
    }

    // wysh.nextWysh = this.getHeadWysh();
    this.wyshes.push(wysh);

    mutatedWyshes.push(wysh);
    return mutatedWyshes;
  }

  reorder(orderedWyshIdArray) {
    for (const wyshId of orderedWyshIdArray){
      console.log(wyshId);
    }
  }



  getHeadWysh() {
    var headWysh = null;

    for (var i =0; i < this.wyshes.length; i++) {
      if (this.findPreviousWysh(this.wyshes[i]) === null && this.wyshes[i].nextWysh !== null) {
        headWysh = this.wyshes[i];
      }
    }

    if (headWysh === null && this.wyshes.length > 0) {
      headWysh = this.wyshes[0];
    }

    return headWysh;
  }

  getTailWysh() {
    var tailWysh = this.getHeadWysh();

    if (tailWysh) {
      var searchLimit = this.wyshes.length * 2;

      for (var i = 0;(tailWysh.nextWysh !== null) && i < searchLimit; i++) {
        tailWysh = tailWysh.nextWysh;
      }
    }

    return tailWysh;
  }

  shuffle() {

    this.randomizedWyshes = this.wyshes.slice(0);

    var currentIndex = this.randomizedWyshes.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = this.randomizedWyshes[currentIndex];
      this.randomizedWyshes[currentIndex] = this.randomizedWyshes[randomIndex];
      this.randomizedWyshes[randomIndex] = temporaryValue;
    }
  }

  getFirstWysh() {
    if (this.randomize === true) {
      return this.randomizedWyshes[0];
    }
    else {
      return this.getHeadWysh();
    }
  }

  getNextWysh(wysh) {
    // if we are randomized, find the index of the argument wysh and return the next wysh in the array
    // or null if we are done
    if (this.randomize === true) {

      for (var i = 0; i < this.randomizedWyshes.length; i++) {
        if (wysh.wyshId === this.randomizedWyshes[i].wyshId) {
          if (i < this.randomizedWyshes.length - 1) {
            return this.randomizedWyshes[i + 1];
          }
          else {
            return null;
          }
        }
      }
    }
    else {
      return wysh.nextWysh;
    }
  }

  getFilteredSortedWyshes(sortDirection, filter) {

    var filteredOrderedWyshes = (filter) ? filter.filterContent(this.getOrderedWyshes()) : this.getOrderedWyshes();

    if (sortDirection === "sort" || sortDirection === "trueOrder") {
      return filteredOrderedWyshes;
    }

    if (this.myWysh && (this.myWysh.getBlockType().equals(BlockTypes.SEQUENTIALMONADIC))) {
      if (sortDirection === "asc") {
        return filteredOrderedWyshes.sort(Wysh.compareTopBoxAvgAsc);
      }
      else {
        return filteredOrderedWyshes.sort(Wysh.compareTopBoxAvgDesc);
      }      
    }
    else if (this.myWysh && (this.myWysh.getBlockType().equals(BlockTypes.MAXDIFF) || this.myWysh.getBlockType().equals(BlockTypes.PAIRWISE))) {
      if (sortDirection === "asc") {
        return filteredOrderedWyshes.sort(Wysh.compareAverageScoreAsc);
      }
      else {
        return filteredOrderedWyshes.sort(Wysh.compareAverageScoreDesc);
      }      
    }
    else {
      var binaryWyhes = [];
      var scalarWyshes = [];
      var allWyshes = [];

      for (var i = 0; i < filteredOrderedWyshes.length; i++) {
        if (filteredOrderedWyshes[i].questionType && filteredOrderedWyshes[i].questionType === "scalar") {
          scalarWyshes.push(filteredOrderedWyshes[i]);
        }
        else {
          binaryWyhes.push(filteredOrderedWyshes[i]);
        }
      }

      if (sortDirection === "asc") {
        binaryWyhes.sort(Wysh.compareAverageNormalizedAsc);
        scalarWyshes.sort(Wysh.compareAverageNormalizedAsc);
      }
      else {
        binaryWyhes.sort(Wysh.compareAverageNormalizedDesc);
        scalarWyshes.sort(Wysh.compareAverageNormalizedDesc);
      }

      allWyshes = binaryWyhes.concat(scalarWyshes);

      return allWyshes;
    }
  }

  verify() {

    var steadfast = true;

    if (this.getOrderedWyshes().length < this.wyshes.length) {
      steadfast = false;
    }

    if (steadfast) {
      for (const childWysh of this.wyshes) {
        if (steadfast && childWysh.orderedWyshList.verify() === false) {
          steadfast = false;
        }
      }
    }

    return steadfast;
  }

  repair() {

    var steadfast = true;

    if (this.getOrderedWyshes().length < this.wyshes.length) {
      this.repairBrokenList();
    }

    var childrenAreSteadfast = true;

    for (const childWysh of this.wyshes) {
      if (childWysh.orderedWyshList.verify() === false) {
        childWysh.orderedWyshList.repair();
      }
    }
  }

  repairBrokenList() {

    var newWorldOrder = [];
    var currentWysh = null;

    for (var i = 0; i < this.wyshes.length; i++) {
      if (i < this.wyshes.length - 1) {
        this.wyshes[i].nextWysh = this.wyshes[i + 1];
      }
      else {
        this.wyshes[i].nextWysh = null;
      }
    }


  }

  generateBlockId() {

    const seen = new Set();

    let uniqueBlocks = this.wyshes.filter(wysh => {
      const hash = wysh.product.productId;

      if (wysh.isBlock() === false) {
        return false;
      }

      if (seen.has(hash)) {
        return false;
      }
      seen.add(hash);
      return true;
    });

    var blockId = this.myWysh ? this.myWysh.product.productId + "-0" : "block-id-0"
    var blockIndex = 0;

    for (var i = 0; seen.has(blockId); i++) {
      // strip off everything after the last "-". This is the base id of the current block.
      var blockIdBase = blockId.substring(0, blockId.lastIndexOf("-"));
      blockIndex += 1;
      blockId = blockIdBase + "-" + blockIndex;
    }

    return blockId;
  }

  importFromJson(swydget, owlJson) {
    this.randomize = owlJson['randomize'] ? owlJson['randomize'] : false
    for (const wyshJson of owlJson['wyshes']) {
      this.wyshes.push(Wysh.importWyshFromJson(swydget, wyshJson))
    }
  }

  static importOWLFromJson(swydget, owlJson) {
    let owl = new OrderedWyshList();
    owl.importFromJson(swydget, owlJson);
    return owl;
  }


}
