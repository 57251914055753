import BlockType from "./BlockType";

export default class SequentialMonadicBlockType extends BlockType {

  constructor(blockTypeId, name) {
    super(blockTypeId, name);
    this.isQuantitativeAndQualitativeReadOnly = true;
  }

  appendBlock(block, stimuliArray) {

    const myArray = [
      "Q" + block.questionNumber,
      "",
      block.getWyshPrompt(),
      block.getQuestionType(),
      "",
      ""
    ];

    stimuliArray.push(myArray);
    stimuliArray.push([]); // Insert extra row after appending myself.

    const fow = block.getFilteredOrderedWyshes();

    if (block.questionType === "binary") {
      // Top Box 1
      for (const stimulus of fow) {
        this.appendBinaryTopBox(stimulus, stimuliArray);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
    }
    else {
      // Top Box 1
      for (const stimulus of fow) {
        this.appendTopBox(stimulus, stimuliArray, 1);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
      
      // Top Box 2 
      for (const stimulus of fow) {
        this.appendTopBox(stimulus, stimuliArray, 2);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
      
      // Top Box 3
      for (const stimulus of fow) {
        this.appendTopBox(stimulus, stimuliArray, 3);
      }
      stimuliArray.push([]); // Insert extra row after appending TopBox.
    }
    
    

    for (const stimulus of fow) {
      stimulus.appendMyself(stimuliArray);
    }
  }

  appendTopBox(stimulus, stimuliArray, count = 1) {
    const myArray = [
      "Q" + stimulus.questionNumber,
      stimulus.product.description,
      stimulus.getWyshPrompt(),
      stimulus.getQuestionType(),
    ];

    const snapshotKeys = Array.from(stimulus.event.snapshotContainer.snapshots.keys());
  
    let topBoxOptions = ""
    for (let i = count; i > 0; i--) {
      const optionIndex = 1 + stimulus.wyshOptions.length - i;
      topBoxOptions += optionIndex;
      if (i > 1){
        topBoxOptions += ", ";
      }
    }

    myArray.push(topBoxOptions);
    myArray.push("T" + count+ "B");

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      let totalDecisionCount = 0;
      
      if (stimulus.wyshOptions.length > 0) {
        for (let i = count; i > 0; i--) {
          totalDecisionCount += stimulus.wyshOptions[stimulus.wyshOptions.length - i].decisionsCount;
        }
      }
      
      myArray.push(totalDecisionCount);
    }

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      let totalDecisionCount = 0;

      if (stimulus.wyshOptions.length > 0) {
        for (let i = count; i > 0; i--) {
          totalDecisionCount += stimulus.wyshOptions[stimulus.wyshOptions.length - i].decisionsCount;
        }
      }

      myArray.push(totalDecisionCount / stimulus.takerCount);

    }

    stimuliArray.push(myArray);
  }

  appendBinaryTopBox(stimulus, stimuliArray) {
    const myArray = [
      "Q" + stimulus.questionNumber,
      stimulus.product.description,
      stimulus.getWyshPrompt(),
      stimulus.getQuestionType(),
    ];

    const snapshotKeys = Array.from(stimulus.event.snapshotContainer.snapshots.keys());
  
    myArray.push("1");
    myArray.push("T1B");

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      myArray.push(stimulus.aggregatedResults.likesCount);
    }

    for (const snapshotKey of snapshotKeys) {
      const snapshot = stimulus.event.snapshotContainer.snapshots.get(snapshotKey);
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
      }

      myArray.push(stimulus.aggregatedResults.likesCount / stimulus.takerCount);

    }

    stimuliArray.push(myArray);
  }


}