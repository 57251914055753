import dispatcher from "../../Dispatcher";
import axios from "axios";
import MeService from "../../services/MeService";
import Decision from "../../classes/Decision";

const AdminActionsHook = () => {

  /**
  * deleteSwydget
  *
  * Delete the event as far as the user nows. It does not actually delete the Swydget/Event.
  * It removes all references of the Swydget/Event from the Creator's profile. It essentially
  * places the Swydget/Event in limbo
  *
  * @param user
  * @param event
  */
  const deleteSwydget = (user, serverUrl, navigationHandler, swydget) => {

    const endpoint = "/1/events/event/" + swydget.eventId;
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    dispatcher.dispatch({
      type: "ME_DELETE_SWYDGET"
    });

    const clearText = user.userId + ":" + user.password;
    const base64String = "Basic " + btoa(clearText);
  
    var config = {
      headers: {'Authorization': base64String}
    };


    axios.delete(serverUrl + encodedEndpoint, config)
      .then((data) => {

        dispatcher.dispatch({
          type: "ME_DELETE_SWYDGET_SUCCESSFUL",
          eventId: swydget.eventId
        });

        navigationHandler("/");
      }
    )
    .catch(function (error) {
      console.log("error");
    });
  }

  const getDecisions = (user, swydget, getDecisionsCallback) => {

    const endpoint = "/1/events/event/" + swydget.eventId + "/get_swydget_decisions";
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    const clearText = user.userId + ":" + user.password;
    const base64String = "Basic " + btoa(clearText);

    var config = {
      headers: {'Authorization': base64String}
    };
    
    var parametersJson = {
      "filter":  swydget.filter.toJson()
    }
    
    axios.post(MeService.serverUrl + ":" + MeService.wm_new_port + encodedEndpoint, parametersJson, config)
      .then((data) => {

        var decisionsReceivedArray = [];

        for (var i = 0; i < data.data.results.result_set.length; i++) {
          decisionsReceivedArray.push(Decision.createFromJson(data.data.results.result_set[i]));
        }

        getDecisionsCallback(decisionsReceivedArray);

        

        // dispatcher.dispatch({
        //   type: "ME_GET_DECISIONS_SUCCESSFUL",
        //   decisionsArray: decisionsReceivedArray
        // });
      }
    )
    .catch(function (error) {
      console.log(error);
    });
  }

  

  return {
    deleteSwydget, getDecisions
  }
}

export default AdminActionsHook;