import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import SwytchbackTextField from '../controls/SwytchbackTextField';
import classes from "../../../css/modules/BuildModals.module.css";
import "../../../css/swytchbackModal.css"
import "../../../css/swytchbackStyles.css"
import SocialSharingChannel from "../../../components/SocialSharingChannel";
import { Switch } from '@mui/material';
import SwydgetContestEntry from '../../../components/swydget/SwydgetContestEntry';

const IncentiveModal = ({
  showIncentiveModal,
  closeIncentiveModal,
  contestPrizeText, 
  handleContestPrizeTextChange, 
  callToActionInstructionText, 
  handleCallToActionInstructionsTextChange, 
  callToActionSubmitButtonText, 
  handleCallToActionSubmitButtonTextChange,
  showSwytchbackTOS,
  handleShowSwytchbackTOSClicked,
  swydget,
  handleApplyIncentiveClicked,
  swydgetTOSLink,
  handleSwydgetTOSLinkChange
}) => {

  return (

    <Modal
      onHide={closeIncentiveModal}
      size="xl"
      show={showIncentiveModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Incentive Page</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="welcome-page-tabs">
            <Tab eventKey={1} title="Text">
              <div className="columnCenteredContainer">
                <SwytchbackTextField
                  style={{width: '70%', marginTop: 30}}
                  id="contestPrizeTextTF"
                  value={contestPrizeText}
                  onChange={handleContestPrizeTextChange}
                  margin="normal"
                  variant="outlined"
                  label="Incentive Primary Text"
                />
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="callToActionInstructionTextTF"
                  value={callToActionInstructionText}
                  onChange={handleCallToActionInstructionsTextChange}
                  margin="normal"
                  variant="outlined"
                  label="Incentive Secondary Text"
                />
                <SwytchbackTextField
                  style={{width: '70%', marginBottom: 30}}
                  id="callToActionSubmitButtonTextTF"
                  value={callToActionSubmitButtonText}
                  onChange={handleCallToActionSubmitButtonTextChange}
                  margin="normal"
                  variant="outlined"
                  label="Incentive Button Text"
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Legal">
              <div className="columnCenteredContainer">
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    Show Swytchback Terms of Service
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={showSwytchbackTOS}
                      onChange={handleShowSwytchbackTOSClicked}
                      color="primary"
                    />
                  </div>
                </div>
                <SwytchbackTextField
                  style={{width: '70%', marginBottom: 30}}
                  id="swydgetTOSLinkTextTF"
                  value={swydgetTOSLink}
                  onChange={handleSwydgetTOSLinkChange}
                  margin="normal"
                  variant="outlined"
                  label="Swydget Terms of Service"
                />
              </div>              
            </Tab>
            <Tab eventKey={3} title="Preview">
              <SwydgetContestEntry eventInFocus={swydget} height={70} />
            </Tab>
          </Tabs>  
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={handleApplyIncentiveClicked}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
    
  );

}

export default IncentiveModal;
  



