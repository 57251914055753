import React, { useState } from "react";
import { Button } from 'react-bootstrap';
import classes from '../../../css/modules/StimulusVerbatimComponent.module.css';
import '../../../css/swytchbackModal.css';
import SwytchbackTextField from "../controls/SwytchbackTextField";
import SwytchbackFrameButton from "../controls/SwytchbackFrameButton";
import BlockTypes from "../../../classes/BlockTypes";


export default function StimulusVerbatimComponent(props) {

  const updateStimulusCallback = props.updateStimulusallback;

  const [localStimulus, setLocalStimulus] = useState(props.stimulus);

  const handleFreeResponseQuestionChange = (evt) => {
    const stimulus = localStimulus;
    if (stimulus.freeResponseQuestions.length > 0) {
      stimulus.freeResponseQuestions[0] = evt.target.value;
    }
    else {
      stimulus.freeResponseQuestions = [evt.target.value];
    }
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const toggleFreeResponseEnabled = (wysh, optionValue) => {
    const stimulus = localStimulus;
    var newOptionsArray = stimulus.hiddenFreeResponseOptions;

    if (newOptionsArray.includes(optionValue)) {
      newOptionsArray.splice(newOptionsArray.indexOf(optionValue), 1);
    }
    else {
      newOptionsArray.push(optionValue);
    }

    stimulus.hiddenFreeResponseOptions = newOptionsArray;
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const clear = () => {
    const stimulus = localStimulus;
    stimulus.freeResponseQuestions = [];
    stimulus.hiddenFreeResponseOptions = [];
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const generateFreeResponseCheckboxes = () => {

    const stimulus = localStimulus;

    // Display Logic: show if response =

    // Here, I have a wysh. That wysh has a filter. That filter tells me
    // if this wysh should have it's box checked and which box should be checked

    var checkboxes = [];

    if (stimulus.questionType === "binary") {

      checkboxes.push(
        <div key={"hide-free-response-" + 0} className="rowContainerSpaced marginBottom">
          <SwytchbackTextField
            id={"hide-free-response-" + 0}
            style={{ width: '70%' }}
            value={"No"}
            margin="normal"
            variant="outlined"
          />
          <div style={{ width: '25%' }} className="rowContainer">
            <Button
              className={!(stimulus.hiddenFreeResponseOptions.includes(0)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"}
              style={{ width: '50%' }}
              active={!(stimulus.hiddenFreeResponseOptions.includes(0) ? true : false)}
              onClick={(e) => toggleFreeResponseEnabled(stimulus, 0, e)}
              disabled={stimulus.isQuantitativeAndQualitativeReadOnly()}>
              Yes
            </Button>
            <Button
              className={(stimulus.hiddenFreeResponseOptions.includes(0)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"}
              style={{ width: '50%' }}
              active={!(stimulus.hiddenFreeResponseOptions.includes(0) ? true : false)}
              onClick={(e) => toggleFreeResponseEnabled(stimulus, 0, e)}
              disabled={stimulus.isQuantitativeAndQualitativeReadOnly()}>
              No
            </Button>
          </div>
        </div>
      );
      checkboxes.push(
        <div key={"hide-free-response-" + 1} className="rowContainerSpaced marginBottom">
          <SwytchbackTextField
            id={"hide-free-response-" + 1}
            style={{ width: '70%' }}
            value={"Yes"}
            margin="normal"
            variant="outlined"
          />
          <div style={{ width: '25%' }} className="rowContainer">
            <Button
              className={!(stimulus.hiddenFreeResponseOptions.includes(1)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"}
              style={{ width: '50%' }}
              active={!(stimulus.hiddenFreeResponseOptions.includes(1) ? true : false)}
              onClick={(e) => toggleFreeResponseEnabled(stimulus, 1, e)}
              disabled={stimulus.isQuantitativeAndQualitativeReadOnly()}>
              Yes
            </Button>
            <Button
              className={(stimulus.hiddenFreeResponseOptions.includes(1)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"}
              style={{ width: '50%' }}
              active={!(stimulus.hiddenFreeResponseOptions.includes(1) ? true : false)}
              onClick={(e) => toggleFreeResponseEnabled(stimulus, 1, e)}
              disabled={stimulus.isQuantitativeAndQualitativeReadOnly()}>
              No
            </Button>
          </div>
        </div>
      );
    }
    else if (stimulus.questionType === "scalar" || stimulus.questionType === "multiple-choice" || stimulus.questionType === "single-select" || stimulus.questionType === "nps" || stimulus.questionType === "csat") {
      for (var i = 0; i < stimulus.wyshOptions.length; i++) {

        let score = stimulus.wyshOptions[i].resultNormalized;

        checkboxes.push(
          <div key={"hide-free-response-" + i} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              id={"hide-free-response-" + i}
              style={{ width: '70%' }}
              value={(i + 1) + ": " + stimulus.wyshOptions[i].resultLiteral}
              margin="normal"
              variant="outlined"
            />
            <div style={{ width: '25%' }} className="rowContainer">
              <Button
                className={!(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"}
                style={{ width: '50%' }}
                active={!(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized) ? true : false)}
                onClick={(e) => toggleFreeResponseEnabled(stimulus, score, e)}
                disabled={stimulus.isQuantitativeAndQualitativeReadOnly()}>
                Yes
              </Button>
              <Button
                className={(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"}
                style={{ width: '50%', borderLeft: '1px solid white' }}
                active={!(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized) ? true : false)}
                onClick={(e) => toggleFreeResponseEnabled(stimulus, score, e)}
                disabled={stimulus.isQuantitativeAndQualitativeReadOnly()}>
                No
              </Button>
            </div>
          </div>
        );
      }
    }

    return <div className="columnContainer">{checkboxes}</div>;
  }

  return (
    <div id="stimulusVerbatimComponent" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="hookContentContainer column">
        <div className="widget fullWidth">
          <div className='title marginBottom'>
            Verbatim Prompt:
          </div>
          <div className='content'>
            <div className="swytchbackAdminControl">
              <SwytchbackTextField
                style={{ width: '100%' }}
                id="verbatim-input"
                value={localStimulus.freeResponseQuestions.length > 0 ? localStimulus.freeResponseQuestions[0] : ""}
                onChange={handleFreeResponseQuestionChange}
                margin="normal"
                variant="outlined" 
                placeholder="Prompt..."
                disabled={localStimulus.isQuantitativeAndQualitativeReadOnly()}
              />
            </div>
          </div>
        </div>
        <div className="widget fullWidth">
          <div className="titleContainer">
            <div className='title'>
              Verbatim Display Logic:
            </div>
            <div className='instruction'>
              Display verbatim if response option selected
            </div>
          </div>
          <div className='content'>
            <div className="swytchbackAdminControl">
              {generateFreeResponseCheckboxes()}
            </div>
            <div>
              <SwytchbackFrameButton style={{ width: '20%' }} onClick={clear} disabled={localStimulus.isQuantitativeAndQualitativeReadOnly()}>Clear</SwytchbackFrameButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}