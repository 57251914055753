import dispatcher from "../Dispatcher";
import { useNavigate } from 'react-router';
import swydgetStore from "../stores/SwydgetStore";
import Event from "../classes/Event";
import Wysh from "../classes/Wysh";
import WyshMeProduct from "../classes/WyshMeProduct";
import WyshWordFrequency from "../classes/WyshWordFrequency";
import ProductStyles from "../classes/ProductStyles";
import Decision from "../classes/Decision";
import BranchLogicStatement from "../classes/BranchLogicStatement";
import SkipRule from "../classes/SkipRule";
import axios from "axios";
import pako from 'pako';
import axiosRetry from 'axios-retry';
import Auth from "../services/AuthService";
import * as Utilities from "../services/Utilities";
import MaxDiffVersion from "../classes/maxdiff/MaxDiffVersion";
import BlockTypes from "../classes/BlockTypes";
import PairwiseVersion from "../classes/pairwise/PairwiseVersion";


axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });


/*
getEvents

Returns the list of surveys the user has published.
*/
export function getEvents(user, passwordSha256, serverUrl, workspace, sortOrder="edited-recent", firstIndex=null, lastIndex=null, filterTerm=null, ownerFilter="all") {

  var endpoint = "/1/events/?workspace=" + workspace + "&sort_order=" + sortOrder;

  if (filterTerm) {
    endpoint += "&filter_term=" + encodeURIComponent(filterTerm);
  }

  if (ownerFilter) {
    endpoint += "&owner_filter=" + ownerFilter;
  }

  dispatcher.dispatch({
    type: "ME_FETCH_MY_EVENTS"
  });

  const clearText = user.userId + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var rangeString = "entities=" + (firstIndex ? firstIndex : 0) + "-" + (lastIndex ? lastIndex : 9);
  var rangedConfig = {
    headers: {'Authorization': base64String, 'range': rangeString}
  };

  axios.get(serverUrl + endpoint, rangedConfig)
    .then((fetchEventsResponse) => {

    const { results } = fetchEventsResponse.data;

    dispatcher.dispatch({
      type: "ME_FETCH_MY_EVENTS_SUCCESSFUL",
      myEventsJson: results,
      myEventsCurrentWorkspaceTotalSize: fetchEventsResponse.data.range_info ? fetchEventsResponse.data.range_info["count"] : 0
    });
  }).catch(function (error) {
    console.log(error);
  });
}

/*
getProducts

Get the WyshMeProducts used in a users Surveys. They might not be created by the
user, but rather by another user.

GET 1/events/
*/
export function getProducts(user, passwordSha256, serverUrl, firstIndex, lastIndex, sortOrder) {

  const endpoint = "/1/my_products?sort_order=" + sortOrder;

  const clearText = user.userId + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var rangeString = "entities=" + firstIndex + "-" + lastIndex;
  var rangedConfig = {
    headers: {'Authorization': base64String, 'range': rangeString}
  };

  // var parametersJson = {
  //   "query": "select prd_uuid, " +
  //            "prd_name, " +
  //            "prd_merchant, " +
  //            "prd_description, " +
  //            "prd_price, " +
  //            "prd_currency, " +
  //            "prd_buy_url, " +
  //            "prd_image_url, " +
  //            "prd_sku, " +
  //            "prd_es_score, " +
  //            "prd_origin_type, " +
  //            "prd_origin_uuid, " +
  //            "prd_origin_seq, " +
  //            "prd_saved, " +
  //            "prd_creator_mid, " +
  //            "prd_created, " +
  //            "prd_updated, " +
  //            "prd_attributes_json, " +
  //            "prd_styles_json " +
  //            "from prd " +
  //            "where prd_creator_mid='" + user.myMid + "'"
  // }

  axios.get(serverUrl + endpoint, rangedConfig)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_FETCH_PRODUCTS_SUCCESSFUL",
        products: data.data.results.result_set,
        myProductsTotalSize: data.data.fullLength
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
getProductsByMid

Get the WyshMeProducts created by the argument creator. This is used to get all the
products of another user.

TODO: replace this with an API of its own to fetch the recommended products created by a given user.

GET 1/events/
*/
export function getProductsByMid(serverUrl, creatorMid) {

  var userId = "test105@catboytech.com";
  var passwordSha256 = "6fec2a9601d5b3581c94f2150fc07fa3d6e45808079428354b868e412b76e6bb";

  const endpoint = "/1/products/get_products_by_mid/" + creatorMid;

  const clearText = userId + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  axios.get(serverUrl + endpoint, config)
    .then((data) => {
      dispatcher.dispatch({
        type: "ME_FETCH_PRODUCTS_BY_MID_SUCCESSFUL",
        products: data.data.results.result_set
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

export function loadWyshes(user, passwordSha256, serverUrl, myEventsJson, surveyResults, wyshDecisionsResults) {

  const clearText = user.userId + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var eventsJsonArray = myEventsJson['events'];
  var requestsArray = [];
  for (var i = 0; i < eventsJsonArray.length; i++) {
    var eventId = eventsJsonArray[i]['_id'];
    if (Utilities.startsWith(eventId, "me-") === false) {
      if ((Utilities.endsWith(eventId, "#") === false) && (Utilities.endsWith(eventId, "%23") === false)) {
        eventId += "#";
      }

      let encodedEventId = eventId.replace("#", "%23");
      requestsArray.push(axios.get(serverUrl + "/1/events/event/" + encodedEventId, config));
    }
  }

  var myEventsLoadedWithWyshesJsonArray = [];

  axios.all(requestsArray)
    .then(axios.spread((...args) => {
      for (let i = 0; i < args.length; i++) {
        myEventsLoadedWithWyshesJsonArray.push(args[i].data['result']['event']);
      }

      dispatcher.dispatch({
        type: "ME_FETCH_MY_EVENTS_WITH_WYSHES_SUCCESSFUL",
        myEventsJson: {"events": myEventsLoadedWithWyshesJsonArray},
        surveyResultsJson: wyshDecisionsResults
      });
    }))
    .catch(function (error) {
      console.log(error);
    });
}

/*
getEvent

Returns a particular Event/Survey
*/
export function getEvent(username, passwordSha256, serverUrl, eventId, swydgetTakerUserKey=null) {

  var eventIdArg = eventId;
  if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
    eventIdArg += "#";
  }

  const encodedEventId = eventIdArg.replace("#", "%23");
  const endpoint = "/1/events/event/" + encodedEventId;

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  axios.get(serverUrl + endpoint, config)
    .then((data) => {

      var e = data.data.result.event;
      var eventInFocus = Event.createFromJsonAndResults(e, []);

      dispatcher.dispatch({
        type: "ME_FETCH_EVENT_SUCCESSFUL",
        eventInFocus: eventInFocus
      });
    }
  )
  .catch(function (error) {
    console.log(error);
    const navigate = useNavigate();
    navigate("/swydget/not_available");
  });
}

/*
getSurveyTakers

Retrieves the contact info for the survey takers for a given Survey.

GET /1/events/event/evt.00000000000003ec-00000021%23/takers/
*/
export function getSurveyTakers(username, passwordSha256, serverUrl, surveyId) {

  var eventIdArg = surveyId;
  if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
    eventIdArg += "#";
  }

  const endpoint = "/1/events/event/" + eventIdArg + "/takers/";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  axios.get(serverUrl + encodedEndpoint, config)
    .then((data) => {

        dispatcher.dispatch({
          type: "ME_FETCH_EVENT_SURVEY_TAKERS_SUCCESSFUL",
          eventId: surveyId,
          surveyTakers: data.data.results.survey_takers
        });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
createSurvey

Creates a bakery fresh Survey
*/
export function createSurvey(username, passwordSha256, serverUrlNew, serverUrlOld, navigationHandler, surveyName, defaultPrompt, createSurveyJson, binaryIconSetID) {

  const endpoint = "/event_list/create";

  dispatcher.dispatch({
    type: "ME_CREATE_SURVEY"
  });

  // const clearText = username + ":" + passwordSha256;
  // const buffer = Buffer.from(clearText, "utf-8");
  // const base64String = "Basic " + buffer.toString('base64');


  var config = {
    //headers: {'Authorization': base64String}
  };

  axios.put(serverUrlOld + endpoint, createSurveyJson, config)
    .then((data) => {

      let e = Event.createFromJson(data.data.results.event);
      completeCreatingSurvey(username, passwordSha256, serverUrlNew, navigationHandler, e, surveyName, defaultPrompt, "binary", [], binaryIconSetID, "");

    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
createScalarSurvey

Creates a bakery fresh Scalar Survey.
*/
export function createScalarSurvey(username, passwordSha256, serverUrlNew, serverUrlOld, surveyName, defaultPrompt, createSurveyJson, scalarDescription, options) {

  // This is really a 2 part process
  // 1. Create the Event/Survey
  // 2. Update the type to Scalar and pass in the default options

  const endpoint = "/event_list/create";

  // const clearText = username + ":" + passwordSha256;
  // const buffer = Buffer.from(clearText, "utf-8");
  // const base64String = "Basic " + buffer.toString('base64');


  var config = {
    //headers: {'Authorization': base64String}
  };

  axios.put(serverUrlOld + endpoint, createSurveyJson, config)
    .then((data) => {

      // Victory! The basic binary survey has been created.
      // Now, let's update it's type to scalar and pass in the default options

      let e = Event.createFromJson(data.data.results.event);

      completeCreatingSurvey(username, passwordSha256, serverUrlNew, e, surveyName, defaultPrompt, "scalar", options, "", scalarDescription);
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
createForcedRankSurvey

Creates a bakery fresh Forced Rank Survey.
*/
export function createForcedRankSurvey(username, passwordSha256, serverUrlNew, serverUrlOld, surveyName, defaultPrompt, createSurveyJson) {

  // This is really a 2 part process
  // 1. Create the Event/Survey
  // 2. Update the type to Scalar and pass in the default options

  const endpoint = "/event_list/create";

  // const clearText = username + ":" + passwordSha256;
  // const buffer = Buffer.from(clearText, "utf-8");
  // const base64String = "Basic " + buffer.toString('base64');


  var config = {
    //headers: {'Authorization': base64String}
  };

  axios.put(serverUrlOld + endpoint, createSurveyJson, config)
    .then((data) => {


      // Victory! The basic binary survey has been created.
      // Now, let's update it's type to scalar and pass in the default options

      let e = Event.createFromJson(data.data.results.event);

      completeCreatingSurvey(username, passwordSha256, serverUrlNew, e, surveyName, defaultPrompt, "forced_rank", [], "", "");
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
completeCreatingSurvey

PRIVATE!!!
Only needed to finish setting the survey type and options

Updates an basic binary Event/Survey to be a different type of Survey
*/
export function completeCreatingSurvey(username, passwordSha256, serverUrl, navigationHandler, event, surveyName, defaultPrompt, surveyType, options, binaryIconSetID, scalarDescription, mcSingleInstruction="", mcMultipleInstruction="") {

  event.surveyType = surveyType;
  event.surveyName = surveyName;
  event.options= options;

  var updateSurveyJson = {
    "req_auth_token": "",
    "survey_name": surveyName,
    "survey_type": surveyType,
    "default_prompt": defaultPrompt,
    "options": options,
    "binary_icon_set_id": binaryIconSetID,
    "scalar_description": scalarDescription,
    "mc_single_instruction": mcSingleInstruction,
    "mc_multiple_instruction": mcMultipleInstruction
  };

  // const endpoint = "/event/update";
  const endpoint = "/1/events/event/" + event.eventId;
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  axios.post(serverUrl + encodedEndpoint, updateSurveyJson, config)
    .then((data) => {

        dispatcher.dispatch({
          type: "ME_CREATE_SURVEY_SUCCESSFUL",
          eventId: event.eventId,
          event: event
        });

        navigationHandler("/survey/" + event.eventId);
    }
  )
  .catch(function (error) {
    console.log(error);
  });

}

/*
cloneSwydget(user, swydget)

Creates a duplicate Swydget of the argument Swydget and loads it with the products of the
argument Swydget. It also duplicates all of the creative content of the argument Swydget
(wallpaper, cover photo, social networks, etc.)
*/
export function cloneSwydget(user, serverUrlNew, serverUrlOld, swydget) {

  var userJson = user.addressBookJson.contacts[0];

  // TODO: Clean this crap up
  userJson["isFriend"] = userJson["is_friend"];
  userJson["displayName"] = userJson["display_name"];
  userJson["firstName"] = userJson["first_name"];
  userJson["lastName"] = userJson["last_name"];
  userJson["ownerMid"] = userJson["owner-mid"];

  var createSurveyJson = {
    "address_book": user.addressBookJson,
    "user-id": userJson,
    "event_info": {
      name : swydget.title,
      note : "",
      invited : [

      ],
      when : "",
      where : ""
    }
  };


  const endpoint = "/event_list/create";

  dispatcher.dispatch({
    type: "ME_CLONE_SWYDGET_STARTED"
  });

  // const clearText = username + ":" + passwordSha256;
  // const buffer = Buffer.from(clearText, "utf-8");
  // const base64String = "Basic " + buffer.toString('base64');


  var config = {
    //headers: {'Authorization': base64String}
  };

  axios.put(serverUrlOld + endpoint, createSurveyJson, config)
    .then((data) => {

      let clonedSwydget = Event.createFromJson(data.data.results.event);

      completeCloningSwydget(user, serverUrlNew, clonedSwydget, swydget);

    }
  )
  .catch(function (error) {
    console.log(error);
  });

}

/*
completeCloningSwydget

PRIVATE!!!
Copy salient attributes from the original Swydget to the Clone

*/
export function completeCloningSwydget(user, serverUrl, clonedEvent, originalEvent) {

  var d = new Date();
  var dateString = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate() + "-" + d.getHours() + ":" + d.getMinutes();

  var updateSurveyJson = {
    "req_auth_token": "",
    "title": originalEvent.title,
    "description": originalEvent.description,
    "default_prompt": originalEvent.defaultPrompt,
    "preview_title": originalEvent.previewTitle,
    "preview_description": originalEvent.previewDescription,
    "reward_text": originalEvent.rewardText,
    "reward_url": originalEvent.rewardUrl,
    "contest_prize_text": originalEvent.contestPrizeText,
    "call_to_action_instructions_text": originalEvent.callToActionInstructionText,
    "call_to_action_submit_button_text": originalEvent.callToActionSubmitButtonText,
    "user-id": user.addressBookJson["contacts"]["0"],
    "randomize": originalEvent.randomize,
    "issue_sub_groups": originalEvent.issueSubGroups,
    "sub_group_count": originalEvent.subGroupCount,
    "limit_free_response": originalEvent.limitFreeResponse,
    "limit_free_response_count": originalEvent.limitFreeResponseCount,
    "get_started_text": originalEvent.getStartedText,
    "tos_link": originalEvent.tosLink,
    "privacy_link": originalEvent.privacyLink,
    "reward_button_text": originalEvent.rewardButtonText,
    "show_results": originalEvent.showResults,
    "show_recommendations": originalEvent.showRecommendations,
    "location_enabled": originalEvent.locationEnabled,
    "social_sharing_channels": originalEvent.socialSharingChannels,
    "company_logo_url": originalEvent.getDefaultCardBackgroundUrl,
    "cover_photo_url": originalEvent.coverPhotoUrl,
    "wallpaper_url": originalEvent.wallpaperUrl,
    "front_image": originalEvent.frontImage,
    "survey_name": dateString + " - " + originalEvent.surveyName,
    "survey_type": originalEvent.surveyType,
    "options": originalEvent.options,
    "binary_icon_set_id": originalEvent.binaryIconSetID,
    "default_product_str": originalEvent.defaultProduct.toJsonString(),
  };

  // UPDATE THE FRESHLY CREATED EVENT WITH THE ORIGINAL SWYDGET DETAILS
  const endpoint = "/1/events/event/" + clonedEvent.eventId;
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  // CREATE THE REQUESTS TO ADD THE PRODUCTS TO THE FRESH CLONE
  // - Batch update of all of them using axious.all
  // - When complete, use the product IDs of the original Swydget to
  //   determine the proper order of the Swydgets in the clone
  // - In another method use axios.all to batch update all of the Swydgets
  //   of the clones
  // - 2 sychronous ops using multple asynchronos calls

  var updateCloneURL = serverUrl + encodedEndpoint;
  var requestsArray = [];
  requestsArray.push(axios.post(updateCloneURL, updateSurveyJson, config));


  var addCloneRequestsArray = generateRequestsToCloneOrderedWyshList(user, serverUrl, clonedEvent, originalEvent.orderedWyshList);
  for (var i = 0; i < addCloneRequestsArray.length; i++) {
    requestsArray.push(addCloneRequestsArray[i]);
  }

  axios.all(requestsArray)
    .then(axios.spread((...responses) => {

      orderClonedWyshes(user, serverUrl, originalEvent, clonedEvent);

      dispatcher.dispatch({
        type: "ME_CLONE_SWYDGET_SUCCESSFUL"
      });

    }))
    .catch(function (error) {
      console.log(error);
    });


}

function generateRequestsToCloneOrderedWyshList(user, serverUrl, clonedEvent, orderedWyshList) {

  var requestsArray = [];
  var orderedWyshes = orderedWyshList.getOrderedWyshes();

  for (var i = 0; i < orderedWyshes.length; i++) {

    requestsArray.push(generateRequestToAddProductToClonedSurvey( user,
                                                                  serverUrl,
                                                                  orderedWyshes[i],
                                                                  clonedEvent,
                                                                  orderedWyshes[i].questionType,
                                                                  orderedWyshes[i].caption,
                                                                  orderedWyshes[i].freeResponseQuestions,
                                                                  orderedWyshes[i].binaryIconSetID,
                                                                  orderedWyshes[i].scalarDescription,
                                                                  orderedWyshes[i].block
                                                                ));


    if (orderedWyshes[i].isBlock()) {
      var childRequestsArray = generateRequestsToCloneOrderedWyshList(user, serverUrl, clonedEvent, orderedWyshes[i].orderedWyshList);
      for (var j = 0; j < childRequestsArray.length; j++) {
        requestsArray.push(childRequestsArray[j]);
      }
    }
  }

  return requestsArray;
}

/*
orderClonedWyshes

Order the Wyshes of a Cloned Swydget using the order of the original Swydget
*/
export function orderClonedWyshes(user, serverUrl, originalEvent, clonedEvent) {

  var eventIdArg = clonedEvent.eventId;
  if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
    eventIdArg += "#";
  }

  const encodedEventId = eventIdArg.replace("#", "%23");
  const endpoint = "/1/events/event/" + encodedEventId;
  const resultEndpoint = "/1/magic_hat";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {
    "query": "select svt_event_id, svt_mid, svt_created, svt_completed, svd_prd_uuid, svd_result_literal, svd_result_normalized, svd_created, svd_request_data_json " +
             "from svy_taker " +
             "left outer join svy_decision on svy_taker.svt_mid = svy_decision.svd_mid and svy_taker.svt_event_id = svy_decision.svd_event_id " +
             "where svd_event_id='" + eventIdArg + "' order by svt_mid asc, svd_created asc"
  }

  axios.all([
    axios.get(serverUrl + endpoint, config),
    axios.post(serverUrl + resultEndpoint, parametersJson, config)
  ]).then(axios.spread(function(fetchEventResponse, getResultsResponse) {


    var e = fetchEventResponse.data.result.event;
    var results = getResultsResponse.data.results.result_set;
    var clonedEventWithWyshes = Event.createFromJsonAndResults(e, results);

    var originalWyshes = originalEvent.wyshes;
    var clonedEventWyshes = clonedEventWithWyshes.wyshes;

    for (var i = 0; i < originalWyshes.length; i++) {

      // Find the corresponding Wyshes in the ORIGINAL and the CLONED Swydgets
      var currClonedWysh = clonedEventWithWyshes.findEquivalentWysh(originalWyshes[i]);
      if (currClonedWysh) {
        currClonedWysh.nextWysh = clonedEventWithWyshes.findEquivalentWysh(originalWyshes[i].nextWysh);
        currClonedWysh.parentWysh = clonedEventWithWyshes.findEquivalentWysh(originalWyshes[i].parentWysh);
        currClonedWysh.wyshRouter.importWyshRouter(originalWyshes[i]);
      }
      else {
        // console.log(originalWyshes[i]);
      }
    }

    updateWyshes(user.userId, user.password, serverUrl, clonedEventWithWyshes, clonedEventWyshes, "ME_CLONE_SWYDGET_SUCCESSFUL");

  })).catch(function (error) {
    console.log(error);
  });
}

/*
updateEvent

Updates an event's details.
*/
export function updateEvent(user, serverUrl, event, title, rewardText, rewardUrl, contestPrizeText) {

  var updateSurveyJson = {
    "req_auth_token": "",
    "title": title,
    "survey_name": event.surveyName,
    "swydget_state": event.swydgetState,
    "description": event.description,
    "default_prompt": event.defaultPrompt,
    "scalar_description": event.scalarDescription,
    "mc_single_instruction": event.mcSingleInstruction,
    "mc_multiple_instruction": event.mcMultipleInstruction,
    "preview_title": event.previewTitle,
    "preview_description": event.previewDescription,
    "reward_text":rewardText,
    "reward_url": rewardUrl,
    "contest_prize_text": contestPrizeText,
    "call_to_action_instructions_text": event.callToActionInstructionText,
    "call_to_action_submit_button_text": event.callToActionSubmitButtonText,
    "user-id": user.addressBookJson["contacts"]["0"],
    "randomize": event.randomize,
    "issue_sub_groups": event.issueSubGroups,
    "sub_group_count": event.subGroupCount,
    "limit_free_response": event.limitFreeResponse,
    "limit_free_response_count": event.limitFreeResponseCount,
    "get_started_text": event.getStartedText,
    "tos_link": event.tosLink,
    "privacy_link": event.privacyLink,
    "reward_button_text": event.rewardButtonText,
    "show_results": event.showResults,
    "show_recommendations": event.showRecommendations,
    "location_enabled": event.locationEnabled,
    "social_sharing_channels": event.socialSharingChannels,
    "cover_photo_url": event.coverPhotoUrl,
    "wallpaper_url": event.wallpaperUrl,
    "company_logo_url": event.companyLogoUrl,
    "snapshots_container": event.snapshotContainer.toJson(),
    "default_product_str": event.defaultProduct.toJsonString(),
    "show_swytchback_tos": event.showSwytchbackTOS
  };

  // const endpoint = "/event/update";
  const endpoint = "/1/events/event/" + event.eventId;
  const encodedEndpoint = endpoint.replace(/#/g, "%23");


  dispatcher.dispatch({
    type: "ME_UPDATE_SURVEY"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  axios.post(serverUrl + encodedEndpoint, updateSurveyJson, config)
    .then((data) => {

        dispatcher.dispatch({
          type: "ME_UPDATE_SURVEY_SUCCESSFUL"
        });
    }
  )
  .catch(function (error) {
    console.log(error);
  });

}

/*
uploadCoverPhoto

Upload a cover image to a Survey/Event.
*/
export function uploadCoverPhoto(username, passwordSha256, serverUrl, survey, photoFile) {

  const endpoint = "/1/events/event/" + survey.eventId + "/cover_photo";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");


  dispatcher.dispatch({
    type: "ME_UPLOAD_SURVEY_COVER_PHOTO"
  });

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  console.log("photo size %f", photoFile.size);
  var formData = new FormData();
  formData.append("photo_file", photoFile);

  axios.post(serverUrl + encodedEndpoint, formData, config)
    .then((data) => {

      // After we've updaed, we want to fetch the Event again and update
      // the UI
      getEvent(username, passwordSha256, serverUrl, survey.eventId.replace("#", "%23"));
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
upload wallpaper image

Upload a wallpaper image to a Survey/Event.
*/
export function uploadWallpaper(username, passwordSha256, serverUrl, survey, photoFile) {

  const endpoint = "/1/events/event/" + survey.eventId + "/wallpaper";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  dispatcher.dispatch({
    type: "ME_UPLOAD_SURVEY_WALLPAPER"
  });

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  console.log("photo size %f", photoFile.size);

  var formData = new FormData();
  formData.append("photo_file", photoFile);

  axios.post(serverUrl + encodedEndpoint, formData, config)
    .then((data) => {

      // After we've updaed, we want to fetch the Event again and update
      // the UI
      getEvent(username, passwordSha256, serverUrl, survey.eventId.replace("#", "%23"));
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
uploadDefaultCardBackground

Upload a default card background image to a Survey/Event.
*/
export function uploadDefaultCardBackground(username, passwordSha256, serverUrl, survey, photoFile) {

  const endpoint = "/1/events/event/" + survey.eventId + "/default_card_background";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");
  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  console.log("photo size %f", photoFile.size);
  var formData = new FormData();
  formData.append("photo_file", photoFile);

  axios.post(serverUrl + encodedEndpoint, formData, config)
    .then((data) => {

      // After we've updaed, we want to fetch the Event again and update
      // the UI
      getEvent(username, passwordSha256, serverUrl, survey.eventId.replace("#", "%23"));
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
createProduct

Creates a bakery fresh Product. It is added to a Survey via a different method.
*/
export function createProduct(user,
                              serverUrl,
                              survey,
                              creatorMid,
                              prdName,
                              prdPrice,
                              prdDescription,
                              prdLink,
                              photoFile,
                              prdAttributes,
                              prdStyles,
                              questionType,
                              scalarOptions,
                              question,
                              freeResponseQuestion,
                              binaryIconSetID,
                              isBlock,
                              parentWysh=null,
                              randomizeWyshOptions=false,
                              mcIncludeNoneOfTheAbove=false,
                              mcIncludeOther=false,
                              mcOptionsLimit=0,
                              mustViewAll=false,
                              prdImageUrl=null,
                              hiddenFreeResponseOptions=null) {

  
  const endpoint = "/1/product";

  dispatcher.dispatch({
    type: "ME_CREATE_PRODUCT"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var qualityScale = determineQualityScale(photoFile);

  var formData = new FormData();
  formData.append("creator_mid", creatorMid);
  formData.append("prd_name", prdName);
  formData.append("prd_price", prdPrice);
  formData.append("prd_description", prdDescription ? prdDescription : "");
  formData.append("prd_buy_url", prdLink);
  formData.append("prd_attributes_json", JSON.stringify(prdAttributes));
  formData.append("prd_styles_json", JSON.stringify(prdStyles))
  if (prdImageUrl)
    formData.append("prd_image_url", prdImageUrl);

  if (prdImageUrl) {
    formData.append("quality_scale", qualityScale);
  }

  if (photoFile) {
    console.log("photo size %f", photoFile.size);
    formData.append("photo_file", photoFile);
  }

  axios.post(serverUrl + endpoint, formData, config)
    .then((data) => {

        const { product_info } = data.data;
        var product = WyshMeProduct.createFromJson(product_info);

        if (survey) {
          // The caller passed in the necessary info to add the product to a survey.
          // Let's do that!
          addProductToSwydget(user.userId,
                              user.password,
                              serverUrl,
                              product,
                              survey,
                              questionType,
                              scalarOptions,
                              question,
                              freeResponseQuestion,
                              binaryIconSetID,
                              false, //isBlock??? always false even though a wysh could be a block as well.
                              parentWysh,
                              randomizeWyshOptions,
                              mcIncludeNoneOfTheAbove,
                              mcIncludeOther,
                              mcOptionsLimit,
                              mustViewAll,
                              hiddenFreeResponseOptions
                            );
        }
        else {
          // The caller only passed in the pieces to create the product.
          // It is now in the library, so let's tell everybody.
          getProducts(user, user.password, serverUrl);
          // dispatcher.dispatch({
          //   type: "ME_CREATE_PRODUCT_SUCCESSFUL",
          //   product: product
          // });
        }
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
createProducts

Batch creates an array bakery fresh products. It is added to a Survey via a different method.
*/
export function createProducts(
  user,
  serverUrl,
  swydget,
  imageFiles,
  parentWysh=null) {

  const endpoint = "/1/product";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  dispatcher.dispatch({
    type: "ME_CREATE_PRODUCTS"
  });

  var requestsArray = [];

  for (var i = 0; i < imageFiles.length; i++) {

    var qualityScale = determineQualityScale(imageFiles[i]);

    var formData = new FormData();
    formData.append("creator_mid", user.myMid);
    formData.append("prd_name", "");
    formData.append("prd_price", 0.0);
    formData.append("prd_description", "");
    formData.append("prd_buy_url", "");
    formData.append("prd_attributes_json", JSON.stringify({}));
    formData.append("prd_styles_json", JSON.stringify(new ProductStyles()));
    formData.append("quality_scale", qualityScale);

    if (imageFiles[i]) {
      console.log("photo size %f", imageFiles[i].size);
      formData.append("photo_file", imageFiles[i]);
    }

    requestsArray.push(axios.post(serverUrl + endpoint, formData, config));
  }

  axios.all(requestsArray)
    .then(axios.spread(
      (...args) => {

        // Add the batch to a Swydget
        var products = [];
        for (var i = 0; i < args.length; i++) {
          const { product_info } = args[i].data;
          products.push(WyshMeProduct.createFromJson(product_info));
        }

        if (swydget) {
          addProductsToSwydget(
            user,
            serverUrl,
            products,
            swydget,
            parentWysh
          );
        }

      }
    ))
    .catch((error) => {
      console.log(error);
    });
}


/*
updateProduct

Creates a bakery fresh Product. It is added to a Survey via a different method.
*/
export function updateProduct(user, serverUrl, productInFocus, creatorMid, prdName, prdPrice, prdDescription, prdLink, photoFile, prdAttributes, prdStyles, removeImage) {

  const endpoint = "/1/product/update/";

  dispatcher.dispatch({
    type: "ME_UPDATE_PRODUCT"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var prdUrl = productInFocus.imageUrl;

  if (removeImage === true) {
    prdUrl = "";
  }

  var formData = new FormData();
  formData.append("creator_mid", creatorMid);
  formData.append("prd_uuid", productInFocus.productId);
  formData.append("prd_url", prdUrl);
  formData.append("prd_name", prdName);
  formData.append("prd_price", prdPrice);
  formData.append("prd_description", prdDescription);
  formData.append("prd_buy_url", prdLink);
  formData.append("prd_attributes_json", JSON.stringify(prdAttributes));
  formData.append("prd_styles_json", JSON.stringify(productInFocus.styles));

  if (photoFile) {
    console.log("photo size %f", photoFile.size);
    var qualityScale = determineQualityScale(photoFile);
    formData.append("quality_scale", qualityScale);
    formData.append("photo_file", photoFile);
  }
  else {
    formData.append("photo_file", "remove");
  }


  axios.post(serverUrl + endpoint, formData, config)
    .then((data) => {

        const { product_info } = data.data;
        var product = WyshMeProduct.createFromJson(product_info);



        dispatcher.dispatch({
          type: "ME_UPDATE_PRODUCT_SUCCESSFUL",
          product: product
        });

        getProducts(user, user.password, serverUrl);

    }
  )
  .catch(function (error) {
    console.log(error);
  });
}


/*
updateWysh

Updates a wysh

POST /1/events/event/evt.00000000000003ec-00000021%23/wyshes/wys.00000000000003ec-00000065%23
*/
export function updateWysh(user, serverUrl, survey, updatedWysh, reloadEvent, photoFile=null) {

  // Step 1: Update the Product in Product Space
  const productUpdateEndpint = "/1/product/update/";

  dispatcher.dispatch({
    type: "ME_UPDATE_WYSH"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var formData = new FormData();
  formData.append("creator_mid", user.myMid);
  formData.append("prd_uuid", updatedWysh.product.productId);
  formData.append("prd_url", updatedWysh.product.imageUrl);
  formData.append("prd_name", updatedWysh.product.name);
  formData.append("prd_price", updatedWysh.product.price);
  formData.append("prd_description", updatedWysh.product.description);
  formData.append("prd_buy_url", updatedWysh.product.buyPage);
  formData.append("prd_attributes_json", JSON.stringify(updatedWysh.product.attributes));
  formData.append("prd_styles_json", JSON.stringify(updatedWysh.product.styles));

  if (photoFile) {
    console.log("photo size %f", photoFile.size);
    var qualityScale = determineQualityScale(photoFile);
    formData.append("quality_scale", qualityScale);
    formData.append("photo_file", photoFile);
  }
  else {
    formData.append("photo_file", "remove");
  }

  axios.post(serverUrl + productUpdateEndpint, formData, config)
    .then((data) => {

      const { product_info } = data.data;
      var product = WyshMeProduct.createFromJson(product_info);
      updatedWysh.product = product;

      // Step 2: Update the Stimuli/Wysh

      // Set up Parameters JSON
      var freeResponseQuestionsArray = [];
      for (var i = 0; i < updatedWysh.freeResponseQuestions.length; i++) {
        freeResponseQuestionsArray.push(updatedWysh.freeResponseQuestions[i]);
      }

      var parametersJson = {
        question_type: updatedWysh.questionType,
        free_response_questions: freeResponseQuestionsArray,
        caption: updatedWysh.caption,
        options: updatedWysh.getScalarOptionsAsNames(),
        randomize_wysh_options: updatedWysh.randomizeWyshOptions,
        mc_include_none_of_the_above: updatedWysh.mcIncludeNoneOfTheAbove,
        mc_include_other: updatedWysh.mcIncludeOther,
        mc_options_limit: updatedWysh.mcOptionsLimit,
        must_view_all: updatedWysh.mustViewAll,
        binary_icon_set_id: updatedWysh.binaryIconSetID,
        product: updatedWysh.product.toJsonString(),
        randomize: updatedWysh.randomize,
        forced_choice: updatedWysh.forcedChoice,
        display_block_card: updatedWysh.displayBlockCard,
        issue_sub_groups: updatedWysh.issueSubGroups,
        sub_group_count: updatedWysh.subGroupCount,
        limit_free_response: updatedWysh.limitFreeResponse,
        limit_free_response_count: updatedWysh.limitFreeResponseCount,
        hidden_free_response_options: updatedWysh.hiddenFreeResponseOptions,
        words_to_omit: updatedWysh.wordsToOmit,
        selected_free_response_decisions: updatedWysh.selectedFreeResponseDecisions
      };

      if (updatedWysh.nextWysh) {
        parametersJson['wysh_id_next'] = updatedWysh.nextWysh.wyshId
      }

      if (updatedWysh.parentWysh) {
        parametersJson['parent_wysh_id'] = updatedWysh.parentWysh.wyshId
      }

      if (updatedWysh.wyshRouter) {

        let branchLogicStatements = [];
        for (i = 0; i < updatedWysh.wyshRouter.branchLogicStatements.length; i++) {
          branchLogicStatements.push(updatedWysh.wyshRouter.branchLogicStatements[i].toJson());
        }

        parametersJson['branch_logic_statements'] = branchLogicStatements;
      }

      const stimuliUpdateEndpint = "/1/events/event/" + survey.eventId + "/wyshes/" + updatedWysh.wyshId;
      const encodedStimuliUpdateEndpint = stimuliUpdateEndpint.replace(/#/g, "%23");


      axios.post(serverUrl + encodedStimuliUpdateEndpint, parametersJson, config)
        .then((updateStimuliData) => {

          dispatcher.dispatch({
            type: "ME_UPDATE_WYSH_SUCCESSFUL",
            eventId: survey.eventId,
            updatedWysh: Wysh.createFromJson(updateStimuliData.data.results.wysh)
          });

          if (reloadEvent) {
            const encodedEventId = survey.eventId.replace("#", "%23");
            getEvent(user.userId, user.password, serverUrl, encodedEventId);
          }
        }
      )
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
updateBlock

Updates a block and propagates some updates to its children

POST /1/events/event/evt.00000000000003ec-00000021%23/wyshes/wys.00000000000003ec-00000065%23
*/
export function updateBlock(username, passwordSha256, serverUrl, survey, updatedBlock, updatedBlockAttributesDict={}) {

  var freeResponseQuestionsArray = [];
  for (var i = 0; i < updatedBlock.freeResponseQuestions.length; i++) {
    freeResponseQuestionsArray.push(updatedBlock.freeResponseQuestions[i]);
  }

  var parametersJson = {
    question_type: updatedBlock.questionType,
    free_response_questions: freeResponseQuestionsArray,
    caption: updatedBlock.caption,
    options: updatedBlock.getScalarOptionsAsNames(),
    binary_icon_set_id: updatedBlock.binaryIconSetID,
    randomize_wysh_options: updatedBlock.randomizeWyshOptions,
    mc_include_none_of_the_above: updatedBlock.mcIncludeNoneOfTheAbove,
    mc_include_other: updatedBlock.mcIncludeOther,
    mc_options_limit: updatedBlock.mcOptionsLimit,
    must_view_all: updatedBlock.mustViewAll,
    product: updatedBlock.product.toJsonString(),
    randomize: updatedBlock.randomize,
    forced_choice: updatedBlock.forcedChoice,
    block_type: updatedBlock.getBlockType().blockTypeId,
    display_block_card: updatedBlock.displayBlockCard,
    issue_sub_groups: updatedBlock.issueSubGroups,
    sub_group_count: updatedBlock.subGroupCount,
    limit_free_response: updatedBlock.limitFreeResponse,
    limit_free_response_count: updatedBlock.limitFreeResponseCount,
    hidden_free_response_options: updatedBlock.hiddenFreeResponseOptions
  };

  if (updatedBlock.nextWysh) {
    parametersJson['wysh_id_next'] = updatedBlock.nextWysh.wyshId
  }

  if (updatedBlock.parentWysh) {
    parametersJson['parent_wysh_id'] = updatedBlock.parentWysh.wyshId
  }

  if (updatedBlock.getBlockType()) {
    parametersJson['block_type'] = updatedBlock.getBlockType().blockTypeId;
  }

  if (updatedBlock.wyshRouter) {

    let branchLogicStatements = [];
    for (i = 0; i < updatedBlock.wyshRouter.branchLogicStatements.length; i++) {
      branchLogicStatements.push(updatedBlock.wyshRouter.branchLogicStatements[i].toJson());
    }

    parametersJson['branch_logic_statements'] = branchLogicStatements;
  }

  const endpoint = "/1/events/event/" + survey.eventId + "/wyshes/" + updatedBlock.wyshId;
  // const encodedEndpoint = endpoint.replace("#", "%23");
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  dispatcher.dispatch({
    type: "ME_UPDATE_WYSH"
  });

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      var areBlockChildrenDirty = false;

      for (var i = 0; i < updatedBlock.orderedWyshList.wyshes.length; i++) {
        if ("defaultPrompt" in updatedBlockAttributesDict) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].caption = updatedBlockAttributesDict["defaultPrompt"];
        }
        if (updatedBlockAttributesDict["freeResponseQuestions"] && updatedBlockAttributesDict["freeResponseQuestions"].length > 0) {
          if (updatedBlockAttributesDict["freeResponseQuestions"][0].trim() !== "") {
            updatedBlock.orderedWyshList.wyshes[i].freeResponseQuestions = updatedBlockAttributesDict["freeResponseQuestions"];
          }
          else {
            updatedBlock.orderedWyshList.wyshes[i].freeResponseQuestions = [];
          }
          areBlockChildrenDirty = true;
        }
        if (updatedBlockAttributesDict["wyshOptions"]) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].setWyshOptions(updatedBlockAttributesDict["wyshOptions"]);
        }
        if (updatedBlockAttributesDict["questionType"]) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].questionType = updatedBlockAttributesDict["questionType"];
        }
        if (updatedBlockAttributesDict["binaryIconSetID"]) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].binaryIconSetID = updatedBlockAttributesDict["binaryIconSetID"];
        }
        if (updatedBlockAttributesDict["hiddenFreeResponseOptions"]) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].hiddenFreeResponseOptions = updatedBlockAttributesDict["hiddenFreeResponseOptions"];
        }
        if ("randomizeWyshOptions" in updatedBlockAttributesDict) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].randomizeWyshOptions = updatedBlockAttributesDict["randomizeWyshOptions"];
        }
        if ("mcIncludeNoneOfTheAbove" in updatedBlockAttributesDict) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].mcIncludeNoneOfTheAbove = updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"];
        }
        if ("mcIncludeOther" in updatedBlockAttributesDict) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].mcIncludeOther = updatedBlockAttributesDict["mcIncludeOther"];
        }
        if ("mcOptionsLimit" in updatedBlockAttributesDict) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].mcOptionsLimit = updatedBlockAttributesDict["mcOptionsLimit"];
        }
        if ("mustViewAll" in updatedBlockAttributesDict) {
          areBlockChildrenDirty = true;
          updatedBlock.orderedWyshList.wyshes[i].mustViewAll = updatedBlockAttributesDict["mustViewAll"];
        }
      }

      if (areBlockChildrenDirty === true) {
        updateWyshes(username, passwordSha256, serverUrl, survey, updatedBlock.orderedWyshList.wyshes, "ME_UPDATE_WYSH_SUCCESSFUL")
      }
      else {
        dispatcher.dispatch({
          wyshId: updatedBlock.wyshId,
          type: "ME_UPDATE_WYSH_SUCCESSFUL"
        });
      }

      const encodedEventId = survey.eventId.replace("#", "%23");
      getEvent(username, passwordSha256, serverUrl, encodedEventId);
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

export function generateWyshesUpdateRequests(serverUrl, survey, wyshes) {
  
  var requestsArray = [];

  for (var i = 0; i < wyshes.length; i++) {
    var parametersJson = {
      caption: wyshes[i].caption,
      options: wyshes[i].getScalarOptionsAsNames(),
      product: wyshes[i].product.toJsonString(),
      randomize: wyshes[i].orderedWyshList.randomize,
      free_response_questions: wyshes[i].freeResponseQuestions,
      question_type: wyshes[i].questionType,
      binary_icon_set_id: wyshes[i].binaryIconSetID,
      randomize_wysh_options: wyshes[i].randomizeWyshOptions,
      mc_include_none_of_the_above: wyshes[i].mcIncludeNoneOfTheAbove,
      mc_include_other: wyshes[i].mcIncludeOther,
      mc_options_limit: wyshes[i].mcOptionsLimit,
      must_view_all: wyshes[i].mustViewAll,
      hidden_free_response_options: wyshes[i].hiddenFreeResponseOptions,
      words_to_omit: wyshes[i].wordsToOmit,
      selected_free_response_decisions: wyshes[i].selectedFreeResponseDecisions
    };

    if (wyshes[i].nextWysh) {
      parametersJson['wysh_id_next'] = wyshes[i].nextWysh.wyshId
    }

    if (wyshes[i].parentWysh) {
      parametersJson['parent_wysh_id'] = wyshes[i].parentWysh.wyshId
    }

    if (wyshes[i].wyshRouter) {

      let branchLogicStatements = [];
      for (var j = 0; j < wyshes[i].wyshRouter.branchLogicStatements.length; j++) {
        branchLogicStatements.push(wyshes[i].wyshRouter.branchLogicStatements[j].toJson());
      }

      parametersJson['branch_logic_statements'] = branchLogicStatements;
    }

    const endpoint = "/1/events/event/" + survey.eventId + "/wyshes/" + wyshes[i].wyshId;
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    requestsArray.push({
      parametersJson: parametersJson,
      endpoint: serverUrl + encodedEndpoint
    })
  }

  return requestsArray
}

/*
updateWyshes

Updates an array of Wyshes

sends Posts for all the Wyshes:
POST /1/events/event/evt.00000000000003ec-00000021%23/wyshes/wys.00000000000003ec-00000065%23
*/
export function updateWyshes(username, passwordSha256, serverUrl, survey, wyshes, dispatchType, wyshToRemove) {

  console.log("update wyshes");
  

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  const config = {
    headers: {'Authorization': base64String}
  };

  const requestObjectsArray = generateWyshesUpdateRequests(serverUrl, survey, wyshes);
  const requestsArray = [];

  for (const requestObj of requestObjectsArray) {
    requestsArray.push(axios.post(requestObj.endpoint, requestObj.parametersJson, config));
  }

  axios.all(requestsArray)
    .then(axios.spread((...args) => {

      if (wyshToRemove) {

        const wyshesToRemove = [wyshToRemove].concat(wyshToRemove.getAllDescendantWyshes());

        const wyshIdsToRemove = []

        for (const w of wyshesToRemove) {
          wyshIdsToRemove.push(w.wyshId)
        }

        var removeWyshesParams = {
          wysh_list: wyshIdsToRemove
        };

        // Generate the Axios HTTP delete request for the stimuli/wysh/block being deleted
        const removeWyshesEndpoint = "/1/events/event/" + survey.eventId + "/remove_wyshes/";
        const encodedRemoveWyshesEndpoint = removeWyshesEndpoint.replace(/#/g, "%23");
        axios.post(serverUrl + encodedRemoveWyshesEndpoint, removeWyshesParams, config)
          .then((data) => {

            getEvent(username, passwordSha256, serverUrl, survey.eventId.replace("#", "%23"));
          }
        );

      }
      else {
        getEvent(username, passwordSha256, serverUrl, survey.eventId.replace("#", "%23"));
      }

      

      // dispatcher.dispatch({
      //   type: dispatchType
      // });
    }))
    .catch(function (error) {
      console.log(error);
    });
}

export function determineQualityScale(photoFile) {
  var qualityScale = 1.0;

  if (photoFile) {
    if (photoFile.size > 1250000) {
      qualityScale = 0.4;
    }
    else if (photoFile.size > 1000000) {
      qualityScale = 0.5;
    }
    else if (photoFile.size > 850000) {
      qualityScale = 0.6;
    }
    else if (photoFile.size > 600000) {
      qualityScale = 0.7;
    }
    else if (photoFile.size > 300000) {
      qualityScale = 0.8;
    }
    else if (photoFile.size > 200000) {
      qualityScale = 0.9;
    }
  }

  return qualityScale;
}

/**
addProductToSwydget

Add an existing product to a swydget.
@param username
@param passwordSha256 - sha256 encoded password
@param serverUrl
@param product - WyshMeProduct
@param swydget - Event/Swydget
@param questionType
@param options - scalar options
@param question - specific prompt
@param freeResponseQuestions - an ARRAY of verbatim questions (Strings)
@param hiddenFreeResponseOptions - array of wysh options to NOT ask for verbatim
@param binaryIconSetID - string identifying the binary icon set id for a binary Wysh
@param isBlock boolean indicating if the Wysh should be designated as a block
@param parentWysh
@param randomizeWyshOptions
@param mcIncludeNoneOfTheAbove
@param mcIncludeOther
@param mcOptionsLimit
@param mustViewAll
*/
export function addProductToSwydget(
  username,
  passwordSha256,
  serverUrl,
  product,
  swydget,
  questionType,
  options,
  question,
  freeResponseQuestions,
  binaryIconSetID,
  isBlock,
  parentWysh,
  randomizeWyshOptions,
  mcIncludeNoneOfTheAbove,
  mcIncludeOther,
  mcOptionsLimit,
  mustViewAll,
  hiddenFreeResponseOptions
  ) {

  // let mcIncludeOther = false;
  // let mcIncludeNoneOfTheAbove = false;

  // for (const option of options) {
  //   if (option.resultNormalized === 98.0) {
  //     mcIncludeOther = true;
  //   }
  //   if (option.resultNormalized === 99.0) {
  //     mcIncludeNoneOfTheAbove = true;
  //   }
  // }

  var parametersJson = {
    "product_info": JSON.parse(product.toJsonString()),
    "question_type": questionType,
    "options": options,
    "caption": question,
    "free_response_questions": freeResponseQuestions,
    "binary_icon_set_id": binaryIconSetID,
    "randomize_wysh_options": randomizeWyshOptions,
    "hidden_free_response_options": hiddenFreeResponseOptions ? hiddenFreeResponseOptions : parentWysh.hiddenFreeResponseOptions,
    "mc_include_none_of_the_above": mcIncludeNoneOfTheAbove,
    "mc_include_other": mcIncludeOther,
    "mc_options_limit": mcOptionsLimit? mcOptionsLimit : parentWysh ? parentWysh.mcOptionsLimit : 0,
    "must_view_all": mustViewAll,
    "block": isBlock
  }

  const endpoint = "/1/events/event/" + swydget.eventId + "/wyshes/";
  const encodedEndpoint = endpoint.replace("#", "%23");

  dispatcher.dispatch({
    type: "ME_ADD_PRODUCT_TO_SWYDGET"
  });

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

        const encodedEventId = swydget.eventId.replace("#", "%23");

        // so is there a parentWysh? If so, it is on like Donkey Kong
        //


        if (data.data.result.wysh_dict && parentWysh) {
          var newWysh = Wysh.createFromJson(data.data.result.wysh_dict);
          newWysh.setSwydget(swydget);
          updateWyshes(username, passwordSha256, serverUrl, swydget, parentWysh.orderedWyshList.add(newWysh), "ME_UPDATE_WYSHES_SUCCESSFUL");
        }
        else if (data.data.result.wysh_dict) {
          var newWysh = Wysh.createFromJson(data.data.result.wysh_dict);
          newWysh.setSwydget(swydget);
          updateWyshes(username, passwordSha256, serverUrl, swydget, swydget.orderedWyshList.add(newWysh, "ME_UPDATE_WYSHES_SUCCESSFUL"));
        }

        // getEvent(username, passwordSha256, serverUrl, swydget.eventId.replace("#", "%23"));

        // dispatcher.dispatch({
        //   type: "ME_ADD_PRODUCT_TO_SWYDGET_SUCCESSFUL",
        //   eventId: swydget.eventId
        // });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
addProductsToSwydget

Add an existing product to a swydget.
@param user
@param serverUrl
@param products - array of WyshMeProducts
@param swydget - Event/Swydget
@param parentWysh - inherits everything from parent. If null, inherits basic defaults
*/
export function addProductsToSwydget(
  user,
  serverUrl,
  products,
  swydget,
  parentWysh
) {

  dispatcher.dispatch({
    type: "ME_ADD_PRODUCTS_TO_SWYDGET"
  });

  const endpoint = "/1/events/event/" + swydget.eventId + "/wyshes/";
  const encodedEndpoint = endpoint.replace("#", "%23");
  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var requestsArray = [];

  for (var i = 0; i < products.length; i++) {
    var parametersJson = {
      "product_info": JSON.parse(products[i].toJsonString()),
      "question_type": parentWysh ? parentWysh.questionType : "binary",
      "options": parentWysh ? parentWysh.getScalarOptionsAsNames() : [],
      "caption": parentWysh ? parentWysh.caption : "",
      "free_response_questions": parentWysh ? parentWysh.freeResponseQuestions : [],
      "hidden_free_response_options": parentWysh ? parentWysh.hiddenFreeResponseOptions : [],
      "binary_icon_set_id": parentWysh ? parentWysh.binaryIconSetID : "yes_no",
      "randomize_wysh_options": parentWysh ? parentWysh.randomizeWyshOptions : false,
      "hidden_free_response_options": parentWysh ? parentWysh.hiddenFreeResponseOptions : [],
      "mc_include_none_of_the_above": parentWysh ? parentWysh.mcIncludeNoneOfTheAbove : false,
      "mc_include_other": parentWysh ? parentWysh.mcIncludeOther : false,
      "mc_options_limit": parentWysh ? parentWysh.mcOptionsLimit : 0,
      "must_view_all": parentWysh ? parentWysh.mustViewAll : false,
      "block": false
    }

    requestsArray.push(axios.post(serverUrl + encodedEndpoint, parametersJson, config));
  }

  axios.all(requestsArray)
    .then(axios.spread(
      (...args) => {

        if (parentWysh) {
          for (var i = 0; i < args.length; i++) {
            if (args[i].data.result.wysh_dict) {
              var newWysh = Wysh.createFromJson(args[i].data.result.wysh_dict);
              newWysh.setSwydget(swydget);
              parentWysh.orderedWyshList.add(newWysh);
              // parentWysh.orderedWyshList.verifyAndRepair();
            }
          }

          updateWyshes(user.userId, user.password, serverUrl, swydget, parentWysh.orderedWyshList.getOrderedWyshes());
        }
        else {
          for (var i = 0; i < args.length; i++) {
            if (args[i].data.result.wysh_dict) {
              var newWysh = Wysh.createFromJson(args[i].data.result.wysh_dict);
              newWysh.setSwydget(swydget);
              swydget.orderedWyshList.add(newWysh);
              // parentWysh.orderedWyshList.verifyAndRepair();
            }
          }

          updateWyshes(user.userId, user.password, serverUrl, swydget, swydget.orderedWyshList.getOrderedWyshes());
        }

        dispatcher.dispatch({
          type: "ME_ADD_PRODUCTS_TO_SWYDGET_SUCCESSFUL",
          eventId: swydget.eventId
        });
      }
    ))
    .catch((error) => {
      console.log(error);
    });
}



/*
addProductToClonedSurvey

Add an existing product to a swydget.
*/
export function generateRequestToAddProductToClonedSurvey(user, serverUrl, wysh, survey, questionType, question, freeResponseQuestions, binaryIconSetID, scalarDescription, block) {

  var options = [];

  for (var i = 0; i < wysh.wyshOptions.length; i++) {
    options.push({"name": wysh.wyshOptions[i].resultLiteral, "resultNormalized": wysh.wyshOptions[i].resultNormalized});
  }

  var parametersJson = {
    "product_info": JSON.parse(wysh.product.toJsonString()),
    "question_type": questionType,
    "options": options,
    "caption": question,
    "free_response_questions": freeResponseQuestions,
    "binary_icon_set_id": binaryIconSetID,
    "randomize_wysh_options": wysh.randomizeWyshOptions,
    "mc_include_none_of_the_above": wysh.mcIncludeNoneOfTheAbove,
    "mc_include_other": wysh.mcIncludeOther,
    "mc_options_limit": wysh.mcOptionsLimit,
    "must_view_all": wysh.mustViewAll,
    "scalar_description": scalarDescription,
    "block": block,
    "randomize": wysh.randomize,
    "forced_choice": wysh.forcedChoice,
    "display_block_card": wysh.displayBlockCard,
    "issue_sub_groups": wysh.issueSubGroups,
    "sub_group_count": wysh.subGroupCount,
    "limit_free_response": wysh.limitFreeResponse,
    "limit_free_response_count": wysh.limitFreeResponseCount,
    "hidden_free_response_options": wysh.hiddenFreeResponseOptions
  }

  const endpoint = "/1/events/event/" + survey.eventId + "/wyshes/";
  const encodedEndpoint = endpoint.replace("#", "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  return axios.post(serverUrl + encodedEndpoint, parametersJson, config);
}

/*
publishSurvey

Publishes a Survey. Once published a Survey is immutable-ish.
*/
export function publishSurvey(user, serverUrl, survey, friendsToInvite, nonFriendsToInvite) {

  const endpoint = "/1/events/surveys/published/" + survey.eventId;
  const encodedEndpoint = endpoint.replace("#", "%23");

  dispatcher.dispatch({
    type: "ME_PUBLISH_SURVEY"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {

  }

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_PUBLISH_SURVEY_SUCCESSFUL",
        eventId: survey.eventId,
        nonFriendsToInvite: nonFriendsToInvite,
        friendsToInvite: friendsToInvite
      });

    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
unPublishSurvey

Sets the argument Survey to an unpublished state

DELETE /1/events/surveys/decision
*/
export function unPublishSurvey(user, serverUrl, survey) {

  const endpoint = "/1/events/surveys/published/" + survey.eventId;
  const encodedEndpoint = endpoint.replace("#", "%23");

  dispatcher.dispatch({
    type: "ME_UNPUBLISH_SURVEY"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  axios.delete(serverUrl + encodedEndpoint, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_UNPUBLISH_SURVEY_SUCCESSFUL",
        eventId: survey.eventId
      });

      // getEvent(user.userId, user.password, serverUrl, survey.eventId.replace("#", "%23"));
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
postForcedRankDecision

Submit a Forced Rank product survey decision to the back end. The email is optional as anonymous
decisions must be logged as well as those tied to a particular Swytchback user.
*/
export function postForcedRankDecision(serverUrl, userKey, firstName, lastName, productId, eventId, decisionRaw, decisionValue, coordinates, competitors, roundNumber, trialNumber) {

  var competitorsJsonArray = [];
  var round = 0;
  var trial = 0;

  if (competitors) {
    for (var i = 0; i < competitors.length; i++) {
      competitorsJsonArray.push(competitors[i].product.productId);
    }
  }

  if (roundNumber) {
    round = roundNumber;
  }

  if (trialNumber) {
    trial = trialNumber;
  }

  var geo_dict ={};
  if (coordinates) {
    geo_dict["accuracy"] = coordinates["accuracy"];
    geo_dict["latitude"] = coordinates["latitude"];
    geo_dict["longitude"] = coordinates["longitude"];
  }

  var parametersJson = {
    "product_id": productId,
    "event_id": eventId,
    "decision": decisionRaw,
    "decision_value": decisionValue,
    "geolocation_info": geo_dict,
    "competitors": competitorsJsonArray,
    "round": round,
    "trial": trial
  }

  if (userKey) {
    parametersJson["user_key"] = userKey;
  }

  if (firstName) {
    parametersJson["first_name"] = firstName;
  }

  if (lastName) {
    parametersJson["last_name"] = lastName;
  }

  if (swydgetStore.versionId) {
    parametersJson["version_id"] = swydgetStore.versionId;
  }  

  const endpoint = "/1/swydget/surveys/decision";

  dispatcher.dispatch({
    type: "SWYDGET_POST_PRODUCT_DECISION",
    eventId: eventId,
    productId: productId,
    decision: decisionRaw
  });

  axios.post(serverUrl + endpoint, parametersJson)
    .then((data) => {

        dispatcher.dispatch({
          type: "SWYDGET_POST_PRODUCT_DECISION_SUCCESSFUL",
          userKey: data.data.survey_taker_key,
          eventId: eventId,
          productId: productId,
          decision: decisionRaw
        });

        var userId = "test105@catboytech.com";
        var password = "6fec2a9601d5b3581c94f2150fc07fa3d6e45808079428354b868e412b76e6bb";

        getEvent(userId, password, serverUrl, eventId);
    }
  );
}

/*
swydgetComplete

Marks the Swydget as complete for a user.

POST /1/swydget/_/complete
*/
export function swydgetComplete(serverUrl, userKey, swydgetId) {

  var parametersJson = {
    "user_key": userKey
  }

  const endpoint = "/1/swydget/" + swydgetId + "/complete";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  dispatcher.dispatch({
    type: "SWYDGET_POST_SWYDGET_COMPLETE",
    swydgetId: swydgetId
  });

  axios.post(serverUrl + encodedEndpoint, parametersJson)
    .then((data) => {

      console.log("swydget marked complete");

      dispatcher.dispatch({
        type: "SWYDGET_POST_SWYDGET_COMPLETE_SUCCESSFUL"
      });
    }
  );
}


/*
registerAnonymousSurveyTaker

Creates a new, anonymous survey taker. This will create a new user profile with a fresh MID.

POST /1/swydget/register_anonymous_user
*/
export function registerAnonymousSurveyTaker(serverUrl, eventId, versionId) {

  var parametersJson = {
    "event_id": eventId,
    "version_id": versionId
  }

  var tsParametersJson = {
    "event_id": eventId
  }

  const endpoint = "/1/swydget/register_anonymous_user";
  const tsEndpoint = "/1/swydget/get_session_timestamp";

  dispatcher.dispatch({
    type: "SWYDGET_REGISTER_ANONYMOUS_USER",
    eventId: eventId
  });

  axios.all([
    axios.post(serverUrl + endpoint, parametersJson),
    // axios.post(serverUrl + tsEndpoint, tsParametersJson)
  ]).then(
      axios.spread(function(registerResponse, tsResponse) {

        var sessionTimestamp = 0;
        var versionId = 0;
        if (tsResponse && tsResponse.data && tsResponse.data.session_timestamp) {
          sessionTimestamp = tsResponse.data.session_timestamp
        }

        if (tsResponse && tsResponse.data && tsResponse.data.version_id) {
          versionId = tsResponse.data.version_id
        }


        if (registerResponse) {
          dispatcher.dispatch({
            type: "SWYDGET_REGISTER_ANONYMOUS_USER_SUCCESSFUL",
            userKey: registerResponse.data.survey_taker_key,
            surveyTakerMid: registerResponse.data.survey_taker_mid,
            sessionTimestamp: sessionTimestamp,
            versionId: versionId,
            eventId: eventId
          });
        }
      }
    )
  );
}

/*
getSessionTimestamp

Retrieve a timestamp from the back end.

POST /1/swydget/get_session_timestamp
*/
export function getSessionTimestamp(username, passwordSha256, serverUrl, eventId, userKey) {

  var eventIdArg = eventId;
  if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
    eventIdArg += "#";
  }

  const encodedEventId = eventIdArg.replace("#", "%23");
  const getEventEndpoint = "/1/events/event/" + encodedEventId;

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };


  var getSessionTimestampParametersJson = {
    "event_id": eventId
  }

  if (userKey) {
    getSessionTimestampParametersJson["user_key"] = userKey;
  }

  const tsEndpoint = "/1/swydget/get_session_timestamp";

  axios.all([
    axios.post(serverUrl + tsEndpoint, getSessionTimestampParametersJson, config),
    axios.get(serverUrl + getEventEndpoint, config)
  ]).then(axios.spread((sessionTimestampResponse, getEventResponse) => {

      let sessionTimestamp = 0;
      let swydget = null;

      if (sessionTimestampResponse.data && sessionTimestampResponse.data.results) {

        const responseJson = sessionTimestampResponse.data.results;

        sessionTimestamp = responseJson.session_timestamp;
      }

      if (getEventResponse.data) {
        var e = getEventResponse.data.result.event;
        swydget = Event.createFromJsonAndResults(e, []);
      }

      const getMaxDiffVersionsEndpoint = "/1/swydget/" + eventIdArg + "/max_diff_version";
      const getMaxDiffVersionsEndpointEncoded = getMaxDiffVersionsEndpoint.replace("#", "%23");

      let maxDiffStimuliCounts = new Set();

      for (const w of swydget.wyshes) {
        if (w.getBlockType() && w.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
          maxDiffStimuliCounts.add(w.getFilteredOrderedWyshes().length);
        }
        else if (w.getBlockType() && w.getBlockType().equals(BlockTypes.PAIRWISE) === true) {
          maxDiffStimuliCounts.add(w.getFilteredOrderedWyshes().length);
        }
      }

      let maxDiffStimuliCountsArray = [...maxDiffStimuliCounts];

      const maxDiffVersionParamsJson = {
        stimuli_counts: maxDiffStimuliCountsArray
      }

      if (userKey) {
        maxDiffVersionParamsJson["user_key"] = userKey
      }

      axios.post(serverUrl + getMaxDiffVersionsEndpointEncoded, maxDiffVersionParamsJson, config).then((data) => {

        const mdvDictJson = data.data.results;

        // Load the max diff version for the stimuli count of any max diff blocks
        for (const w of swydget.wyshes) {
          if (w.getBlockType() && w.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
            const stimuliCount = w.getFilteredOrderedWyshes().length;
            w.setMaxDiffVersion(MaxDiffVersion.createFromJson(mdvDictJson[stimuliCount]['mdv']));
          }
          else if (w.getBlockType() && w.getBlockType().equals(BlockTypes.PAIRWISE) === true) {
            const stimuliCount = w.getFilteredOrderedWyshes().length;
            w.setPairwiseVersion(PairwiseVersion.createFromJson(mdvDictJson[stimuliCount]['pwv']));
          }
        }

        dispatcher.dispatch({
          type: "SWYDGET_GET_SESSION_TIMESTAMP_SUCCESSFUL",
          sessionTimestamp: sessionTimestamp,
          eventInFocus: swydget
        });

      });
      

      
    }
  ));
}

/*
resolveKeyAndAcceptPrivacyPolicy

Submit a user key to the backend for reconciliation with the backend user store. After that,
explicitly accept the privacy policy for this swydget.

POST /1/users/user/resolve_key
*/
export function resolveKeyAndAcceptPrivacyPolicy(serverUrl, currentUserKey, newUserKey, eventId) {


  var swydgetIdArg = eventId;
  if ((Utilities.endsWith(swydgetIdArg, "#") === false) && (Utilities.endsWith(swydgetIdArg, "%23") === false)) {
    swydgetIdArg += "#";
  }

  console.log(newUserKey);

  const acceptPrivacyPolicyEndpoint = "/1/events/event/" + swydgetIdArg + "/accept_privacy_policy";
  const encodedAcceptPrivacyPolicyEndpoint = acceptPrivacyPolicyEndpoint.replace(/#/g, "%23");
  var acceptPrivacyPolicyParametersJson = {
    "user_key": currentUserKey,
    "new_user_key": newUserKey
  }


  axios.post(serverUrl + encodedAcceptPrivacyPolicyEndpoint, acceptPrivacyPolicyParametersJson)
    .then((data) => {

      dispatcher.dispatch({
        type: "SWYDGET_RESOLVE_KEY_SUCCESSFUL",
        validatedKey: currentUserKey,
        eventId: eventId
      });
    }
  )
  .catch(function (error) {
    console.log("error");
  });
}

/*
inviteSurveyTakers


*/
export function inviteSurveyTakers(user, serverUrl, survey, invitee, friendsToInvite, nonFriendsToInvite) {

  const inviteNonFriendToInviteEndpoint = "/1/friends/survey_invite/";

  dispatcher.dispatch({
    type: "ME_INVITE_NON_FRIEND_SURVEY_TAKERS"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {
    "address_book": user.addressBookJson,
    "event_id": survey.eventId,
    "friend_cix": Number(invitee.contactIdx),
    "title": survey.title
  }

  axios.post(serverUrl + inviteNonFriendToInviteEndpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_INVITE_NON_FRIEND_SURVEY_TAKERS_SUCCESSFUL",
        eventId: survey.eventId,
        invitee: invitee,
        nonFriendsToInvite: nonFriendsToInvite,
        friendsToInvite: friendsToInvite
      });

    }
  )
  .catch(function (error) {
    console.log("error");
  });
}

/*
inviteExistingFriend

Invite a friend
POST /event/add-user
- OLD API
- serverUrl
- user,
- friend
*/
export function inviteEventMember(serverUrlNew, serverUrlOld, user, friend, swydget) {

  var parametersJson = {
    "user-id": {
      "first_name": user.firstName,
      "last_name": user.lastName,
      "display_name": user.firstName + " " + user.lastName,
      "is_friend": user.isConnected,
      "keys": user.keysDictionary,
      "photo": user.photo,
      "owner-mid": user.myMid,
      "cix": user.contactIdx
    },
    "event_id": swydget.eventId,
    "contact": user.addressBookJson.contacts[friend.contactIdx],
    "address_book": user.addressBookJson
  }


  const endpoint = "/event/add-user";

  dispatcher.dispatch({
    type: "ME_INVITE_SWYDGET_MEMBER"
  });


  var config = {

  };

  axios.post(serverUrlOld + endpoint, parametersJson, config)
    .then((data) => {

      const encodedEventId = swydget.eventId.replace("#", "%23");

      getEvent(user.userId, user.password, serverUrlNew, encodedEventId);

      dispatcher.dispatch({
        type: "ME_INVITE_SWYDGET_MEMBER_SUCCESSFUL"
      });

    }
  )
  .catch(function (error) {
    console.log(error);
  });


}

/*
acknowledgeEventInviteRequest

Two things are happening here:
1. Accepting/Rejecting the invitation
2. Ackowledging the notification
*/
export function acknowledgeEventInviteRequest(serverUrlOld, user, eventInviteNotification, accept) {


  let eventAcceptParametersJson = {
    "user-id": user.addressBookJson.contacts[0],
    "event_id": eventInviteNotification.eventId,
    "message_id": eventInviteNotification.header.index.toString()
  }

  let messageAckParametersJson = {
    "user-id": user.addressBookJson.contacts[0],
    "message_id_list": [eventInviteNotification.header.index]
  }

  const acceptEventEndpoint = "/event/accept";
  const messageAckEndpoint = "/messages/ack";

  dispatcher.dispatch({
    type: "ME_ACK_EVENT_INVITE"
  });

  var config = {

  };

  if (accept === true) {
    axios.all([
      axios.post(serverUrlOld + acceptEventEndpoint, eventAcceptParametersJson, config),
      axios.post(serverUrlOld + messageAckEndpoint, messageAckParametersJson, config)
    ]).then(axios.spread(function(acceptEventResponse, messageAckResponse) {

      Auth.login(user.userId, user.password, "/notifications");

    })).catch(function (error) {
      console.log(error);
    });
  }
  else{
    axios.all([
      axios.post(serverUrlOld + messageAckEndpoint, messageAckParametersJson, config)
    ]).then(axios.spread(function(messageAckResponse) {

      Auth.login(user.userId, user.password, "/notifications");

    })).catch(function (error) {
      console.log(error);
    });
  }


}

/*
acknowledgeNotification

Simple acknowlegedment of a notification
*/
export function acknowledgeNotification(serverUrlOld, user, notification, accept) {

  let messageAckParametersJson = {
    "user-id": user.addressBookJson.contacts[0],
    "message_id_list": [notification.header.index]
  }

  const messageAckEndpoint = "/messages/ack";

  var config = {

  };


  axios.post(serverUrlOld + messageAckEndpoint, messageAckParametersJson, config)
  .then((data) => {

    Auth.login(user.userId, user.password, "/notifications");

  }).catch(function (error) {
    console.log(error);
  });
}

/*
removeSwydgetMember

Removes a user from the list of Swydget admin membebrs.
POST /event/remove-user
- user-id
- contact
*/
export function removeSwydgetMember(serverUrlNew, user, friendToRemove, swydget) {

  const endpoint = "/1/events/event/" + swydget.eventId + "/invitee/" + user.myMid + "-" + friendToRemove.contactIdx;
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  dispatcher.dispatch({
    type: "ME_REMOVE_SWYDGET_ADMIN_USER"
  });

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };


  axios.delete(serverUrlNew + encodedEndpoint, config)
    .then((data) => {

        dispatcher.dispatch({
          type: "ME_REMOVE_SWYDGET_ADMIN_USER_SUCCESSFUL",
          eventId: swydget.eventId
        });

        const encodedEventId = swydget.eventId.replace("#", "%23");
        getEvent(user.userId, user.password, serverUrlNew, encodedEventId);
    }
  )
  .catch(function (error) {
    console.log("error");
  });
}


/**
* getDecisions
*
* Get Swydget decision
*
* @param serverUrl
* @param swydget
*/
export function getDecisions(serverUrl, swydget) {

  var userId = "test105@catboytech.com";
  var passwordSha256 = "6fec2a9601d5b3581c94f2150fc07fa3d6e45808079428354b868e412b76e6bb";

  const endpoint = "/1/events/event/" + swydget.eventId + "/get_swydget_decisions";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = userId + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };
  
  var parametersJson = {
    "filter":  swydget.filter.toJson()
  }

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      // This is really where we are auditing the submissions. At this point,
      // we don't care about ANYTHING in the Swydget.
      //
      // This is solely happening in the background. Get the decisions and if
      // any of them are missing, post again with fireAndForget set to true.

      var decisionsReceivedArray = [];

      for (var i = 0; i < data.data.results.result_set.length; i++) {
        decisionsReceivedArray.push(Decision.createFromJson(data.data.results.result_set[i]));
      }

      dispatcher.dispatch({
        type: "ME_GET_DECISIONS_SUCCESSFUL",
        decisionsArray: decisionsReceivedArray
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

export function auditDecisions(serverUrl, swydget, maxDiffResponsesArray) {

  var userId = "test105@catboytech.com";
  var passwordSha256 = "6fec2a9601d5b3581c94f2150fc07fa3d6e45808079428354b868e412b76e6bb";
  
  const endpoint = "/1/swydget/max_diff_version/fetch_design";

  const clearText = userId + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  const bestWorstDecisions = {}
  let stimuliCount = 0;
  for (const midResponses of maxDiffResponsesArray) {
    if (midResponses.length -2 > stimuliCount){
      stimuliCount = midResponses.length;
    }
  }


  for (const midResponses of maxDiffResponsesArray) {
    if (midResponses.length > stimuliCount){
      stimuliCount = midResponses.length;
    }

    if (midResponses.length > 1) {
      const mid = midResponses[0];
      const mdv = midResponses[1];
      bestWorstDecisions[mid] = midResponses.slice(2)
    }
  }

  const parametersJson = {
    "stimuli_count": stimuliCount - 2,
    "best_worst_selections": bestWorstDecisions
  }


  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_AUDIT_DECISIONS_SUCCESSFUL",
        midToMdv: data.data.results.mid_to_mdv
      });

    }
  )
  .catch(function (error) {
    console.log(error);
  });


  
}

/**
* getDecisionsWithLocation
*
* Get Swydget decisions where the user has submitted their location as well
*
* @param serverUrl
* @param eventId
*/
export function getDecisionsWithLocation(serverUrl, eventId) {

  var userId = "test105@catboytech.com";
  var passwordSha256 = "6fec2a9601d5b3581c94f2150fc07fa3d6e45808079428354b868e412b76e6bb";

  var eventIdArg = eventId;
  if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
    eventIdArg += "#";
  }

  const endpoint = "/1/events/event/" + eventIdArg + "/get_swydget_decisions_with_location";

  const clearText = userId + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  axios.get(serverUrl + endpoint, config)
    .then((data) => {

      // This is really where we are auditing the submissions. At this point,
      // we don't care about ANYTHING in the Swydget.
      //
      // This is solely happening in the background. Get the decisions and if
      // any of them are missing, post again with fireAndForget set to true.

      var decisionsReceivedArray = [];


      for (var i = 0; i < data.data.results.result_set.length; i++) {
        decisionsReceivedArray.push(Decision.createFromJson(data.data.results.result_set[i]));
      }

      dispatcher.dispatch({
        type: "ME_GET_DECISIONS_SUCCESSFUL",
        decisionsArray: decisionsReceivedArray
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* getDecisionCounts
*
* Get Swydget decision counts for each product and each decision option in that product
*
* @param serverUrl
* @param swydgetId
*/
export function getDecisionCounts(serverUrl, swydgetId) {

  var swydgetIdArg = swydgetId;
  if ((Utilities.endsWith(swydgetIdArg, "#") === false) && (Utilities.endsWith(swydgetIdArg, "%23") === false)) {
    swydgetIdArg += "#";
  }

  const endpoint = "/1/events/event/" + swydgetIdArg + "/decision_counts";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  var config = {}

  axios.get(serverUrl + encodedEndpoint, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_GET_DECISION_COUNTS_SUCCESSFUL",
        swydgetId: swydgetId,
        decisionCounts: data.data.results.decision_counts
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* getDecisionCountsFiltered
*
* Get Swydget decision counts for each product and each decision option in that product
*
* @param serverUrl
* @param user
* @param swydget
*/
export function getDecisionCountsFiltered(serverUrl, user, swydget) {

  var swydgetIdArg = swydget.eventId;
  if ((Utilities.endsWith(swydgetIdArg, "#") === false) && (Utilities.endsWith(swydgetIdArg, "%23") === false)) {
    swydgetIdArg += "#";
  }

  const decisionCountEndpoint = "/1/events/event/" + swydgetIdArg + "/decision_counts";
  const decisionCountEncodedEndpoint = decisionCountEndpoint.replace(/#/g, "%23");
  const completionInfoEndpoint = "/1/events/event/" + swydgetIdArg + "/completion_info";
  const encodedCompletionInfoEndpoint = completionInfoEndpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {};

  parametersJson.filter = swydget.filter.toJson();

  axios.all([
    axios.post(serverUrl + decisionCountEncodedEndpoint, parametersJson, config),
    axios.get(serverUrl + encodedCompletionInfoEndpoint, config)
  ]).then(axios.spread(function(postFilteredDecisionsResponse, getCompletionInfoResponse) {

    var completionInfo = getCompletionInfoResponse.data.results;
    var completionCount = 0;
    var completionPct = 0.0;
    var firstCompletionDate = "";
    var lastCompletionDate = "";
    var authorPrivacyAcceptedPct = 0.0;
    var swytchbackPrivacyAcceptedPct = 0.0;
    var swytchbackTosAcceptedPct = 0.0;
    var avgDurationInSeconds = 0.0;
    var segmentIdCountMap = {};

    if (completionInfo && completionInfo['completion_count']) {
      completionCount = completionInfo['completion_count'];
    }

    if (completionInfo && completionInfo['completion_pct']) {
      completionPct = completionInfo['completion_pct'];
    }

    if (completionInfo && completionInfo['first_completion_date']) {
      firstCompletionDate = completionInfo['first_completion_date'];
    }

    if (completionInfo && completionInfo['last_completion_date']) {
      lastCompletionDate = completionInfo['last_completion_date'];
    }

    if (completionInfo && completionInfo['author_privacy_accepted_pct']) {
      authorPrivacyAcceptedPct = completionInfo['author_privacy_accepted_pct'];
    }

    if (completionInfo && completionInfo['swytchback_privacy_accepted_pct']) {
      swytchbackPrivacyAcceptedPct = completionInfo['swytchback_privacy_accepted_pct'];
    }

    if (completionInfo && completionInfo['swytchback_tos_accepted_pct']) {
      swytchbackTosAcceptedPct = completionInfo['swytchback_tos_accepted_pct'];
    }

    if (completionInfo && completionInfo['swytchback_tos_accepted_pct']) {
      swytchbackTosAcceptedPct = completionInfo['swytchback_tos_accepted_pct'];
    }

    if (completionInfo && completionInfo['avg_duration_seconds']) {
      avgDurationInSeconds = completionInfo['avg_duration_seconds'];
    }

    if (completionInfo && completionInfo['segment_id_counts']) {
      segmentIdCountMap = completionInfo['segment_id_counts'];
    }

    var decision_counts = postFilteredDecisionsResponse.data.results.decision_counts;
    var taker_counts_by_product = postFilteredDecisionsResponse.data.results.taker_counts_by_product;

    dispatcher.dispatch({
      type: "ME_GET_DECISION_COUNTS_SUCCESSFUL",
      swydgetId: swydget.eventId,
      decisionCounts: decision_counts,
      takerCountsByProduct: taker_counts_by_product,
      completionCount: completionCount,
      completionPct: completionPct,
      firstCompletionDate: firstCompletionDate,
      lastCompletionDate: lastCompletionDate,
      authorPrivacyAcceptedPct: authorPrivacyAcceptedPct,
      swytchbackPrivacyAcceptedPct: swytchbackPrivacyAcceptedPct,
      swytchbackTosAcceptedPct: swytchbackTosAcceptedPct,
      avgDurationInSeconds: avgDurationInSeconds,
      segmentIdCountMap: segmentIdCountMap
    });
  })).catch(function (error) {
    console.log(error);
  });
}

/**
* getGeolocationData
*
* Get Swydget decision counts for each product and each decision option in that product
*
* @param serverUrl
* @param user
* @param swydget
*/
export function getGeolocationData(serverUrl, user, swydget) {

  var swydgetIdArg = swydget.eventId;
  if ((Utilities.endsWith(swydgetIdArg, "#") === false) && (Utilities.endsWith(swydgetIdArg, "%23") === false)) {
    swydgetIdArg += "#";
  }

  const endpoint = "/1/events/event/" + swydgetIdArg + "/geolocation";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {};

  parametersJson.filter = swydget.filter.toJson();

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_GET_GEOLOCATION_DATA_SUCCESSFUL",
        swydgetId: swydget.eventId,
        geolocationData: data.data.results.geolocation_data
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* getFreeResponseAnswers
*
* Get Swydget free response data for the argument swydget wysh
*
* @param serverUrl
* @param user
* @param swydget
* @param wysh
*/
export function getFreeResponseAnswers(serverUrl, user, swydget, wysh) {

  var swydgetIdArg = swydget.eventId;
  if ((Utilities.endsWith(swydgetIdArg, "#") === false) && (Utilities.endsWith(swydgetIdArg, "%23") === false)) {
    swydgetIdArg += "#";
  }

  const freeResponseAnswerEndpoint = "/1/events/event/" + swydgetIdArg + "/free_response_answers";
  const freeResponseAnswerEncodedEndpoint = freeResponseAnswerEndpoint.replace(/#/g, "%23");
  const wordFrequencyEndpoint = "/1/events/event/" + swydgetIdArg + "/free_response_frequency";
  const wordFrequencyEndpointEncodedEndpoint = wordFrequencyEndpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var freeResponseParametersJson = {};
  var wordFrequencyParametersJson = {};

  if (wysh) {
    //add it to the filter
    swydget.filter.selectedProductId = wysh.product.productId;
  }

  freeResponseParametersJson.filter = swydget.filter.toJson();
  freeResponseParametersJson.profanity_check = true;
  wordFrequencyParametersJson.stopwords = [];
  wordFrequencyParametersJson.limit = 50;
  wordFrequencyParametersJson.filter = swydget.filter.toJson();
  wordFrequencyParametersJson.profanity_check = true;

  axios.all([
    axios.post(serverUrl + freeResponseAnswerEncodedEndpoint, freeResponseParametersJson, config),
    axios.post(serverUrl + wordFrequencyEndpointEncodedEndpoint, wordFrequencyParametersJson, config)
  ]).then(axios.spread(function(freeResponseAnswersResponse, wordFrequencyResponse) {

    var wordFreqDict = WyshWordFrequency.generateWordFrequencyDict(wordFrequencyResponse.data.results.word_frequency_dict);

    let encodedFreeResponseAnswersByProductDict = freeResponseAnswersResponse.data.results.free_response_answers_by_product_compressed
    var strData = atob(encodedFreeResponseAnswersByProductDict);
    var charData = strData.split('').map(function(x){return x.charCodeAt(0);});

    // Turn number array into byte-array
    var binData = new Uint8Array(charData);

    // Pako magic
    var data = pako.inflate(binData);
    
    // Convert gunzipped byteArray back to ascii string:
    var strData = "";

    for (var i = 0; i < data.length; i++) {
      strData += String.fromCharCode(data[i]);
    }

    let freeResponseJson = JSON.parse(strData);

    dispatcher.dispatch({
      type: "ME_GET_FREE_RESPONSE_ANSWERS_DATA_SUCCESSFUL",
      swydgetId: swydget.eventId,
      wyshId: wysh ? wysh.wyshId : null,
      freeResponseAnswersByProduct: freeResponseJson,
      wordFrequencyDictionary: wordFreqDict
    });

  })).catch(function (error) {
    console.log(error);
  });
}


/**
* getFreeResponseAnswersWordFrequency
*
* Get Swydget free response word frequency data for the argument swydget wysh
*
* @param serverUrl
* @param user
* @param swydget
* @param wysh
* @param stopwords
* @param limit
*/
export function getFreeResponseAnswersWordFrequency(serverUrl, user, swydget, wysh, stopwords=[], limit=50) {

  var swydgetIdArg = swydget.eventId;
  if ((Utilities.endsWith(swydgetIdArg, "#") === false) && (Utilities.endsWith(swydgetIdArg, "%23") === false)) {
    swydgetIdArg += "#";
  }

  const endpoint = "/1/events/event/" + swydgetIdArg + "/free_response_frequency/" + wysh.product.productId;
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {};

  parametersJson.stopwords = stopwords;
  parametersJson.limit = limit;

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_GET_FREE_RESPONSE_ANSWERS_DATA_SUCCESSFUL",
        swydgetId: swydget.eventId,
        freeResponseAnswersByProduct: data.data.results.free_response_answers_by_product
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* getCompletionInfo
*
* Get the completion info for the argument swydgets array
*
* @param serverUrl
* @param user authenticated owner of the swydget
* @param swydgets array
*/
export function getCompletionInfo(serverUrl, user, swydgets) {

  const endpoint = "/1/swydgets/completion_info";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var swydgetIdArray = [];

  for (var i = 0; i < swydgets.length; i++) {
    swydgetIdArray.push(swydgets[i].eventId);
  }

  var parametersJson = {
    "event_ids": swydgetIdArray
  }

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_FETCH_COMPLETION_INFO_SUCCESSFUL",
        completionInfo: data.data.results
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
postSegmentationMapping

Submit a product survey decision to the back end. The email is optional as anonymous
decisions must be logged as well as those tied to a particular Swytchback user.
*/
export function postSegmentationMapping(serverUrl, user, swydget, segmentationMapping) {

  const endpoint = "/1/events/event/" + swydget.eventId + "/takers/segmentation_mapping";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  dispatcher.dispatch({
    type: "ME_POST_SEGMENTATION_MAPPING"
  });

  var segmentationMappingBySegment = user.segmentBucket.inflateSegmentOrdinalMapping(segmentationMapping);

  var parametersJson = {
    "mapping": segmentationMappingBySegment
  }

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_POST_SEGMENTATION_MAPPING_SUCCESSFUL"
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
batchUploadStimuli

Submit an array of stimuli arrays (an array of arrays). The stimuli will be inserted into the
argument swydget in the order.
*/
export function batchUploadStimuli(serverUrl,
                                   user,
                                   swydget,
                                   stimuliArrays,
                                   parentWysh,
                                   questionType="binary",
                                   options=[],
                                   question="",
                                   freeResponseQuestions=[],
                                   binaryIconSetID="yes_no",
                                   isBlock=false,
                                 ) {

  const endpoint = "/1/products/batch_stimuli_upload/" + swydget.eventId;
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  dispatcher.dispatch({
    type: "ME_BATCH_STIMULI_UPLOAD"
  });

  var parametersJson = {
    "creator_mid": user.myMid,
    "stimuli_arrays_str": JSON.stringify(stimuliArrays),
    "question_type": questionType,
    "options": options,
    "caption": question,
    "free_response_questions": freeResponseQuestions,
    "binary_icon_set_id": binaryIconSetID,
    "block": isBlock
  }

  if (parentWysh) {
    parametersJson["parent_wysh_id"] = parentWysh.wyshId;
    parametersJson["question_type"] = parentWysh.questionType;
    parametersJson["options"] = parentWysh.getScalarOptionsAsNames();
    parametersJson["binary_icon_set_id"] = parentWysh.binaryIconSetID;
  }
  else {
    parametersJson["question_type"] = swydget.surveyType
  }

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "ME_BATCH_STIMULI_UPLOAD_SUCCESSFUL"
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
importContent

Imports either a wysh and/or and OrderedWyshList
*/
export function importContent(serverUrl,
                              user,
                              swydget,
                              orderedWyshList,
                              rootWysh=null
                             ) {

  if (user === null || swydget === null || orderedWyshList === null) {
    return
  }


  let importWorksheet = {};
  let wyshArray = [];

  if (rootWysh) {
    wyshArray = wyshArray.concat(rootWysh);
  }

  wyshArray = wyshArray.concat(orderedWyshList.getAllDescendantWyshes());

  for (const wysh of wyshArray) {
    importWorksheet[wysh.wyshId] = {
      originalWysh: wysh,
    }
  }

  const parentWysh = rootWysh ? rootWysh.parentWysh : null;

  // Create all the products
  const endpoint = "/1/product";
  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var requestsArray = [];

  for (const wyshToImport of wyshArray) {

    var formData = new FormData();
    formData.append("creator_mid", user.myMid);
    formData.append("prd_name", wyshToImport.product.name);
    formData.append("prd_price", wyshToImport.product.price);
    formData.append("prd_description", wyshToImport.product.description);
    formData.append("prd_buy_url", wyshToImport.product.buyPage);
    formData.append("prd_attributes_json", JSON.stringify(wyshToImport.product.attributes));
    formData.append("prd_styles_json", JSON.stringify(wyshToImport.product.styles));
    if (wyshToImport.product.imageUrl)
      formData.append("prd_image_url", wyshToImport.product.imageUrl);

    requestsArray.push(axios.post(serverUrl + endpoint, formData, config));
  }

  Promise.all(requestsArray)
    .then(responses => {
      const data = responses.map(response => response.data);

      var products = [];
      for (const response of data) {
        const { product_info } = response;
        products.push(WyshMeProduct.createFromJson(product_info));
      }

      for (const wyshToImportIndex in wyshArray) {
        importWorksheet[wyshArray[wyshToImportIndex].wyshId]['importedProduct'] = products[wyshToImportIndex];
      }

      _importProductsToSwydget(user, serverUrl, swydget, rootWysh, parentWysh, importWorksheet);

      }
    )
    .catch((error) => {
      console.log(error);
    });
}

function _importProductsToSwydget(user, serverUrl, swydget, rootWysh=null, parentWysh, importWorksheet) {

  // So here we are bringing in a bunch of products and a parentWysh to house these Wyshes

  dispatcher.dispatch({
    type: "ME_IMPORT_PRODUCTS_TO_SWYDGET"
  });

  const endpoint = "/1/events/event/" + swydget.eventId + "/wyshes/";
  const encodedEndpoint = endpoint.replace("#", "%23");
  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  var requestsArray = [];

  for (const originalWyshId in importWorksheet) {

    const wyshArtifacts = importWorksheet[originalWyshId]

    if (wyshArtifacts) {
      const originalWysh = wyshArtifacts.originalWysh;
      const importedProduct = wyshArtifacts.importedProduct;

      var parametersJson = {
        "product_info": JSON.parse(importedProduct.toJsonString()),
        "question_type": originalWysh.questionType,
        "options": originalWysh.getScalarOptionsAsNames(),
        "caption": originalWysh.caption,
        "free_response_questions": originalWysh.freeResponseQuestions,
        "binary_icon_set_id": originalWysh.binaryIconSetID,
        "block": originalWysh.isBlock(),
        "randomize_wysh_options": originalWysh.randomizeWyshOptions,
        "mc_include_none_of_the_above": originalWysh.mcIncludeNoneOfTheAbove,
        "mc_include_other": originalWysh.mcIncludeOther,
        "mc_options_limit": originalWysh.mcOptionsLimit,
        "must_view_all": originalWysh.mustViewAll,
        "scalar_description": originalWysh.scalarDescription,
        "forced_choice": originalWysh.forcedChoice,
        "randomize": originalWysh.randomize,
        "issue_sub_groups": originalWysh.issueSubGroups,
        "display_block_card": originalWysh.displayBlockCard, // Deprecated. Should always be false
        "sub_group_count": originalWysh.subGroupCount,
        "limit_free_response": originalWysh.limitFreeResponse,
        "limit_free_response_count": originalWysh.limitFreeResponseCount,
        "hidden_free_response_options": originalWysh.hiddenFreeResponseOptions
      }

      if (originalWysh.getBlockType() || originalWysh.isBlock() === true) {
        parametersJson["block_type"] = originalWysh.getBlockType().blockTypeId;
      }

      requestsArray.push(axios.post(serverUrl + encodedEndpoint, parametersJson, config));
    }
  }

  Promise.all(requestsArray)
    .then(responses => {

      const data = responses.map(response => response.data);
      var importOriginalRootWyshId = null;
      var newWyshes = [];
      var responseIndex = 0;

      //generate the new stimuli/wyshes for the importWorksheet
      for (const originalWyshId in importWorksheet) {
        let response = data[responseIndex];
        if (response.result.wysh_dict) {
          var newWysh = Wysh.createFromJson(response.result.wysh_dict);
          newWysh.setSwydget(swydget);
          importWorksheet[originalWyshId]['newWysh'] = newWysh;
        }
        responseIndex += 1;
      }

      var rootWyshArtifacts = rootWysh && importWorksheet.hasOwnProperty(rootWysh.wyshId) ? importWorksheet[rootWysh.wyshId] : null;


      // connect all the children with their parents
      for (const originalWyshId in importWorksheet) {

        var originalWyshArtifacts = importWorksheet.hasOwnProperty(originalWyshId) ? importWorksheet[originalWyshId] : null;
        if (originalWyshArtifacts) {

          var parentWyshArtifacts = importWorksheet[originalWyshArtifacts.originalWysh.parentWyshId]  ? importWorksheet[originalWyshArtifacts.originalWysh.parentWyshId] : null;

          if (parentWyshArtifacts) {
            parentWyshArtifacts.newWysh.orderedWyshList.add(originalWyshArtifacts.newWysh)
          }
        }
      }

      // Order the blocks
      for (const originalWyshId in importWorksheet) {

        var originalWyshArtifacts = importWorksheet.hasOwnProperty(originalWyshId) ? importWorksheet[originalWyshId] : null;
        if (originalWyshArtifacts) {

          originalWyshArtifacts.newWysh.nextWysh = null;

          var nextWyshArtifacts = importWorksheet[originalWyshArtifacts.originalWysh.nextWyshId]  ? importWorksheet[originalWyshArtifacts.originalWysh.nextWyshId] : null;

          if (nextWyshArtifacts) {
            originalWyshArtifacts.newWysh.nextWysh = nextWyshArtifacts.newWysh;
          }
        }
      }

      // Set up all the Branch Logic
      for (const originalWyshId in importWorksheet) {

        var originalWyshArtifacts = importWorksheet.hasOwnProperty(originalWyshId) ? importWorksheet[originalWyshId] : null;
        if (originalWyshArtifacts) {
          for (const originalBls of originalWyshArtifacts.originalWysh.wyshRouter.branchLogicStatements){

            var newBls = new BranchLogicStatement();

            // If the next wysh is blockComplete or surveyComplete, there is NOT a nextWyshArtifacts to reference
            // In this circumstance, we need to roll a new BLS that just sends the user to the end of the block or swydget
            // Otherwise set the nextWyshId to the NEW Next Wysh
            if (originalBls.nextWyshId === "surveyComplete" || originalBls.nextWyshId === "blockComplete") {
              newBls.nextWyshId = originalBls.nextWyshId;
            }
            else {
              newBls.nextWyshId = importWorksheet[originalBls.nextWyshId] ? importWorksheet[originalBls.nextWyshId].newWysh.wyshId : null;
            }

            if (newBls.nextWyshId) {
              // Duplicate the skip rules
              for (const originalSkipRuleArray of originalBls.skipRules) {
                var andRow = [];
                for (const originalSkipRule of originalSkipRuleArray) {
                  var observedWyshArtifacts = importWorksheet[originalSkipRule.observedWyshId]  ? importWorksheet[originalSkipRule.observedWyshId]: null;
                  if (observedWyshArtifacts) {
                    var newSkipRule = new SkipRule();
                    newSkipRule.observedWyshId = observedWyshArtifacts.newWysh.wyshId;
                    newSkipRule.resultNormalized = originalSkipRule.resultNormalized;
                    newSkipRule.negation = originalSkipRule.negation;
                    andRow.push(newSkipRule);
                  }
                }
              }
              newBls.skipRules.push(andRow);
              originalWyshArtifacts.newWysh.wyshRouter.branchLogicStatements.push(newBls);
            }
          }
        }
      }


      let owl = null;

      // We are adding these things to their appropriate wyshlist
      if (rootWysh) {
        // A rootWysh means we imported a block
        if (parentWysh) {
          // A parentWysh means we are adding this block to its OWL
          owl = parentWysh.orderedWyshList;
          if (rootWyshArtifacts && rootWyshArtifacts.newWysh) {
            owl.add(rootWyshArtifacts.newWysh);
          }
        }
        else {
          // No parent means we are adding this block to the root OWL
          owl = swydget.orderedWyshList;
          for (const originalWyshId in importWorksheet) {
            if (importWorksheet[originalWyshId].newWysh.parentWysh === null) {
              owl.add(importWorksheet[originalWyshId].newWysh);
            }
          }
        }
      }
      else {
        // No Rootwysh means we are bringing in the OWL at the root level
        // We must navigate the OWL in order
        owl = swydget.orderedWyshList;
        for (const originalWyshId in importWorksheet) {
          if (importWorksheet[originalWyshId].newWysh.parentWysh === null) {
            owl.wyshes.push(importWorksheet[originalWyshId].newWysh);
          }
        }
      }

      // Update ALL the wyshes
      if (owl) {
        newWyshes = newWyshes.concat(owl.getAllDescendantWyshes());
        updateWyshes(user.userId, user.password, serverUrl, swydget, newWyshes);
      }

      dispatcher.dispatch({
        type: "ME_IMPORT_PRODUCTS_TO_SWYDGET_SUCCESSFUL",
        eventId: swydget.eventId,
        eventInFocus: swydget
      });
    }
  ).catch((error) => {
    console.log(error);
  });
}

export function submitMaxDiffDecisions(serverUrl, userKey, swydget, maxDiffTrialSetsDecisionsMap, userInteractionJson={}) {

  let jsonObject = {}

  for (let [key, decisionsArray] of maxDiffTrialSetsDecisionsMap) {
    let tsDecisionsArray = []
    for (const d of decisionsArray) {
      tsDecisionsArray.push(d.toJsonObject());
    }
    jsonObject[key] = tsDecisionsArray;
  }

  var parametersJson = {
    "event_id": swydget.eventId,
    "session_timestamp": swydgetStore.sessionTimestamp,
    "max_diff_trial_set_decisions_map": jsonObject,
    "user_interaction_json": userInteractionJson
  }

  if (userKey) {
    parametersJson["user_key"] = userKey;
  }

  if (swydgetStore.versionId) {
    parametersJson["version_id"] = swydgetStore.versionId;
  }  

  const endpoint = "/1/swydget/maxdiff/decisions";

  axios.post(serverUrl + endpoint, parametersJson)
    .then((data) => {


    });
}

/*
takeSnapshot

Submit a swydget and its filter to the backend to persist a snapshot of the current state
of the swydget given the filter.
*/
export function takeSnapshot(serverUrl, user, swydget, name) {

  const endpoint = "/1/events/event/" + swydget.eventId + "/snapshot";
  const encodedEndpoint = endpoint.replace(/#/g, "%23");

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {};

  parametersJson.filter = swydget.filter.toJson();
  parametersJson.name = name;
  parametersJson.color = swydget.snapshotContainer.getNextColor();

  // we need a map of wyshIds to:
  // 1. wordsToOmit
  // 2. selectedFreeResponseDecisions
  var dataScrubbingDict = {}
  for (var i in swydget.wyshes) {
    dataScrubbingDict[swydget.wyshes[i].wyshId] = swydget.wyshes[i].getDataScrubbingDict();
  }

  parametersJson.data_scrubbing_dict = dataScrubbingDict;

  dispatcher.dispatch({
    type: "ME_TAKE_SNAPSHOT"
  });

  console.log(parametersJson);

  axios.post(serverUrl + encodedEndpoint, parametersJson, config)
    .then((data) => {

      getEvent(user.userId, user.password, serverUrl, swydget.eventId);

    }
  )
  .catch(function (error) {
    console.log(error);
  });
}
