import Filter from "./Filter";
import Decision from "./Decision";
import TurfResults from "./turf/TurfResults";

export default class WyshSnapshot
{
  constructor(created) {
    this.wysh = null;
    this.swydget = null;
    this.wyshDecisionCounts = {}; // new Map();
    this.wyshTakerCount = 0;
    this.wordsToOmit = [];
    this.selectedFreeResponseDecisions = [];
    this.created = created;
    this.turfResults = [];
  }

  setTurfResults(turfResults) {
    this.turfResults = turfResults;
  }

  getTurfResults() {
    return this.turfResults;
  }

  setSwydget(swydget) {
    this.swydget = swydget;

    for (const tr of this.turfResults) {
      tr.setSwydget(this.swydget);

    }
  }

  setWysh(wysh) {
    this.wysh = wysh;

    for (const tr of this.turfResults) {
      tr.setBlock(this.wysh);
    }
  }

  deleteTurfResults(turfResults) {
    this.turfResults = this.turfResults.filter(tr => tr.created !== turfResults.created);
  }
  
  static createFromJson(json, created) {

    var snapshot = new WyshSnapshot(created);

    if (json && json.wysh_taker_count) {
      snapshot.wyshTakerCount = json.wysh_taker_count
    }

    if (json && json.wysh_decision_counts) {
      for (const k in json.wysh_decision_counts) {
        // snapshot.wyshDecisionCounts.set(k, json.wysh_decision_counts[k]);
        snapshot.wyshDecisionCounts[k] = json.wysh_decision_counts[k];
      }
    }

    if (json && json.data_scrubbing_dict) {
      if (json.data_scrubbing_dict.words_to_omit) {
        snapshot.wordsToOmit = json.data_scrubbing_dict.words_to_omit;
      }

      if (json.data_scrubbing_dict.selected_free_response_decisions) {
        snapshot.selectedFreeResponseDecisions = json.data_scrubbing_dict.selected_free_response_decisions;
      }
    }

    if (json && json.turf_results) {
      for (const turf_json of json.turf_results) {
        snapshot.turfResults.push(new TurfResults(turf_json.created, turf_json.taker_count, "", true, turf_json.included_stimuli, turf_json.required_stimuli, turf_json.threshold, turf_json.combinations_limit, turf_json.turf_results_map));
      }
    }

    return snapshot;
  }
}
