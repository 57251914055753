import React, { Component } from 'react';
import { Tab, Tabs, Button, Modal } from 'react-bootstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import loginStore from "../../stores/LoginStore";
import meStore from "../../stores/MeStore";
import MeService from "../../services/MeService";
import ExportWyshPopover from "../../hooks/admin/ExportWyshPopover";
import "../../css/swytchbackStyles.css";
import "../../css/swytchbackModal.css";
import BlockTypes from '../../classes/BlockTypes';
import BlockTypeSelector from '../../hooks/admin/blocks/BlockTypeSelector';
import SequentialMonadicConfigureComponent from '../../hooks/admin/blocks/SequentialMonadicConfigureComponent';
import StandardConfigureComponent from '../../hooks/admin/blocks/StandardConfigureComponent';
import MaxDiffConfigureComponent from '../../hooks/admin/blocks/MaxDiffConfigureComponent';
import ForcedChoiceConfigureComponent from '../../hooks/admin/blocks/ForcedChoiceConfigureComponent';
import BlockBranchLogicManagerComponent from '../../hooks/admin/blocks/BlockBranchLogicManagerComponent';
import AdvancedControls from '../../hooks/admin/AdvancedControls';


class BlockOptionsModal extends Component {

  constructor(props) {
    super();

    this.user = loginStore.getLoggedInUser();
    this.updatedBlockAttributesDict = {};
    this.emojiWrapperRef = null;
    this.decisionsLoadedAction = null; // export or re-calc

    this.state = {
      eventInFocus: props.eventInFocus || null,
      blockWysh: props.blockWysh || null,
      showEmojiPicker: false,
      optionInFocus: -1,
      responsesArray: [],
      utilityScoresArray: [],
      showRecalcUtilityScoresModal: false
    };
  }

  componentDidMount() {
    meStore.on("change-decisionsLoaded", this.decisionsLoaded);
  }

  componentWillUnmount() {
    meStore.removeListener("change-decisionsLoaded", this.decisionsLoaded);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if props have changed
    if (prevProps.blockWysh !== this.props.blockWysh) {
      this.setState({blockWysh: this.props.blockWysh});
    }

    // Check if state has changed
    if (prevState.blockWysh !== this.state.blockWysh) {
      console.log("State Swydget has changed:");
    }
  }


  updateBranchLogic = (updatedWysh) => {
    var bw = this.state.blockWysh;

    if (bw) {
      bw.wyshRouter.branchLogicStatements = updatedWysh.wyshRouter.branchLogicStatements
    }
  }

  updateBlock = () => {

    var bw = this.state.blockWysh;

    if (bw) {
      MeService.updateBlock(this.user, this.state.eventInFocus, bw, this.updatedBlockAttributesDict);
      if (this.props.closeBlockOptionsModal) {
        this.props.closeBlockOptionsModal();
      }
    }
  }

  updateBlockCallback = (blockWysh, updatedBlockAttributesDict) => {

    var bw = this.state.blockWysh;

    if (bw) {
      this.updatedBlockAttributesDict = updatedBlockAttributesDict;
      this.setState({ blockWysh: bw });
    }
  }

  displayBatchLogicTab = (blockWysh) => {
    if (blockWysh) {
      switch (blockWysh.getBlockType().blockTypeId) {
        case BlockTypes.STANDARD.blockTypeId:
          return true;

        case BlockTypes.FORCEDCHOICE.blockTypeId:
          return false;
        case BlockTypes.SEQUENTIALMONADIC.blockTypeId:
          return true;

        case BlockTypes.MAXDIFF.blockTypeId:
          return false;

        case BlockTypes.PAIRWISE.blockTypeId:
          return false;

        default:
          return true;
      }
    }

    return true;
  }

  getConfigurationComponent = (blockWysh) => {
    if (blockWysh) {
      switch (blockWysh.getBlockType().blockTypeId) {
        case BlockTypes.STANDARD.blockTypeId:
          return (
            <StandardConfigureComponent
              blockWysh={this.state.blockWysh}
              readOnly={this.props.readOnly}
              updateBlockCallback={this.updateBlockCallback}
            />
          )

        case BlockTypes.FORCEDCHOICE.blockTypeId:
          return (
            <ForcedChoiceConfigureComponent
              blockWysh={this.state.blockWysh}
              readOnly={this.props.readOnly}
              updateBlockCallback={this.updateBlockCallback}
            />
          )

        case BlockTypes.SEQUENTIALMONADIC.blockTypeId:
          return (
            <SequentialMonadicConfigureComponent
              blockWysh={this.state.blockWysh}
              readOnly={this.props.readOnly}
              updateBlockCallback={this.updateBlockCallback}
            />
          )

        case BlockTypes.MAXDIFF.blockTypeId:
          return (
            <MaxDiffConfigureComponent
              blockWysh={this.state.blockWysh}
              readOnly={this.props.readOnly}
              updateBlockCallback={this.updateBlockCallback}
            />
          )

        case BlockTypes.PAIRWISE.blockTypeId:
          return (
            <MaxDiffConfigureComponent
              blockWysh={this.state.blockWysh}
              readOnly={this.props.readOnly}
              updateBlockCallback={this.updateBlockCallback}
            />
          )

        default:
          return (
            <SequentialMonadicConfigureComponent
              blockWysh={this.state.blockWysh}
              readOnly={this.props.readOnly}
              updateBlockCallback={this.updateBlockCallback}
            />
          )
      }
    }
  }

  decisionsLoaded = () => {
    // Here we want to generate the report and down load it.
    // The event has the decisions
    const bt = this.state.blockWysh.getBlockType();

    if (bt && (bt.equals(BlockTypes.MAXDIFF) || bt.equals(BlockTypes.PAIRWISE))) {
      this.setState({
        responsesArray: bt.generateResponsesArray(this.state.blockWysh),
        utilityScoresArray: bt.generateUtilityScoreArray(this.state.blockWysh)
      });
    }

    switch (this.decisionsLoadedAction) {
      case "export":
        this.exportBlockSpreadsheet();
        break;
      case "re-calc":
        this.openRecalcUtilityScoresModal();
        break;
      default:
        break;
    }
  }

  exportBlockSpreadsheet = () => {

    const fileName = this.state.blockWysh ? this.state.blockWysh.getBlockName() + "-max-diff-report" : "max-diff-report";

    const stimuliArray = []
    const stimuli = this.state.blockWysh.orderedWyshList.getOrderedWyshes();

    let index = 1;
    stimuliArray.push(["index", "prd_uuid", "description"]);
    for (const stimulus of stimuli) {
      stimuliArray.push([index, stimulus.product.productId, stimulus.product.description]);
      index += 1;
    }

    const mdra = this.state.responsesArray;

    for (const maxDiffResponses of mdra) {
      const mid = maxDiffResponses[0];
      const mdv = maxDiffResponses[1];

      const midToMdv = meStore.midToMdv[mid]
      if (midToMdv) {
        const correctMdv = parseInt(midToMdv, 10);
        if (correctMdv !== mdv) {
          maxDiffResponses[1] = correctMdv;
        }
      }
    }

    const wb = XLSX.utils.book_new();
    const stimuliWs = XLSX.utils.aoa_to_sheet(stimuliArray);
    const decisionsWs = XLSX.utils.aoa_to_sheet(this.state.responsesArray);
    const utilityScoresWs = XLSX.utils.aoa_to_sheet(this.state.utilityScoresArray);
    XLSX.utils.book_append_sheet(wb, stimuliWs, "Stimuli");
    XLSX.utils.book_append_sheet(wb, decisionsWs, "Decisions");
    XLSX.utils.book_append_sheet(wb, utilityScoresWs, "Utility Scores");
    XLSX.writeFile(wb, fileName + ".xlsx");
  }

  fetchDecisions = () => {
    this.decisionsLoadedAction = "export";
    MeService.getDecisions(this.state.blockWysh.event);
  }

  openRecalcUtilityScoresModal = () => {
    this.setState({ showRecalcUtilityScoresModal: true });
  }

  closeRecalcUtilityScoresModal = () => {
    MeService.getEvent(this.user.userId, this.user.password, this.state.blockWysh.event.eventId);
    this.setState({ showRecalcUtilityScoresModal: false });
  }

  render() {

    const { showBlockOptionsModal, closeBlockOptionsModal, readOnly } = this.props;

    let buttonStyle = {
      width: 130,
      marginLeft: 5,
      marginRight: 5
    };

    return (
      <Modal
        size="xl"
        show={showBlockOptionsModal}
        onHide={closeBlockOptionsModal}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header className="swytchbackModalHeader" closeButton>
          <Modal.Title className="swytchbackModalTitle">Block Options</Modal.Title>
        </Modal.Header>
        <Modal.Body className="swytchbackModalBody coloredBackground">
          <div className="hookFullPaneContainer swytchbackGradientPane">
            <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Type">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <div className='rowContainerSpaced fullWidthPane standardModalHeight'>
                      <BlockTypeSelector
                        readOnly={this.props.readOnly}
                        blockWysh={this.state.blockWysh}
                        updateBlockCallback={this.updateBlockCallback}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Configure">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <div className='rowContainerSpaced fullWidthPane standardModalHeight'>
                      {this.getConfigurationComponent(this.state.blockWysh)}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={3} title="Flow Logic">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <div className='rowContainerSpaced fullWidthPane standardModalHeight'>
                      <BlockBranchLogicManagerComponent
                        swydget={this.props.eventInFocus}
                        blockWysh={this.state.blockWysh}
                        updateBlockWyshCallback={this.updateBlockCallback}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={4} title="Advanced">
                <div className="fullPaneContainer">
                  <div className="contentContainer standardModalHeight">
                    <div className='columnCenteredContainer fullWidthPane'>
                      <AdvancedControls
                        user={this.user}
                        show={this.state.showRecalcUtilityScoresModal}
                        handleClose={this.closeRecalcUtilityScoresModal}
                        block={this.state.blockWysh}
                        swydget={this.state.eventInFocus}
                        importingState={this.state.importingState}
                        fetchDecisions={this.fetchDecisions}
                        snapshot={this.props.snapshot}
                        closeModal={closeBlockOptionsModal}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer className="swytchbackModalFooter">
          <ExportWyshPopover
            id={"export-name-popover"}
            buttonText="Export"
            title="Set Export Name"
            content="This is the content of the popover."
            placement="top"
            stimuli={this.state.blockWysh}
          />
          <Button className="swytchbackActiveButton" style={buttonStyle} onClick={closeBlockOptionsModal}>Cancel</Button>
          <Button
            className={this.props.readOnly ? "swytchbackInactiveButton" : "swytchbackActiveButton"}
            style={buttonStyle}
            disabled={this.props.readOnly}
            onClick={this.updateBlock}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

    );
  };
}

export default BlockOptionsModal
