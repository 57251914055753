import React, { useState } from "react";
import { ProgressBar } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
} from 'chart.js';
import { Bar, Line } from "react-chartjs-2";
import classes from "../../css/modules/Stats.module.css";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);



ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);



const StimulusBarAndLineCharts = ({
  stimulus,
  mode,
  snapshot,
  snapshotsToDisplay = [],
  defaultChart = "bar-horizontal",
  defaultHorizontal = true,
  showControls = true
}) => {

  const [selectedChart, setSelectedChart] = useState(defaultChart);
  const [sortOrder, setSortOrder] = useState("ordered");
  const [showLineChart, setShowLineChart] = useState(false);
  const [horizontal, setHorizontal] = useState(defaultHorizontal);
  const [focusOnPrimaryCut, setFocusOnPrimaryCut] = useState(true);

  const borderColor = mode ? (mode === "dark" ? "#2f4b43" : "#D5D5D5") : "#D5D5D5";
  const textColor = mode ? (mode === "dark" ? "#ffffff" : "#000000") : "#000000";


  const generateLabels = (stimulus) => {

    let labels = [];

    switch (stimulus.getQuestionType()) {
      case "nps":
        labels = ["NPS Score"];
        break; f
      case "csat":
        labels = ["Top Box", "Top 2 Box"];
        break;
      default:
        labels = stimulus.wyshOptions.map((option) => {
          return `${option.resultLiteral}`;
        });
    }
    return labels;
  }

  const generateDataArray = (stimulus) => {

    const takerCount = stimulus.takerCount;
    let dataArray = [];



    switch (stimulus.getQuestionType()) {
      case "nps":
        if (stimulus.wyshOptions.length === 11) {
          return calculateScore(stimulus);
        }
        break;
      case "csat":
        if (stimulus.wyshOptions.length === 5) {
          return calculateScore(stimulus);
        }

        break;
      default:
        dataArray = stimulus.wyshOptions.map((option) => {
          const percentage = option.decisionsCount > 0 ? 100 * option.decisionsCount / takerCount : 0.0;
          return percentage.toFixed(1);
        });

    }

    return dataArray
  }

  const generateWyshOptionCountsArray = (stimulus) => {

    let dataArray = []

    const takerCount = stimulus.takerCount;

    switch (stimulus.getQuestionType()) {
      case "nps":
        if (stimulus.wyshOptions.length === 11) {
          const promoterCount = stimulus.getTopScoresCount(2);
          const detractorCount = stimulus.getBottomScoresCount(7);
          const passiveCount = takerCount - promoterCount - detractorCount;
          dataArray.push(promoterCount);
          dataArray.push(passiveCount);
          dataArray.push(detractorCount);
        }
        return dataArray;
      case "csat":
        if (stimulus.wyshOptions.length === 5) {
          const topBoxCount = stimulus.getTopScoresCount(1);
          const top2BoxCount = stimulus.getTopScoresCount(2);
          dataArray.push(topBoxCount);
          dataArray.push(top2BoxCount);
        }

        return dataArray;
      default:
        return stimulus.wyshOptions.map((option) => {
          return option.decisionsCount
        });
    }
  }

  const calculateScore = (stimulus) => {

    const qt = stimulus.getQuestionType();

    switch (qt) {
      case "nps":
        const npsScores = stimulus.calculateNpsScore();
        return [Math.round(npsScores.npsScore)];
      case "csat":
        const csatScores = stimulus.calculateCsatScore();
        return [Math.round(csatScores.topBoxScore), Math.round(csatScores.top2BoxScore)];
      default:
        return ""
    }
  }

  const loadDatasets = (stimulus) => {

    const datasets = [];

    // The stimulus is loaded with data from either a live pull or from the snapshot that has been applied.
    // This is the view of the stimulus in focus and should always be displayed first.
    // The snapshot is undefined if a fresh pull has been initiated.

    // Load additional snapshots to display
    if (stimulus && stimulus.event) {
      for (const s of snapshotsToDisplay) {
        if (s && s.equals(snapshot) === false) {
          stimulus.applySnapshot(s);
          const data = generateDataArray(stimulus);
          datasets.push({
            label: s.name + " (" + stimulus.takerCount + ")",
            backgroundColor: s.color,
            borderColor: focusOnPrimaryCut ? "#aeaeae" : s.color,
            borderWidth: showLineChart ? focusOnPrimaryCut ? 4 : 2 : 0,
            data: data,
            wyshOptionCountsArray: generateWyshOptionCountsArray(stimulus),
            score: calculateScore(stimulus),
            snapshot: s
          });
        }
      }
    }

    if (snapshot) {
      stimulus.applySnapshot(snapshot);
      datasets.push({
        label: snapshot.name + " (" + stimulus.takerCount + ")",
        takerCount: stimulus.takerCount,
        borderColor: snapshot.color,
        backgroundColor: snapshot.color,
        borderWidth: showLineChart ? focusOnPrimaryCut ? 4 : 2 : 0,
        data: generateDataArray(stimulus),
        wyshOptionCountsArray: generateWyshOptionCountsArray(stimulus),
        score: calculateScore(stimulus),
        snapshot: snapshot
      });
    }
    else {
      datasets.push({
        label: "All Data",
        borderColor: "#0059ff",
        borderWidth: showLineChart ? focusOnPrimaryCut ? 4 : 2 : 0,
        backgroundColor: "#0059ff",
        data: generateDataArray(stimulus),
        wyshOptionCountsArray: generateWyshOptionCountsArray(stimulus),
        score: calculateScore(stimulus),
        snapshot: null
      });
    }

    return datasets;
  };


  const horizontalBarOptions = {
    indexAxis: 'y',
    elements: {
      bar: {},
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: textColor,
        },
        grid: {
          color: borderColor
        }
      },
      x: {
        beginAtZero: true,
        grid: {
          color: borderColor
        },
        ticks: {
          color: textColor,
          callback: function (value) {
            return value + "%";
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        padding: 14,
        displayColors: false,
        titleFont: {
          size: 20
        },
        callbacks: {
          title: (context) => {
            let label = context.length > 0 ? context[0].dataset.label : '';
            return label;
          },
          label: function (context) {
            let pct = context.formattedValue += "%";
            return [context.label, "Percentage: " + pct, "Count: " + context.dataset.wyshOptionCountsArray[context.dataIndex]];
          }
        }
      },
    },
  };

  const verticalDraw = {
    id: 'verticalDraw',
    afterDatasetsDraw(chart) {
      const { ctx, data, chartArea } = chart;
      if (!chartArea) return;

      // Your custom code here
      ctx.save();
      ctx.font = 'bold 14px Arial';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      if (horizontal === false) {
        data.datasets.forEach((dataset, datasetIndex) => {
          const meta = chart.getDatasetMeta(datasetIndex);
          meta.data.forEach((bar, index) => {
            const value = dataset.data[index];
            const sign = Math.sign(value) < 0 ? -1 : 1;

            ctx.fillText(value, bar.x, bar.y - 10 * sign)
          })
        })
      }

      ctx.restore();
    }
  }

  const verticalBarOptions = {
    indexAxis: 'x',
    scales: {
      y: {
        grace: 5,
        ticks: {
          color: textColor,
          callback: function (value) {
            const units = stimulus.getQuestionType() === "nps" || stimulus.getQuestionType() === "csat" ? "" : "%"
            return value + units;
          }
        },
        grid: {
          color: borderColor, // Grid line color
        }
      },
      x: {
        beginAtZero: true,
        grid: {
          color: borderColor, // Grid line color
        },
        ticks: {
          color: textColor,
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        padding: 14,
        displayColors: false,
        titleFont: {
          size: 20
        },
        callbacks: {
          title: (context) => {
            let label = context.length > 0 ? context[0].dataset.label : '';
            return label;
          },
          label: function (context) {
            let pct = context.formattedValue += "%";
            const statsArray = [context.label, "Count: " + context.dataset.wyshOptionCountsArray[context.dataIndex]];
            if (context.dataset.score) {
              statsArray.push("Score: " + context.dataset.score[context.dataIndex]);
            }
            else {
              statsArray.push("Percentage: " + pct);
            }

            return statsArray;
          }
        }
      }
    },
  };

  const lineOptions = {
    indexAxis: 'x',
    elements: {
      bar: {},
    },
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: textColor
        },
        grid: {
          color: borderColor
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: borderColor
        },
        ticks: {
          color: textColor,
          callback: function (value) {
            return value + "%";
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        padding: 14,
        displayColors: false,
        titleFont: {
          size: 20
        },
        callbacks: {
          title: (context) => {
            let label = context.length > 0 ? context[0].dataset.label : '';
            return label;
          },
          label: function (context) {
            let pct = context.formattedValue += "%";
            return [context.label, "Percentage: " + pct, "Count: " + context.dataset.wyshOptionCountsArray[context.dataIndex]];
          }
        }
      },
    },
  };

  switch (sortOrder) {
    case 'high-to-low':
      stimulus.wyshOptions.sort((a, b) => b.decisionsCount - a.decisionsCount);
      break;
    case 'low-to-high':
      stimulus.wyshOptions.sort((a, b) => a.decisionsCount - b.decisionsCount);
      break;
    default:
      stimulus.wyshOptions.sort((a, b) => a.resultNormalized - b.resultNormalized);
  }


  const data = {
    labels: generateLabels(stimulus),
    datasets: loadDatasets(stimulus)
  };

  const selectChartType = (e) => {
    const chartType = e.target.value;

    setSelectedChart(chartType);

    switch (chartType) {
      case 'bar-horizontal':
        setShowLineChart(false);
        setHorizontal(true);
        setFocusOnPrimaryCut(false);
        break;
      case 'bar-vertical':
        setShowLineChart(false);
        setHorizontal(false);
        setFocusOnPrimaryCut(false);
        break;
      case 'line-all':
        setShowLineChart(true);
        setFocusOnPrimaryCut(false);
        setHorizontal(false)
        break;
      case 'line-focus':
        setShowLineChart(true);
        setFocusOnPrimaryCut(true);
        setHorizontal(false)
        break;
      default:
        setShowLineChart(false);
        setHorizontal(true);
        setFocusOnPrimaryCut(false);
    }
  }

  const getChart = () => {
    if (showLineChart === true) {
      return (
        <Line options={lineOptions} data={data} />
      )
    }
    else {
      if (horizontal === true) {
        return (
          <Bar key="horizontal-bar" options={horizontalBarOptions} data={data} />
        )
      }
      else {
        if (stimulus.getQuestionType() === "nps" || stimulus.getQuestionType() === "csat") {
          return (
            <Bar key="vertical-bar" options={verticalBarOptions} data={data} plugins={[verticalDraw]} />
          )
        }
        else {
          return (
            <Bar key="vertical-bar" options={verticalBarOptions} data={data} />
          )
        }
      }

    }
  }

  const generateCharts = (stimulus) => {
    switch (selectedChart) {
      case 'bar-horizontal':
        return (
          <div className={`${classes.chartContainer}`} style={{ height: stimulus.wyshOptions.length * 25, minHeight: 350 }}>
            {getChart()}
          </div>
        )
      default:
        return (
          <div className={`${classes.chartContainer}`} style={{ maxHeight: 350 }}>
            {getChart()}
          </div>
        )
    }
  }

  return (
    <div className={`${classes.sectionContainer}`}>
      {generateCharts(stimulus)}
      {showControls === true &&
        <div className={`${classes.controlPanelContainer}`}>
          <div className={`${classes.centeredColumnContainer}`}>
            <div className={`${classes.centeredRowContainer}`}>
              <div className={`${classes.label}`} style={{ color: textColor }}>
                Chart Type:
              </div>
              <FormControl>
                <RadioGroup
                  row
                  className={`${classes.buttonRowContainer}`}
                  name="if-selected"
                  value={selectedChart}
                  onChange={(e) => { selectChartType(e) }}>
                  <FormControlLabel
                    value={"bar-horizontal"}
                    style={{ color: textColor }}
                    label={"Horizontal Bar"}
                    control={
                      <Radio
                        color='primary'
                        disabled={false}
                        sx={{
                          color: textColor,
                          '&.Mui-checked': {
                            color: textColor,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={"bar-vertical"}
                    style={{ color: textColor }}
                    label={"Vertical Bar"}
                    control={
                      <Radio
                        color='primary'
                        disabled={false}
                        sx={{
                          color: textColor,
                          '&.Mui-checked': {
                            color: textColor,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={"line-all"}
                    style={{ color: textColor }}
                    label={"Line - All"}
                    control={
                      <Radio
                        color='primary'
                        disabled={false}
                        sx={{
                          color: textColor,
                          '&.Mui-checked': {
                            color: textColor,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={"line-focus"}
                    style={{ color: textColor }}
                    label={"Line - Focus"}
                    control={
                      <Radio
                        color='primary'
                        disabled={false}
                        sx={{
                          color: textColor,
                          '&.Mui-checked': {
                            color: textColor,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={`${classes.centeredRowContainer}`}>
              <div className={`${classes.label}`} style={{ color: textColor }}>
                Sort:
              </div>
              <FormControl>
                <RadioGroup
                  row
                  className={`${classes.buttonRowContainer}`}
                  name="if-selected"
                  value={sortOrder}
                  onChange={(e) => { setSortOrder(e.target.value) }}>
                  <FormControlLabel
                    value={"ordered"}
                    style={{ color: textColor }}
                    label={"Ordered"}
                    control={
                      <Radio
                        color='primary'
                        disabled={false}
                        sx={{
                          color: textColor,
                          '&.Mui-checked': {
                            color: textColor,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={"high-to-low"}
                    style={{ color: textColor }}
                    label={"High to Low"}
                    control={
                      <Radio
                        color='primary'
                        disabled={false}
                        sx={{
                          color: textColor,
                          '&.Mui-checked': {
                            color: textColor,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={"low-to-high"}
                    style={{ color: textColor }}
                    label={"Low to High"}
                    control={
                      <Radio
                        color='primary'
                        disabled={false}
                        sx={{
                          color: textColor,
                          '&.Mui-checked': {
                            color: textColor,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 16,
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default StimulusBarAndLineCharts;
