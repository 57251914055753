import React, { useState, useEffect } from "react";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import classes from "../../../css/modules/TurfModal.module.css";
import "../../../css/swytchbackModal.css"
import SwytchbackButton from '../../admin/controls/SwytchbackButton';
import TurfResultsWidget from './TurfResultsWidget';
import SwytchbackSelect from '../../admin/controls/SwytchbackSelect';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import SwytchbackFrameButton from "../../admin/controls/SwytchbackFrameButton";
import AdminActionsHook from "../../actions/AdminConsoleActionsHook";
import useHttpPost from "../../utilities/AxiosHttpPost";
import TurfResults from "../../../classes/turf/TurfResults";
import MeService from "../../../services/MeService";
import BlockTypes from "../../../classes/BlockTypes";
import TurfStimuliSelectorModal from "./TurfStimuliSelectorModal";


const TurfModal = ({user, block, snapshot, showTurfModal, closeTurfModal}) => {

  const { postData, postLoading, postError, post } = useHttpPost(user);
  const [includedStimuliProductIds, setIncludedStimuliProductIds] = useState([]);
  const [requiredStimuliProductIds, setRequiredStimuliProductIds] = useState([]);
  const [combinationsLimit, setCombinationsLimit] = useState(0);
  const [turfThreshhold, setTurfThreshold] = useState(0);
  const [isWorking, setIsWorking] = useState(false);

  const limit = 15;
      
  let turfReachResults = {};
  
  useEffect(() => {

    if(includedStimuliProductIds.length === 0) {
      if (block.getBlockType().equals(BlockTypes.MAXDIFF) === true || block.getBlockType().equals(BlockTypes.PAIRWISE) === true) {
        const stimuli = block.orderedWyshList.getFilteredSortedWyshes("desc", null);
        
        const productIds = []
        stimuli.map((stimulus) => {
          if (productIds.length < limit){
            productIds.push(stimulus.product.productId);
          }
        })

        setIncludedStimuliProductIds(productIds);
        setCombinationsLimit(productIds.length);
      }
    }
    
  }, [snapshot])

  const generateTurfResults = () => {

    block.turfResults.sort((a, b) => {
      return b.created - a.created;
    })

    if (block.turfResults && block.turfResults.length > 0) {
      return (
        <div className="columnCenteredContainer">
          {block.turfResults.map((turfResults) => {
            return (
              <TurfResultsWidget key={turfResults.created} turfResults={turfResults} swydget={block.event} snapshot={snapshot} />
            )
          })}
        </div>
      )
    }
    else {
      return (
        <div>
          no turf results
        </div>
      )
    }
  }

  const handleIncludedStimuliChange = (evt) => {

    const { name } = evt.target;

    const idx = includedStimuliProductIds.indexOf(name);

    if (idx !== -1) {
      includedStimuliProductIds.splice(idx, 1);
      setIncludedStimuliProductIds([...includedStimuliProductIds])
      setCombinationsLimit(includedStimuliProductIds.length);
      // if (combinationsLimit > includedStimuliProductIds.length) {
      //   setCombinationsLimit(includedStimuliProductIds.length);
      // }

      const requiredIdx = requiredStimuliProductIds.indexOf(name);
      if (requiredIdx !== -1) {
        requiredStimuliProductIds.splice(requiredIdx, 1);
        setRequiredStimuliProductIds([...requiredStimuliProductIds])
      }
    }
    else {
      if (includedStimuliProductIds.length < limit) {
        setIncludedStimuliProductIds([
          ...includedStimuliProductIds,
          name
        ])
        setCombinationsLimit(includedStimuliProductIds.length);
      }
    }

    
    
  }

  const handleRequiredStimuliChange = (evt) => {

    const { name } = evt.target;

    const idx = requiredStimuliProductIds.indexOf(name);    

    if (idx !== -1) {
      requiredStimuliProductIds.splice(idx, 1);
      setRequiredStimuliProductIds([...requiredStimuliProductIds])
    }
    else {
      setRequiredStimuliProductIds([
        ...requiredStimuliProductIds,
        name
      ])
    }
  }

  const generateIncludedStimuliControl = () => {
    
    return (
      <FormGroup style={{
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
      }}>
        {block.orderedWyshList.getFilteredSortedWyshes("desc", null).map((stimulus) => {

          const isIncluded = includedStimuliProductIds.includes(stimulus.product.productId); 
          
          return (
            <div key={stimulus.product.productId} className={`${classes.rowContainer}`}>
              <FormControlLabel
                style={{ margin: 0, marginRight: 30,padding: 0 }}
                control={
                  <Checkbox
                    checked={includedStimuliProductIds.includes(stimulus.product.productId)}
                    onChange={handleIncludedStimuliChange}
                    name={stimulus.product.productId}
                    style={{ color: '#ffffff' }}
                  />
                }
                label={
                  <div className={`${classes.stimulusLabel}`}>
                    <div>
                      {stimulus.product.getUnformattedText()} 
                    </div>
                    <div>
                      {stimulus.getAverageScore().toFixed(1)}
                    </div>  
                  </div>
                }
              />
              {isIncluded &&
              <FormControlLabel
                style={{ margin: 0, padding: 0 }}
                control={
                  <Checkbox
                    checked={requiredStimuliProductIds.includes(stimulus.product.productId)}
                    onChange={handleRequiredStimuliChange}
                    name={stimulus.product.productId}
                    style={{ color: '#ffffff' }}
                  />
                }
                label={
                  <div className={`${classes.stimulusLabel}`}>
                    <div>
                      Required
                    </div>
                  </div>
                }
              /> 
              }
            </div>
          )
        }
        )
        }
      </FormGroup>
    )
  }

  const generateReach = () => {
    const { getDecisions } = AdminActionsHook();
    setIsWorking(true);
    getDecisions(user, block.event, getDecisionsCallback);
  }

  const getDecisionsCallback = (decisionsReceivedArray) => {
    
    block.event.setAllDecisionsArray(decisionsReceivedArray);
    turfReachResults = block.getBlockType().generateTurfReachResults(block, turfThreshhold);
    generateTurf();
  }

  const filterStimuli = () => {
    
    const includedStimuliProductIdsSet = new Set(includedStimuliProductIds);
    includedStimuliProductIdsSet.add("sys_RespNum");

    for (const productId in turfReachResults) {
      if (!includedStimuliProductIdsSet.has(productId)) {
        delete turfReachResults[productId];
      }
    }
  }

  const generateTurf = () => {

    const endpoint = "https://l6zoodfwcejmjjuu4ohog4tmma0flnjd.lambda-url.us-west-2.on.aws/"
    // const endpoint = "https://3jj4upy5uabatnyxvaij5d3zj40rdgsi.lambda-url.us-west-2.on.aws/"

    
    filterStimuli();

    const parametersJson = {
      "data": turfReachResults,
      "required_stimuli": requiredStimuliProductIds,
      "combinations_limit": combinationsLimit
    }

    post(endpoint, parametersJson, turfReachResultsUploadCallback);
  }

  const turfReachResultsUploadCallback = (data) => {

    if (snapshot) {
      block.applySnapshot(snapshot);
    }

    const requiredStimuliProductIds = data.results.required_stimuli ? data.results.required_stimuli : [];

    const turfResults = new TurfResults(
      data.results.created, 
      block.getBlockTakerCount(), 
      data.results.message, 
      data.results.success, 
      includedStimuliProductIds,
      requiredStimuliProductIds, 
      turfThreshhold, 
      combinationsLimit,
      data.results.max_diff_results
    );

    const parametersJson = {
      "turf_results": turfResults.toJson(),
      "snapshot_id": snapshot.created.toString(),
      "required_stimuli": requiredStimuliProductIds,
      "included_stimuli": includedStimuliProductIds
    }

    const endpoint = MeService.serverUrl + ":" + MeService.wm_new_port + "/1/events/event/" + block.event.eventId + "/turf/" + block.wyshId;
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    post(encodedEndpoint, parametersJson, turfResultsSubmittedCallback);
  }

  const turfResultsSubmittedCallback = (data) => {
    setIsWorking(false);
    MeService.getEvent(user.userId, user.password, block.event.eventId);
    closeTurfModal();
  }

  const generateCombintionLimitOptions = () => {
    
    const menuItems = [];
  
    for (let i = 1; i <= includedStimuliProductIds.length; i++) {
      menuItems.push(<MenuItem key={i} value={i}>{"Top " + i.toString()}</MenuItem>)
    }

    return menuItems;
  }

  
  return (
    <Modal
      onHide={closeTurfModal}
      size="xl"
      show={showTurfModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Analysis</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody swytchbackGradientPane">
        <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="advanced-tabs">
          <Tab eventKey={1} title="Create TURF">
            <div className="fullPaneContainer">
              <div className="contentContainer">
                <div className='fullWidthPane tallModalHeight'>
                  <TurfStimuliSelectorModal
                    block={block}
                    turfThreshhold={turfThreshhold} 
                    setTurfThreshold={setTurfThreshold}
                    combinationsLimit={combinationsLimit}
                    setCombinationsLimit={setCombinationsLimit}
                    includedStimuliProductIds={includedStimuliProductIds}
                    requiredStimuliProductIds={requiredStimuliProductIds}
                    handleIncludedStimuliChange={handleIncludedStimuliChange}
                    handleRequiredStimuliChange={handleRequiredStimuliChange}
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey={2} title="TURF RESULTS">
            <div className="fullPaneContainer">
              <div className="contentContainer">
                <div className='rowContainerSpaced fullWidthPane standardModalHeight'>
                  {generateTurfResults()}
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        {isWorking && (
            <CircularProgress
              size={24}
              thickness={6}
              sx={{
                color: 'green'
              }}
            />
          )
        }
        <SwytchbackButton 
          disabled={isWorking}
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={() => generateReach()}
        >
          Generate TURF
        </SwytchbackButton>
        <SwytchbackButton 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={closeTurfModal}
        >
          Close
        </SwytchbackButton>
      </Modal.Footer>
    </Modal>
    );
};
export default TurfModal;