import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import SwytchbackFormControlLabel from "./SwytchbackFormControlLabel";
import classes from '../../../css/modules/SwytchbackRadioButtonGroup.module.css';


export default function SwytchbackRadioButtonGroup({ifSelected, ifSelectedChanged, disabled=false, circleColor="#ffffff", checkColor="#0050FF", leftLabel="If", rightLabel="If Not"}) {

  return (
    <FormControl>
      <RadioGroup
        row
        className={`${classes.buttonRowContainer}`}
        name="if-selected"
        value={ifSelected}
        onChange={ifSelectedChanged}>
        <SwytchbackFormControlLabel 
          value={false} 
          style={{color: circleColor}}
          label={leftLabel}
          control={
            <Radio 
              color='primary'
              disabled={disabled}
              sx={{
                color: circleColor,
                '&.Mui-disabled': {
                  color: "#aeaeae"
                },
                '&.Mui-checked': {
                  color: checkColor,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 16,
                },
              }}
            />
          } 
        />
        <SwytchbackFormControlLabel 
          value={true} 
          label={rightLabel} 
          style={{color: circleColor}}
          control={
            <Radio 
              color='primary'
              disabled={disabled}
              sx={{
                color: circleColor,
                '&.Mui-disabled': {
                  color: "#aeaeae"
                },
                '&.Mui-checked': {
                  color: checkColor,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 16,
                },
              }}
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
