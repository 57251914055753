import React, { Component } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import { TextField, Table, TableBody, TableCell, TableRow, Paper } from '@mui/material';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import classes from "../css/modules/SwydgetReports.module.css";
import Attribute from "../classes/Attribute";
import meStore from "../stores/MeStore";
import loginStore from "../stores/LoginStore";
import User from '../classes/User';
import Decision from '../classes/Decision';
import Event from '../classes/Event';
import '../css/swytchbackStyles.css';
import SurveyTakerTableRow from "../components/SurveyTakerTableRow";
import SwytchbackFrameButton from '../hooks/admin/controls/SwytchbackFrameButton';


class SwydgetReports extends Component {

  constructor(props) {
    super(props);

    this.createdTimeSinceEpoch = props.createdTimeSinceEpoch * 1000;
    this.title = props.title;
    this.decisionsBucket = [];
    this.decisiosnBucketIncludeDupes = [];

    this.exportFreeResponseSpreadsheet = this.exportFreeResponseSpreadsheet.bind(this);
    this.exportSummarySpreadsheet = this.exportSummarySpreadsheet.bind(this);
    this._swydgetDecisionsLoaded = this._swydgetDecisionsLoaded.bind(this);
    
    this.state = {
      eventInFocus: props.eventInFocus,
      decisionsLoaded: false
    };
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  componentWillMount() {
    meStore.on("change-decisionsLoaded", this._swydgetDecisionsLoaded);
  }

  componentWillUnmount() {
    meStore.removeListener("change-decisionsLoaded", this._swydgetDecisionsLoaded);
  }

  _swydgetDecisionsLoaded() {
    var eif = this.state.eventInFocus;

    for (var i = 0; i < eif.allDecisionsArray.length; i++) {
      let dec = eif.allDecisionsArray[i];

      let w = eif.findWyshByProductId(dec.productId);
      w.decisionsArray.push(dec);
    }

    this.decisionsBucket = eif.getDecisionsByMidArray();
    this.decisiosnBucketIncludeDupes = eif.getDecisionsByMidWithDupesArray();
  }

  
 
  exportFreeResponseSpreadsheet(fileName) {

    const takersInfoArray = this.decisionsBucket[1];
    const takersFreeResponseDict = this.decisionsBucket[3];

    const wb = XLSX.utils.book_new();

    for (var wyshId in takersFreeResponseDict) {

      if (takersFreeResponseDict[wyshId].wysh.freeResponseQuestions[0] || takersFreeResponseDict[wyshId].wysh.questionType === "free-response-only") {

        var textBlob = "";
        var exportArray = [];
        var decArray = takersFreeResponseDict[wyshId].decisions;
        decArray.sort(Decision.compareByResultNormalizedDesc);
        let questionLabel = "Q" + takersFreeResponseDict[wyshId].question_number;
        exportArray.push([
          "Respondent Index", 
          "Respondent MID", 
          "Respondent Email", 
          questionLabel + ": " + takersFreeResponseDict[wyshId].wysh.product.name, 
          takersFreeResponseDict[wyshId].question,
          "Card Text:",
          takersFreeResponseDict[wyshId].wysh.product.getCardTextPlain()
        ]);

        for (var i = 0; i < decArray.length; i++) {
          if (decArray[i].freeResponseAnswers[0] && decArray[i].freeResponseAnswers[0].length > 0) {
            let respondentInfo = Event.findTakerInfo(decArray[i].takerMid, takersInfoArray);
            exportArray.push(
              [
                respondentInfo && respondentInfo.length > 2 ? respondentInfo[0] : "",
                decArray[i].takerMid,
                respondentInfo && respondentInfo.length > 2 ? respondentInfo[2] : "",
                decArray[i].resultLiteral,
                decArray[i].freeResponseAnswers[0]
              ]
            );
            if (textBlob.length + decArray[i].freeResponseAnswers[0].length < 4900) {
              textBlob += ". ";
              textBlob += decArray[i].freeResponseAnswers[0];
            }
          }
        }

        const ws = XLSX.utils.aoa_to_sheet(exportArray);
        XLSX.utils.book_append_sheet(wb, ws, questionLabel);
      }
    }

    if (Object.keys(wb.Sheets).length > 0) {
      XLSX.writeFile(wb, fileName + ".xlsx")
    }
  }

  exportSummarySpreadsheet(swydget, fileName) {

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(swydget.generateSummaryArray());
    XLSX.utils.book_append_sheet(wb, ws, "Summary");
    XLSX.writeFile(wb, fileName + ".xlsx");
  }

  exportCohortsSpreadsheet(swydget, fileName) {

    const wb = XLSX.utils.book_new();
    const stimuliArray = swydget.generateCohortSummaryArray();
    const ws = XLSX.utils.aoa_to_sheet(stimuliArray);
    
    // console.log(ws);
    
    // for (const k in ws) {
    //   console.log(k);
    //   const cell = ws[k];
    //   console.log(typeof cell['v'] + " --> " + cell['v']);
    //   if (typeof cell['v'] === 'number' && cell['v'] < 1) {
        
    //     cell.z = "0.00%"
    //   }
    // }

    XLSX.utils.book_append_sheet(wb, ws, "Cohorts");
    XLSX.writeFile(wb, fileName + ".xlsx");
  }

  exportRespondentListAsExcelSpreadsheet(fileName) {

    const respondentRosterArray = this.decisionsBucket[1];

    var sheetName = "Respondents";
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(respondentRosterArray);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    if (Object.keys(wb.Sheets).length > 0) {
      XLSX.writeFile(wb, fileName + ".xlsx")
    }
  }

  exportAdvancedStatsReportAsExcelSpreadsheet(swydget, fileName) {

    const respondentRosterArray = this.decisionsBucket[1];
    const decisionsByMidArray = this.decisionsBucket[0];
    

    var segmentArray = [];
    segmentArray.push(["segment", "segment_name"]);

    for (var key in swydget.creator.segmentBucket.segments) {
      segmentArray.push([swydget.creator.segmentBucket.segments[key].ordinal, swydget.creator.segmentBucket.segments[key].segment_name])
    }

    const wb = XLSX.utils.book_new();

    const segmentWs = XLSX.utils.aoa_to_sheet(segmentArray);
    const respondentWs = XLSX.utils.aoa_to_sheet(this.decisionsBucket[1]);
    const stimuliWs = XLSX.utils.aoa_to_sheet(swydget.generateSummaryArray());
    const cohortsWs = XLSX.utils.aoa_to_sheet(swydget.generateCohortSummaryArray());
    const decisionsWs = XLSX.utils.aoa_to_sheet(decisionsByMidArray);

    XLSX.utils.book_append_sheet(wb, segmentWs, "Segments");
    XLSX.utils.book_append_sheet(wb, respondentWs, "Respondents");
    XLSX.utils.book_append_sheet(wb, decisionsWs, "Decisions");
    XLSX.utils.book_append_sheet(wb, stimuliWs, "Data Map");
    XLSX.utils.book_append_sheet(wb, cohortsWs, "Cohorts");

    if (Object.keys(wb.Sheets).length > 0) {
      XLSX.writeFile(wb, fileName + ".xlsx")
    }
  }

  exportAdvancedSessionReportAsExcelSpreadsheet(swydget, fileName) {

    const decisionsByMidArray = this.decisiosnBucketIncludeDupes[0];
    const respondentRosterArray = this.decisiosnBucketIncludeDupes[1];
    // let freeResponseByMidArray = this.decisionsBucket[2];

    var segmentArray = [];
    segmentArray.push(["segment", "segment_name"]);

    for (var key in swydget.creator.segmentBucket.segments) {
      segmentArray.push([swydget.creator.segmentBucket.segments[key].ordinal, swydget.creator.segmentBucket.segments[key].segment_name])
    }

    const wb = XLSX.utils.book_new();

    const segmentWs = XLSX.utils.aoa_to_sheet(segmentArray);
    const respondentWs = XLSX.utils.aoa_to_sheet(respondentRosterArray);
    const stimuliWs = XLSX.utils.aoa_to_sheet(swydget.generateSummaryArray());
    const cohortsWs = XLSX.utils.aoa_to_sheet(swydget.generateCohortSummaryArray());
    const decisionsWs = XLSX.utils.aoa_to_sheet(decisionsByMidArray);

    XLSX.utils.book_append_sheet(wb, segmentWs, "Segments");
    XLSX.utils.book_append_sheet(wb, respondentWs, "Sessions");
    XLSX.utils.book_append_sheet(wb, decisionsWs, "Decisions");
    XLSX.utils.book_append_sheet(wb, stimuliWs, "Data Map");
    XLSX.utils.book_append_sheet(wb, cohortsWs, "Cohorts");


    if (Object.keys(wb.Sheets).length > 0) {
      XLSX.writeFile(wb, fileName + ".xlsx")
    }
  }

  render() {

    let tableContainerStyle = {
      padding: 0,
      margin: 0
    };

    let headerDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#53585f',
      fontSize: 16,
      fontWeight: 'bold',
      paddingTop: 0
    };

    let exportDivStyle = {
      fontSize: 16,
      fontWeight: 300,
      color: '#000000'
    };

    let csvButtonWrapperDiv = {
      width: '100%',
      margin: 0,
      padding: 6,
      border: '1px solid red'
    }

    return (
      <div style={tableContainerStyle}>
        <div style={headerDivStyle}>


          {
            this.state.eventInFocus.surveyType === "forced_rank" ?
            <div>
              <div>
                <CSVLink data={[]} filename={this.title} style={exportDivStyle}>Export Survey Data to CSV</CSVLink>
              </div>
              <div>
                <CSVLink data={[]} filename={"respondents_" + this.title} style={exportDivStyle}>Export Respondent Roster to CSV</CSVLink>
              </div>
              <div>
                <CSVLink data={[]} filename={"products_" + this.title} style={exportDivStyle}>Export Content Catalog to CSV</CSVLink>
              </div>
            </div>
            :
            <div style={{display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%'}}>
              <SwytchbackFrameButton 
                style={{width:'70%', margin: 10, marginTop: 30}}
                disabled={!this.state.eventInFocus.areDetailsLoaded()} 
                onClick={() => this.exportRespondentListAsExcelSpreadsheet(this.title + "_respondent-list")}
              >
                Respondent List
              </SwytchbackFrameButton>
              <SwytchbackFrameButton 
                style={{width:'70%', margin: 10}}
                disabled={!this.state.eventInFocus.areDetailsLoaded()} 
                onClick={() => this.exportAdvancedStatsReportAsExcelSpreadsheet(this.state.eventInFocus, this.title + "_advanced-statistics-report")}
              >
                Swydget Data (Advanced)
              </SwytchbackFrameButton>
              <SwytchbackFrameButton 
                style={{width:'70%', margin: 10}}
                disabled={!this.state.eventInFocus.areDetailsLoaded()}
                onClick={() => this.exportAdvancedSessionReportAsExcelSpreadsheet(this.state.eventInFocus, this.title + "_advanced-sessions-report")}
              >
                Sessions Report Advanced
              </SwytchbackFrameButton>
              <SwytchbackFrameButton 
                style={{width:'70%', margin: 10}}
                disabled={!this.state.eventInFocus.areDetailsLoaded()} 
                onClick={() => this.exportFreeResponseSpreadsheet(this.title + "_free-response-report")}
              >
                Verbatims
              </SwytchbackFrameButton>
              <SwytchbackFrameButton 
                style={{width:'70%', margin: 10}}
                disabled={!this.state.eventInFocus.areDetailsLoaded()} 
                onClick={() => this.exportSummarySpreadsheet(this.state.eventInFocus, this.title + "_summary")}
              >
                Summary
              </SwytchbackFrameButton>
              <SwytchbackFrameButton 
                style={{width:'70%', margin: 10, marginBottom: 30}}
                disabled={!this.state.eventInFocus.areDetailsLoaded()} 
                onClick={() => this.exportCohortsSpreadsheet(this.state.eventInFocus, this.title + "_cohorts")}
              >
                Cohorts
              </SwytchbackFrameButton>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default SwydgetReports;
