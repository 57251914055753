import styled from '@emotion/styled';
import { Button } from '@mui/material';

const SwytchbackButton = styled(Button)(({
  backgroundcolor='transparent', 
  hoverbackgoundcolor="transparent", 
  disabledbackgoundcolor="rgba(0, 0, 0, 0.5)", 
  bordercolor="#c4c4c4", 
  hoverbordercolor="#000000", 
  disabledbordercolor="#c4c4c4",
  textcolor="#000000", 
  hovertextcolor="#000000", 
  disabledtextcolor="#aeaeae",
  height=53
}) => ({
  backgroundColor: backgroundcolor,
  color: textcolor,
  height: height,
  border: '1px solid',
  borderColor: bordercolor,
  borderRadius: 10,
  textTransform: 'none', // Prevent text transformation
  '&:hover': {
    backgroundColor: hoverbackgoundcolor,
    borderColor: hoverbordercolor, // Change background color on hover
    color: hovertextcolor
  },
  '& .MuiTouchRipple-root': {
    color: '#157cf8',
    opacity: 0.5
  },
  "&.Mui-disabled": {
    backgroundColor: disabledbackgoundcolor,
    borderColor: disabledbordercolor,
    color: disabledtextcolor
  },
}));

export default SwytchbackButton;
