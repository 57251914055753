import React from "react";
import { Checkbox, FormControlLabel, FormGroup, MenuItem } from "@mui/material";
import "../../../css/swytchbackModal.css"
import classes from "../../../css/modules/TurfModal.module.css";
import SwytchbackSelect from "../../admin/controls/SwytchbackSelect";
import SwytchbackCheckbox from "../../admin/controls/SwytchbackCheckbox";



const TurfStimuliSelectorModal = ({
  disabled=false,
  block, 
  turfThreshhold, 
  setTurfThreshold,
  combinationsLimit,
  setCombinationsLimit,
  includedStimuliProductIds,
  requiredStimuliProductIds,
  handleIncludedStimuliChange,
  handleRequiredStimuliChange

}) => {

  const generateCombintionLimitOptions = () => {
    
    const menuItems = [];
  
    for (let i = 1; i <= includedStimuliProductIds.length; i++) {
      menuItems.push(<MenuItem key={i} value={i}>{"Top " + i.toString()}</MenuItem>)
    }

    return menuItems;
  }

  const generateIncludedStimuliControl = () => {
    
    return (
      <FormGroup style={{
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
      }}>
        {block.orderedWyshList.getFilteredSortedWyshes("desc", null).map((stimulus) => {

          const isIncluded = includedStimuliProductIds.includes(stimulus.product.productId); 
          
          return (
            <div key={stimulus.product.productId} className={`${classes.rowContainer}`}>
              <FormControlLabel
                style={{ margin: 0, marginRight: 30,padding: 0 }}
                control={
                  <SwytchbackCheckbox
                    disabled={disabled}
                    checked={includedStimuliProductIds.includes(stimulus.product.productId)}
                    onChange={handleIncludedStimuliChange}
                    name={stimulus.product.productId}
                    style={{ color: '#ffffff' }}
                  />
                }
                label={
                  <div className={`${classes.stimulusLabel}`}>
                    <div>
                      {stimulus.product.getUnformattedText()} 
                    </div>
                    <div>
                      {stimulus.getAverageScore().toFixed(1)}
                    </div>  
                  </div>
                }
              />
              {isIncluded &&
              <FormControlLabel
                style={{ margin: 0, padding: 0 }}
                control={
                  <SwytchbackCheckbox
                    disabled={disabled}
                    checked={requiredStimuliProductIds.includes(stimulus.product.productId)}
                    onChange={handleRequiredStimuliChange}
                    name={stimulus.product.productId}
                    style={{ color: '#ffffff' }}
                  />
                }
                label={
                  <div className={`${classes.stimulusLabel}`}>
                    <div>
                      Required
                    </div>
                  </div>
                }
              /> 
              }
            </div>
          )
        }
        )
        }
      </FormGroup>
    )
  }

  return (
    <div className={`${classes.rowSpacedContainer}`}>
      <div className={`${classes.leftControlsContainer}`}>
        <div className="widget fullWidth">
          <div className='titleContainer'>
            <div className='title'>
              TURF Reach Threshold:
            </div>
          </div>
          <div className='content'>
            <div className={`${classes.centeredContainer}`}>
              <SwytchbackSelect
                disabled={disabled}
                style={{ width: 160 }}
                inputProps={{ IconComponent: () => null }}
                id={"turf-threshold-select"}
                value={turfThreshhold}
                onChange={(evt) => { setTurfThreshold(evt.target.value) }}
              >
                {block.getBlockType().getThresholdLabels().map((thresholdLabel, idx) => {
                  return (
                    <MenuItem key={idx} value={idx}>{thresholdLabel}</MenuItem>
                  )
                })
                }
              </SwytchbackSelect>
            </div>
          </div>
        </div>
        <div className="widget fullWidth">
          <div className='titleContainer'>
            <div className='title'>
              Combinations to Present:
            </div>
          </div>
          <div className='content'>
            <div className={`${classes.centeredContainer}`}>
              <SwytchbackSelect
                disabled={disabled}
                style={{ width: 160 }}
                inputProps={{ IconComponent: () => null }}
                id={"turf-combinations-select"}
                value={combinationsLimit}
                onChange={(evt) => { setCombinationsLimit(evt.target.value) }}
              >
                {generateCombintionLimitOptions()}
              </SwytchbackSelect>
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.rightPaneContainer}`}>
        <div className="widget">
          <div className='titleContainer'>
            <div className='title'>
              Included in TURF Analysis:
            </div>
          </div>
          <div className='content'>
            <div className={`${classes.centeredContainer}`}>
              {generateIncludedStimuliControl()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
} 

export default TurfStimuliSelectorModal;