import React from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import classes from "../../../css/modules/TurfResultsWidget.module.css";
import "../../../css/swytchbackModal.css"
import { Line } from "react-chartjs-2";
import SwytchbackFrameButton from "../../admin/controls/SwytchbackFrameButton";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import TurfStimuliSelectorModal from "./TurfStimuliSelectorModal";

const TurfResultsWidget = ({turfResults, swydget, snapshot=null}) => {

  const generateCombinationsToDisplay = (turfResults) => {
    const combinations = [];

    for (const key of turfResults.turfResultsMap.keys()) {
      combinations.push(turfResults.turfResultsMap.get(key))
    }

    return combinations;
  }

  const generateTopPerformers = (turfResults) => {

    const stimuliCountToDisplay = ["1", "2", "3"];
    const combinations = [];

    for (const key of turfResults.turfResultsMap.keys()) {
      if (stimuliCountToDisplay.includes(key)) {
        combinations.push(turfResults.turfResultsMap.get(key))
      }
    }

    return (
      combinations.map((comboArray) => {

        const topBucket = comboArray[0];

        return (
          <div key={topBucket.stim_count} className={`${classes.comboStats}`}>
            <div className={`${classes.stimulusStyle}`}>
              Top {topBucket.stim_count}
            </div>
            {topBucket.combo.map((productId) => {
              return(
                <div key={productId} className={`${classes.stimulusStyle}`}>
                  {topBucket.getStimuli(productId).product.description}
                </div>   
              )})
            }  
            <div>
              Reach: {topBucket.reach}
            </div>
            <div>
              Frequency: {(topBucket.frequency * 100).toFixed(1)}%
            </div>
            <div>
              Taker Count: {topBucket.taker_count}
            </div>
          </div>
      )})
    )
  }

  const generateReachChart = (turfResults) => {

    // 1. Generate the data sets
    const combinations = generateCombinationsToDisplay(turfResults)

    const mode = "light";
    const units = "%";
    const dataArray = [];
    const dataLabelsArray=[];
    const labels = [];
    const borderColor = mode ? (mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
    const textColor = mode ? (mode === "dark" ? "#ffffff" : "#000000") : "#000000";

    for (const c of combinations) {
      dataArray.push((100 * c[0].reach / turfResults.takerCount).toFixed(1));
      dataLabelsArray.push(c[0].getStimuliNames());
      labels.push("Top " + c[0].stim_count);
    }
    
    const datasets = [{
      label: snapshot ? snapshot.name : "All Data",
      backgroundColor: snapshot ? snapshot.color : "#ffffff",
      borderColor: snapshot ? snapshot.color : "#ffffff",
      borderWidth: 1,
      data: dataArray,
      dataLabels: dataLabelsArray,
      snapshot: snapshot
    }];

    const data = {
      labels: labels,
      datasets: datasets,
    };

    const lineOptions = {
      indexAxis: 'x',
      elements: {
        bar: {},
      },
      responsive: true,
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: textColor
          },
          grid: {
            color: borderColor
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: borderColor
          },
          ticks: {
            color: textColor,
            callback: function (value) {
              return value + units;
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: textColor
          }
        },
        tooltip: {
          padding: 14,
          displayColors: false,
          titleFont: {
            size: 20
          },
          callbacks: {
            title: (context) => {
              let label = context.length > 0 ? context[0].label + ": " + context[0].raw + units : '';
              return label;
            },
            label: function (context) {
              const subContent = [];
              // subContent.push("Reach: " + context.raw + units);
              subContent.push(...context.dataset.dataLabels[context.dataIndex]);
              return subContent;
            },
            bodyFont: {
              size: 16
            }
          }
        },
        title: {
          display: true,
          text: "Reach",
          font: {
            size: 24,
          },
          position: 'top', // 'top', 'left', 'bottom', 'right'
          color: textColor,   // Custom color for the title
          padding: {
            top: 10,
            bottom: 30
          },
        },
      },
    };

    return (
      <div className={`${classes.chartContainer}`}>
        <Line options={lineOptions} data={data} />
      </div>
    )
  }

  const generateFrequencyChart = (turfResults) => {
    // 1. Generate the data sets
    const combinations = generateCombinationsToDisplay(turfResults)

    const mode = "light";
    const units = "";
    const dataArray = [];
    const dataLabelsArray=[];
    const labels = [];
    const borderColor = mode ? (mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
    const textColor = mode ? (mode === "dark" ? "#ffffff" : "#000000") : "#000000";

    for (const c of combinations) {
      dataArray.push(c[0].frequency.toFixed(2));
      dataLabelsArray.push(c[0].getStimuliNames());
      labels.push("Top " + c[0].stim_count);
    }
    
    const datasets = [{
      label: snapshot ? snapshot.name : "All Data",
      backgroundColor: snapshot ? snapshot.color : "#ffffff",
      borderColor: snapshot ? snapshot.color : "#ffffff",
      borderWidth: 1,
      data: dataArray,
      dataLabels: dataLabelsArray,
      snapshot: snapshot
    }];

    const data = {
      labels: labels,
      datasets: datasets,
    };

    const lineOptions = {
      indexAxis: 'x',
      elements: {
        bar: {},
      },
      responsive: true,
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: textColor
          },
          grid: {
            color: borderColor
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: borderColor
          },
          ticks: {
            color: textColor,
            callback: function (value) {
              return value + units;
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: textColor
          }
        },
        tooltip: {
          padding: 14,
          displayColors: false,
          titleFont: {
            size: 20
          },
          callbacks: {
            title: (context) => {
              let label = context.length > 0 ? context[0].label + ": " + context[0].raw + units : '';
              return label;
            },
            label: function (context) {
              const subContent = [];
              // subContent.push("Frequency: " + context.raw + units);
              subContent.push(...context.dataset.dataLabels[context.dataIndex]);
              return subContent;
            }
          },
          bodyFont: {
            size: 16
          }
        },
        title: {
          display: true,
          text: "Frequency",
          font: {
            size: 24,
          },
          position: 'top', // 'top', 'left', 'bottom', 'right'
          color: textColor,   // Custom color for the title
          padding: {
            top: 10,
            bottom: 30
          },
        },
      },
    };

    return (
      <div className={`${classes.chartContainer}`}>
        <Line options={lineOptions} data={data} />
      </div>
    )
  }

  const generateStimuliList = (label, stimuliArray) => {

    return(
      <div className="widget fullWidth">
        <div className='titleContainer'>
          <div className='title'>
            {label}
          </div>
        </div>
        <div className='content'>
          {stimuliArray.map((stimulus) => {
            return (
              <div key={stimulus.product.productId}>
                {stimulus.product.getUnformattedText()}
              </div>
            )
          })}
        </div>
      </div>  
    )

  }

  const generateOpportunityIndexChart = (turfResults) => {

    // 1. Generate the data sets
    const combinations = generateCombinationsToDisplay(turfResults)

    const mode = "light";
    const units = "";
    const dataArray = [];
    const dataLabelsArray=[];
    const labels = [];
    const borderColor = mode ? (mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
    const textColor = mode ? (mode === "dark" ? "#ffffff" : "#000000") : "#000000";

    for (const c of combinations) {
      dataArray.push((c[0].frequency * c[0].reach / turfResults.takerCount).toFixed(2));
      dataLabelsArray.push(c[0].getStimuliNames());
      labels.push("Top " + c[0].stim_count);
    }
    
    const datasets = [{
      label: snapshot ? snapshot.name : "All Data",
      backgroundColor: snapshot ? snapshot.color : "#ffffff",
      borderColor: snapshot ? snapshot.color : "#ffffff",
      borderWidth: 1,
      data: dataArray,
      dataLabels: dataLabelsArray,
      snapshot: snapshot
    }];

    const data = {
      labels: labels,
      datasets: datasets,
    };

    const lineOptions = {
      indexAxis: 'x',
      elements: {
        bar: {},
      },
      responsive: true,
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: textColor
          },
          grid: {
            color: borderColor
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: borderColor
          },
          ticks: {
            color: textColor,
            callback: function (value) {
              return value + units;
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: textColor
          }
        },
        tooltip: {
          padding: 14,
          displayColors: false,
          titleFont: {
            size: 20
          },
          callbacks: {
            title: (context) => {
              let label = context.length > 0 ? context[0].label + ": " + context[0].raw + units : '';
              return label;
            },
            label: function (context) {
              const subContent = [];
              // subContent.push("Opportunity Index: " + context.raw + units);
              subContent.push(...context.dataset.dataLabels[context.dataIndex]);
              return subContent;
            }
          },
          bodyFont: {
            size: 16
          }
        },
        title: {
          display: true,
          text: "Opportunity Index",
          font: {
            size: 24,
          },
          position: 'top', // 'top', 'left', 'bottom', 'right'
          color: textColor,   // Custom color for the title
          padding: {
            top: 10,
            bottom: 30
          },
        },
      },
    };

    return (
      <div className={`${classes.chartContainer}`}>
        <Line options={lineOptions} data={data} />
      </div>
    )
  }
  
  const exportTurfReport = (turfResults) => {

    const fileName = turfResults.block ? turfResults.block.product.name + "_turf_report.xlsx" : "turf_report.xlsx"; 
    const wb = XLSX.utils.book_new();
    const summaryWs = XLSX.utils.aoa_to_sheet(turfResults.toSummaryResultsArray());
    const fullResultsWs = XLSX.utils.aoa_to_sheet(turfResults.toFullResultsArray())

    XLSX.utils.book_append_sheet(wb, summaryWs, "Summary");
    XLSX.utils.book_append_sheet(wb, fullResultsWs, "All Data");

    if (Object.keys(wb.Sheets).length > 0) {
      XLSX.writeFile(wb, fileName);
    }
  }

  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.header}`}>
        <div className={`${classes.metaStuff}`}>
          <div>
            Data Cut: {snapshot.name}
          </div>
          <div>
            {turfResults.getCreatedStr()}
          </div>
          <div>
            Required Stimuli: {turfResults.getRequiredStimuliNames()}
          </div>
          <div>
            Threshold: {turfResults.block.getBlockType().getThresholdLabels()[turfResults.threshold]}
          </div>
        </div>
        <div>
          <SwytchbackFrameButton
            style={{width: 150, margin: 20}} 
            onClick={() => exportTurfReport(turfResults)}
          >
            Export TURF
          </SwytchbackFrameButton>
        </div>
      </div>
      <div className={`${classes.contentContainer}`}>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={classes.heading}>Reach</div>
          </AccordionSummary>
          <AccordionDetails>
            {generateReachChart(turfResults)}
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className={classes.heading}>Opportunity Index</div>
          </AccordionSummary>
          <AccordionDetails>
            {generateOpportunityIndexChart(turfResults)}
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={`${classes.rowContainer}`}>
        <TurfStimuliSelectorModal
          disabled={true}
          block={turfResults.block}
          turfThreshhold={turfResults.threshold} 
          setTurfThreshold={() => console.log("Move this out")}
          combinationsLimit={turfResults.combinationsLimit}
          setCombinationsLimit={() => console.log("Move this out")}
          includedStimuliProductIds={turfResults.includedStimuli}
          requiredStimuliProductIds={turfResults.requiredStimuli}
          handleIncludedStimuliChange={() => console.log("Move this out")}
          handleRequiredStimuliChange={() => console.log("Move this out")}
        />
      </div>
    </div>
    
  )
}

export default TurfResultsWidget;
