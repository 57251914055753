import React, { useState, useEffect } from "react";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import classes from "../../../css/modules/TurfModal.module.css";
import "../../../css/swytchbackModal.css"
import SwytchbackButton from '../../admin/controls/SwytchbackButton.js';
import TurfResultsWidget from "./TurfResultsWidget.js";
import useHttpPost from "../../utilities/AxiosHttpPost.js";
import useHttpDelete from "../../utilities/AxiosHttpDelete.js";
import MeService from "../../../services/MeService.js";
import BlockTypes from "../../../classes/BlockTypes.js";


const TurfResultsModal = ({user, block, snapshot, selectedTurfResults, showTurfModal, closeTurfModal}) => {
  
  const { postData, postLoading, postError, post } = useHttpPost(user);
  const { httpDelete, results, loading, error  } = useHttpDelete(user);
  const [includedStimuli, setIncludedStimuli] = useState([]);
  const [combinationsLimit, setCombinationsLimit] = useState(0);
  
  const limit = 10;
      
  useEffect(() => {
    if (block.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
      const stimuli = block.orderedWyshList.getFilteredSortedWyshes("desc", null);
      const productIds = []
      stimuli.map((stimulus) => {
        if (productIds.length < limit){
          productIds.push(stimulus.product.productId);
        }
      })

      setIncludedStimuli(productIds);
      setCombinationsLimit(productIds.length);
    }
    
  }, [])

  const generateTurfResults = () => {

    if (selectedTurfResults) {
      return (
        <div className="columnCenteredContainer">
          <TurfResultsWidget key={selectedTurfResults.created} turfResults={selectedTurfResults} swydget={block.event} snapshot={snapshot} />
        </div>
      )
    }
    else {
      return (
        <div className="columnCenteredContainer">
          No TURF Results Selected
        </div>
      )
    }
  }

  const deleteTurfResults = () => {

    const endpoint = MeService.serverUrl + ":" + MeService.wm_new_port + "/1/events/event/" + block.event.eventId + "/turf/" + block.wyshId + "/" + snapshot.created + "/" + selectedTurfResults.created;
    const encodedEndpoint = endpoint.replace(/#/g, "%23");
  
    httpDelete(encodedEndpoint, updateBlockCallback);
  }

  const updateBlockCallback = () => {

    MeService.getEvent(user.userId, user.password, block.event.eventId);
    closeTurfModal();
    
  }

  return (
    <Modal
      onHide={closeTurfModal}
      size="xl"
      show={showTurfModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Turf Results: {snapshot ? snapshot.name : ""}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody swytchbackGradientPane">
        <div className="fullPaneContainer">
          <div className="contentContainer">
            <div className='rowContainerSpaced fullWidthPane tallModalHeight'>
              {generateTurfResults()}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <SwytchbackButton 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={deleteTurfResults}
        >
          Delete
        </SwytchbackButton>
        <SwytchbackButton 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={closeTurfModal}
        >
          Close
        </SwytchbackButton>
      </Modal.Footer>
    </Modal>
    );
};
export default TurfResultsModal;