export default class BinaryIconSet {

  static THUMBS_UP_DOWN = new BinaryIconSet("thumbs_up_down", "Thumbs Up/Down", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/thumbs_up_down/thumbs-up-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/thumbs_up_down/thumbs-down-button.png");
  static THUMBS_UP_DOWN_GRAYSCALE = new BinaryIconSet("thumbs_up_down_gray", "Thumbs Up/Down Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/thumbs_up_down_gray/thumbs-up-button-gray.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/thumbs_up_down_gray/thumbs-down-button-gray.png");
  static CHECK_X = new BinaryIconSet("check_x", "Check/X", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/check_x/yes-check-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/check_x/no-check-button.png");
  static CHECK_X_GRAYSCALE = new BinaryIconSet("check_x_gray", "Check/X Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/check_x_gray/yes-check-button-gray.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/check_x_gray/no-check-button-gray.png");
  static YES_NO = new BinaryIconSet("yes_no", "Yes/No", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/yes_no/yes-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/yes_no/no-button.png");
  static YES_NO_GRAYSCALE = new BinaryIconSet("yes_no_gray", "Yes/No Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/yes_no_gray/yes-button-gray.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/yes_no_gray/no-button-gray.png");
  static SPANISH = new BinaryIconSet("si_no", "Spanish", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/si_no/si-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/si_no/no-spanish-button.png");
  static SPANISH_TRANSPARENT = new BinaryIconSet("si_no_trans", "Spanish Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/si_no_trans/si-button-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/si_no_trans/no-spanish-button-50.png");
  static PORTUGUESE = new BinaryIconSet("portuguese", "Portuguese", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/portuguese/yes-portuguese-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/portuguese/no-portuguese-button.png");
  static PORTUGUESE_TRANSPARENT = new BinaryIconSet("portuguese_trans", "Portuguese Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/portuguese_trans/yes-portuguese-button-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/portuguese_trans/no-portuguese-button-50.png");
  static DUTCH = new BinaryIconSet("dutch", "Dutch", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/dutch/yes-dutch-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/dutch/no-dutch-button.png");
  static GERMAN = new BinaryIconSet("german", "German", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/german/yes-german-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/german/no-german-button.png");
  static GERMAN_TRANSPARENT = new BinaryIconSet("german_trans", "German Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/german_trans/yes-german-button-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/german_trans/no-german-button-50.png");
  static FRENCH = new BinaryIconSet("french", "French", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/french/yes-french-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/french/no-french-button.png");
  static ITALIAN = new BinaryIconSet("italian", "Italian", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/italian/yes-italian-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/italian/no-italian-button.png");
  static JAPANESE = new BinaryIconSet("japanese", "Japanese", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/japanese/yes-japanese-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/japanese/no-japanese-button.png");
  static HAPPY_SAD_FACE = new BinaryIconSet("happy_sad", "Happy/Sad", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/happy_sad/happy-face-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/happy_sad/sad-face-button.png");
  static HAPPY_SAD_FACE_GRAYSCALE = new BinaryIconSet("happy_sad_gray", "Happy/Sad Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/happy_sad_gray/happy-face-button-gray.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/happy_sad_gray/sad-face-button-gray.png");
  static LEFT_RIGHT_ARROWS = new BinaryIconSet("left_right", "Left/Right", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/left_right/right-arrow-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/left_right/left-arrow-button.png");
  static LEFT_RIGHT_ARROWS_GRAYSCALE = new BinaryIconSet("left_right_gray", "Left/Right Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/left_right_gray/right-arrow-button-gray.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/left_right_gray/left-arrow-button-gray.png");
  static TOP_BOTTOM = new BinaryIconSet("top_bottom", "Top/Bottom", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/top_bottom/bottom-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/top_bottom/top-button.png");
  static TOP_BOTTOM_GRAYSCALE = new BinaryIconSet("top_bottom_gray", "Top/Bottom Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/top_bottom_gray/bottom-button-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/top_bottom_gray/top-button-50.png");
  static TRUE_FALSE = new BinaryIconSet("true_false", "True/False", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/true_false/true-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/true_false/false-button.png");
  static TRUE_FALSE_GRAY = new BinaryIconSet("true_false_gray", "True/False Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/true_false_gray/true-button-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/true_false_gray/false-button-50.png");
  static T_F = new BinaryIconSet("t_f", "T/F", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/t_f/t-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/t_f/f-button.png");
  static T_F_GRAY = new BinaryIconSet("t_f_gray", "T/F Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/t_f_gray/t-button-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/t_f_gray/f-button-50.png");
  static MALE_FEMALE_TEXT = new BinaryIconSet("male_female_text", "Male/Female", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_text/male-text.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_text/female-text.png");
  static MALE_FEMALE_TEXT_GRAYSCALE = new BinaryIconSet("male_female_text_gray", "Male/Female Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_text_gray/male-text-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_text_gray/female-text-50.png");
  static M_F = new BinaryIconSet("male_female_letter", "M/F", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_letter/male-letter.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_letter/female-letter.png");
  static M_F_GRAYSCALE = new BinaryIconSet("male_female_letter_gray", "M/F Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_letter_gray/male-letter-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_letter_gray/female-letter-50.png");
  static MALE_FEMALE_SILHOUETTE = new BinaryIconSet("male_female_silhouette", "Male/Female Silhouette", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_silhouette/male-silhouette.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_silhouette/female-silhouette.png");
  static MALE_FEMALE_SILHOUETTE_GRAYSCALE = new BinaryIconSet("male_female_silhouette_gray", "Male/Female Silhouette Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_silhouette_gray/male-silhouette-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_silhouette_gray/female-silhouette-50.png");
  static MALE_FEMALE_GREEK = new BinaryIconSet("male_female_greek", "Male/Female Symbol", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_greek/male-greek.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_greek/female-greek.png");
  static MALE_FEMALE_GREEK_GRAYSCALE = new BinaryIconSet("male_female_greek_gray", "Male/Female Symbol Grayscale", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_greek_gray/male-greek-50.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/male_female_greek_gray/female-greek-50.png");
  static DEUTSCH_ENGLISH = new BinaryIconSet("deutsch_english", "English/Deutsch", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/deutsch_english/English-button.png", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/deutsch_english/German-button.png");
  static NEXT = new BinaryIconSet("next", "Next", "https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/next/next-button-thick.png", null);
  
  static compareByNameDesc(a, b) {
    return b.name.localeCompare(a.name);
  }

  static compareByNameAsc(a, b) {
    return a.name.localeCompare(b.name);
  }

  static getBinaryIconSet(binaryIconSetID) {
    for (const s of BinaryIconSet.getOrderedList()) {
      if (binaryIconSetID && binaryIconSetID === s.binaryIconSetID) {
        return s
      }
    }

    return BinaryIconSet.THUMBS_UP_DOWN;
  }

  static getOrderedList() {
    let iconSets = [
      BinaryIconSet.THUMBS_UP_DOWN,
      BinaryIconSet.THUMBS_UP_DOWN_GRAYSCALE,
      BinaryIconSet.CHECK_X,
      BinaryIconSet.CHECK_X_GRAYSCALE,
      BinaryIconSet.YES_NO,
      BinaryIconSet.YES_NO_GRAYSCALE,
      BinaryIconSet.SPANISH,
      BinaryIconSet.SPANISH_TRANSPARENT,
      BinaryIconSet.PORTUGUESE,
      BinaryIconSet.PORTUGUESE_TRANSPARENT,
      BinaryIconSet.DUTCH,
      BinaryIconSet.GERMAN,
      BinaryIconSet.GERMAN_TRANSPARENT,
      BinaryIconSet.FRENCH,
      BinaryIconSet.ITALIAN,
      BinaryIconSet.JAPANESE,
      BinaryIconSet.HAPPY_SAD_FACE,
      BinaryIconSet.HAPPY_SAD_FACE_GRAYSCALE,
      BinaryIconSet.LEFT_RIGHT_ARROWS,
      BinaryIconSet.LEFT_RIGHT_ARROWS_GRAYSCALE,
      BinaryIconSet.TOP_BOTTOM,
      BinaryIconSet.TOP_BOTTOM_GRAYSCALE,
      BinaryIconSet.TRUE_FALSE,
      BinaryIconSet.TRUE_FALSE_GRAY,
      BinaryIconSet.T_F,
      BinaryIconSet.T_F_GRAY,
      BinaryIconSet.MALE_FEMALE_TEXT,
      BinaryIconSet.MALE_FEMALE_TEXT_GRAYSCALE,
      BinaryIconSet.M_F,
      BinaryIconSet.M_F_GRAYSCALE,
      BinaryIconSet.MALE_FEMALE_SILHOUETTE,
      BinaryIconSet.MALE_FEMALE_SILHOUETTE_GRAYSCALE,
      BinaryIconSet.MALE_FEMALE_GREEK,
      BinaryIconSet.MALE_FEMALE_GREEK_GRAYSCALE,
      BinaryIconSet.DEUTSCH_ENGLISH,
      BinaryIconSet.NEXT
    ];

    return iconSets.sort(BinaryIconSet.compareByNameAsc);
  }

  constructor(binaryIconSetID, name, yesUrl, noUrl) {
    this.binaryIconSetID = binaryIconSetID;
    this.name = name;
    this.yesUrl = yesUrl;
    this.noUrl = noUrl;
  }
}