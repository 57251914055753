import * as MeActions from "../actions/MeActions";
import WyshMeProduct from "../classes/WyshMeProduct";
import Wysh from "../classes/Wysh";
import BlockTypes from "../classes/BlockTypes";
import AdminConsoleActionsHook from "../hooks/actions/AdminConsoleActionsHook";
import SwydgetActionsHook from "../hooks/actions/SwydgetActionsHook";


class MeService {

  constructor() {

    this.serverUrl = "http://localhost";
    this.cloneServerUrl = "http://localhost";
    this.wm_new_port = 5002;
    this.wm_old_port = 5000;

    var appStage = process.env.REACT_APP_BACKEND;
    var backendUrl = process.env.REACT_APP_BACKEND_URL;
    var cloneBackendUrl = process.env.REACT_APP_CLONE_BACKEND_URL;

    if (backendUrl) {
      this.serverUrl = backendUrl;
      this.cloneServerUrl = cloneBackendUrl;
      if (backendUrl.indexOf("127.0.0.1") > -1) {
        this.wm_new_port = 7002;
        this.wm_old_port = 7000;
      }
      else {
        this.wm_new_port = 80;
        this.wm_old_port = 7000;
      }
    }
    else {
      if (appStage) {
        switch (appStage) {
          case "backend-dev":
            this.serverUrl = "https://backend-dev.swytchback.com";
            this.cloneServerUrl = "https://bridge-dev.swytchback.com";
            this.wm_new_port = 80;
            this.wm_old_port = 7000;
            break;
          case "backend-prod":
            this.serverUrl = "https://backend-prod.swytchback.com";
            this.cloneServerUrl = "https://bridge-prod.swytchback.com";
            this.wm_new_port = 80;
            this.wm_old_port = 7000;
            break;
          default:
            this.serverUrl = "http://localhost";
            this.cloneServerUrl = "http://localhost";
            this.wm_new_port = 5002;
            this.wm_old_port = 5000;
        }
      }
    }
  }

  addCamelCaseFieldsToUser(userJson) {
    userJson["isFriend"] = userJson["is_friend"];
    userJson["displayName"] = userJson["display_name"];
    userJson["firstName"] = userJson["first_name"];
    userJson["lastName"] = userJson["last_name"];
    userJson["ownerMid"] = userJson["owner-mid"];
  }

  /*
  createSurvey

  Creates a new survey from the user's question.

  WM-OLD API: PUT event_list/create
  */
  createSurvey(user, navigationHandler, surveyName, defaultPrompt, binaryIconSetID) {

    const userId = user.userId;

    var userJson = user.addressBookJson.contacts[0];
    userJson.keys.email=[{
      "t": "email",
      "v": userId
    }];

    this.addCamelCaseFieldsToUser(userJson);

    var createSurveyJson = {
      "address_book": user.addressBookJson,
      "user-id": userJson,
      "event_info": {
        name : "",
        note : "",
        invited : [

        ],
        when : "",
        where : ""
      }
    };

    MeActions.createSurvey(
      user.userId,
      user.password,
      this.serverUrl + ":" + this.wm_new_port,
      this.serverUrl + ":" + this.wm_old_port,
      navigationHandler,
      surveyName,
      defaultPrompt,
      createSurveyJson,
      binaryIconSetID
    );
  }

  /*
  createForcedRankSurvey

  Creates a new survey from the user's question.

  WM-OLD API: PUT event_list/create
  */
  createForcedRankSurvey(user, surveyName, defaultPrompt) {
    var userJson = user.addressBookJson.contacts[0];

    this.addCamelCaseFieldsToUser(userJson);

    var createSurveyJson = {
      "address_book": user.addressBookJson,
      "user-id": userJson,
      "event_info": {
        name : "",
        note : "",
        invited : [

        ],
        when : "",
        where : ""
      }
    };

    MeActions.createForcedRankSurvey(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, this.serverUrl + ":" + this.wm_old_port, surveyName, defaultPrompt, createSurveyJson);
  }

  /*
  createScalarSurvey

  Creates a new scalar survey from the user's question and options

  WM-OLD API: PUT event_list/create
  */
  createScalarSurvey(user, surveyName, defaultPrompt, scalarDescription, options) {
    var userJson = user.addressBookJson.contacts[0];

    this.addCamelCaseFieldsToUser(userJson);

    var createSurveyJson = {
      "address_book": user.addressBookJson,
      "user-id": userJson,
      "event_info": {
        name : "",
        note : "",
        invited : [

        ],
        when : "",
        where : ""
      }
    };

    MeActions.createScalarSurvey(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, this.serverUrl + ":" + this.wm_old_port, surveyName, defaultPrompt, createSurveyJson, scalarDescription, options);
  }

  /*
  cloneSwydget(user, swydget)

  Creates a duplicate Swydget of the argument Swydget and loads it with the products of the
  argument Swydget. It also duplicates all of the creative content of the argument Swydget
  (wallpaper, cover photo, social networks, etc.)
  */
  cloneSwydget(user, swydget) {
    MeActions.cloneSwydget(user, this.cloneServerUrl + ":" + this.wm_new_port, this.serverUrl + ":" + this.wm_old_port, swydget);
  }

  /*
  updateEvent

  Updates an event's details.

  WM-OLD API: PUT event/update
  */
  updateEvent(user, event, title, rewardText, rewardUrl, contestPrizeText) {
    MeActions.updateEvent(user, this.serverUrl + ":" + this.wm_new_port, event, title, rewardText, rewardUrl, contestPrizeText);
  }

  /**
  * deleteSwydget
  *
  * Delete the event as far as the user nows. It does not actually delete the Swydget/Event.
  * It removes all references of the Swydget/Event from the Creator's profile. It essentially
  * places the Swydget/Event in limbo
  *
  * @param user
  * @param event
  */
  deleteSwydget(user, navigationHandler, event) {
    const { deleteSwydget } = AdminConsoleActionsHook();
    deleteSwydget(user, this.serverUrl + ":" + this.wm_new_port, navigationHandler, event);
  }


  /*
  getEvents

  Get the Events/Surveys created by the user

  GET 1/events/
  */
  getEvents(user, password, workspace="Drafts", sortOrder="edited-recent", firstIndex=null, lastIndex=null, filterTerm=null, ownerFilter="all") {
    MeActions.getEvents(user, password, this.serverUrl + ":" + this.wm_new_port, workspace, sortOrder, firstIndex, lastIndex, filterTerm, ownerFilter);
  }
 
  /*
  getProducts

  Get the WyshMeProducts used in a users Surveys. They might not be created by the
  user, but rather by another user.

  GET 1/events/
  */
  getProducts(user, password, firstIndex=0, lastIndex=9, sortOrder="newest") {
    MeActions.getProducts(user, password, this.serverUrl + ":" + this.wm_new_port, firstIndex, lastIndex, sortOrder);
  }

  /*
  getProductsByMid

  Get the WyshMeProducts created by the argument creator. This is used to get all the
  products of another user.

  TODO: replace this with an API of its own to fetch the recommended products created by a given user.

  GET 1/events/
  */
  getProductsByMid(creatorMid) {
    MeActions.getProductsByMid(this.serverUrl + ":" + this.wm_new_port, creatorMid);
  }

  /*
  getEvent

  Get an Event/Survey WITHOUT the decisions loaded

  GET /1/events/event/evt.00000000000003e8-00000008%23
  */
  getEvent(username, password, eventId) {
    MeActions.getEvent(username, password, this.serverUrl + ":" + this.wm_new_port, eventId);
  }

  /*
  getSurveyTakers

  Retrieves the contact info for the survey takers for a given Survey.

  GET /1/events/event/evt.00000000000003ec-00000021%23/takers/
  */
  getSurveyTakers(user, surveyId) {
    MeActions.getSurveyTakers(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, surveyId);
  }

  /*
  uploadCoverPhoto

  Upload a cover image to a Survey/Event.

  POST /1/events/event/evt.00000000000003e8-00000008%23/cover_photo
  */
  uploadCoverPhoto(user, survey, photoFile) {
    MeActions.uploadCoverPhoto(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, survey, photoFile);
  }

  /*
  uploadWallpaper

  Upload a wallpaper image to a Survey/Event.

  POST /1/events/event/evt.00000000000003e8-00000008%23/wallpaper
  */
  uploadWallpaper(user, survey, photoFile) {
    MeActions.uploadWallpaper(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, survey, photoFile);
  }

  /*
  uploadDefaultCardBackground

  Upload a default card background image to a Survey/Event.

  POST /1/events/event/evt.00000000000003e8-00000008%23/company_logo
  */
  uploadDefaultCardBackground(user, survey, photoFile) {
    MeActions.uploadDefaultCardBackground(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, survey, photoFile);
  }

  uploadStimulus(user, swydget, stimulus, imageFiles=null) {
    MeActions.createProduct(user,
                            this.serverUrl + ":" + this.wm_new_port,
                            swydget,
                            user.myMid,
                            stimulus.product.name,
                            stimulus.product.price,
                            stimulus.product.description,
                            stimulus.product.buyPage,
                            imageFiles.length > 0 ? imageFiles[0] : null,
                            stimulus.product.attributes,
                            stimulus.product.styles,
                            stimulus.questionType,
                            stimulus.getScalarOptionsAsNames(),
                            stimulus.caption, // Prompt,
                            stimulus.freeResponseQuestions,
                            stimulus.binaryIconSetID,
                            false,
                            stimulus.parentWysh,
                            stimulus.randomizeWyshOptions,
                            stimulus.mcIncludeNoneOfTheAbove,
                            stimulus.mcIncludeOther,
                            stimulus.mcOptionsLimit,
                            stimulus.mustViewAll,
                            stimulus.product.imageUrl,
                            stimulus.hiddenFreeResponseOptions);

  }

  /*
  createProduct

  Create a product. Another API method adds it to a Survey.

  POST /1/product
  */
  createProduct(user,
                survey,
                prdName,
                prdPrice,
                prdDescription,
                prdLink,
                photoFile,
                prdAttributes,
                prdStyles,
                questionType,
                scalarOptions,
                question,
                freeResponseQuestions,
                binaryIconSetID,
                parentWysh=null,
                randomizeWyshOptions=false,
                mcIncludeNoneOfTheAbove=false,
                mcIncludeOther=false,
                mcOptionsLimit=0,
                mustViewAll=false) {

    MeActions.createProduct(user,
                            this.serverUrl + ":" + this.wm_new_port,
                            survey,
                            user.myMid,
                            prdName,
                            prdPrice,
                            prdDescription,
                            prdLink,
                            photoFile,
                            prdAttributes,
                            prdStyles,
                            questionType,
                            scalarOptions,
                            question,
                            freeResponseQuestions,
                            binaryIconSetID,
                            false,
                            parentWysh,
                            randomizeWyshOptions,
                            mcIncludeNoneOfTheAbove,
                            mcIncludeOther,
                            mcOptionsLimit,
                            mustViewAll);
  }


  /*
  createProducts

  Batch creates an array bakery fresh products. It is added to a Survey via a different method.
  */
  createProducts(
    user,
    survey,
    imageFiles,
    parentWysh=null) {

    MeActions.createProducts(
      user,
      this.cloneServerUrl + ":" + this.wm_new_port,
      survey,
      imageFiles,
      parentWysh);
  }

  /**
  * createBlock
  *
  * creates a block in a swydget.
  *
  * POST /1/product
  *
  * @param user
  * @param swydget
  * @param blockCardText
  * @param blockPrompt
  */
  createBlock(user, swydget, blockCardText, blockPrompt, parentWysh=null) {
    // re-use createProduct and pass in a parameter indicating we are creating a block
    // blockCardText --> wysh's question
    // blockPrompt --> product's description

    // Here we will create a product representing the block and add it to the Swydget without creating a product in the
    // RDBMS Product data store. We will use importContent to import the block

    if (swydget === undefined || swydget === null) {
      return;
    }

    let blockProduct = new WyshMeProduct();
    const newWysh = new Wysh();
    newWysh.block = true;
    newWysh._blockType = BlockTypes.STANDARD;
    newWysh.questionType = "binary";
    newWysh.setSwydget(swydget);
    newWysh.parentWysh = parentWysh ? parentWysh : null;

    if (blockCardText) {
      // Brief text
      blockProduct.description = blockCardText;
      newWysh.product.description = blockCardText;
    }

    blockProduct.productId = parentWysh ? parentWysh.orderedWyshList.generateBlockId() : swydget.orderedWyshList.generateBlockId();
    MeActions.importContent(this.cloneServerUrl + ":" + this.wm_new_port, user, swydget, newWysh.orderedWyshList, newWysh);
  }

  /*
  updateProduct

  Updates a product in the product store.

  POST /1/product/update
  */
  updateProduct(user, productInFocus, prdName, prdPrice, prdDescription, prdLink, photoFile, prdAttributes, prdStyles, removeImage) {
    MeActions.updateProduct(user, this.serverUrl + ":" + this.wm_new_port, productInFocus, user.myMid, prdName, prdPrice, prdDescription, prdLink, photoFile, prdAttributes, prdStyles, removeImage);
  }

  /*
  updateWyshes

  Updates an array of Wyshes

  sends Posts for all the Wyshes:
  POST /1/events/event/evt.00000000000003ec-00000021%23/wyshes/wys.00000000000003ec-00000065%23
  */
  updateWyshes(user, swydget, wyshes, wyshToRemove) {
    MeActions.updateWyshes(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, swydget, wyshes, "ME_UPDATE_WYSHES_SUCCESSFUL", wyshToRemove);
  }


  generateWyshesUpdateRequests(swydget, wyshes) {
    return MeActions.generateWyshesUpdateRequests(this.serverUrl + ":" + this.wm_new_port, swydget, wyshes);
  }


  /*
  updateWysh

  Updates a wysh

  POST /1/events/event/evt.00000000000003ec-00000021%23/wyshes/wys.00000000000003ec-00000065%23
  */
  updateWysh(user, survey, updatedWysh, reloadEvent=true, imageFile=null,) {
    MeActions.updateWysh(user, this.serverUrl + ":" + this.wm_new_port, survey, updatedWysh, reloadEvent, imageFile);
  }

  /*
  updateBlock

  Updates a block and its children

  POST /1/events/event/evt.00000000000003ec-00000021%23/wyshes/wys.00000000000003ec-00000065%23
  */
  updateBlock(user, survey, updatedBlock, updatedBlockAttributesDict) {
    if (updatedBlock.isBlock() === true) {
      MeActions.updateBlock(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, survey, updatedBlock, updatedBlockAttributesDict);
    }
  }

  /*
  addProductToSwydget

  Add the argument product to the argument survey

  POST /1/product
  */
  addProductToSwydget(
    user,
    product,
    survey,
    questionType="binary",
    options=[],
    question=survey.title,
    freeResponseQuestions=[],
    binaryIconSetID="yes_no",
    isBlock=null,
    parentWysh=null) {

    MeActions.addProductToSwydget(
      user.userId,
      user.password,
      this.serverUrl + ":" + this.wm_new_port,
      product,
      survey,
      questionType,
      options,
      question,
      freeResponseQuestions,
      binaryIconSetID,
      isBlock,
      parentWysh);
  }

  /*
  publishSurvey

  publish the argument Survey. Once published, the survey cannot be changed.

  POST /1/events/surveys/decision
  */
  publishSurvey(user, survey, friendsToInvite, nonFriendsToInvite) {
    MeActions.publishSurvey(user, this.serverUrl + ":" + this.wm_new_port, survey, friendsToInvite, nonFriendsToInvite);
  }

  /*
  unPublishSurvey

  Sets the argument Survey to an unpublished state

  DELETE /1/events/surveys/decision
  */
  unPublishSurvey(user, survey) {
    MeActions.unPublishSurvey(user, this.serverUrl + ":" + this.wm_new_port, survey);
  }

  /*
  postProductDecision

  Submits a user decision on a product in a pariticular Survey

  POST /1/events/surveys/published/<EventId>
  */
  postProductDecision(fireAndForget, userKey, firstName, lastName, productId, eventId, decisionRaw, decisionValue, geolocation_info, freeResponseAnswer, userInteractionJson, location) {
    const { postProductDecision } = SwydgetActionsHook();
    postProductDecision(this.serverUrl + ":" + this.wm_new_port, fireAndForget, userKey, firstName, lastName, productId, eventId, decisionRaw, decisionValue, geolocation_info, freeResponseAnswer, userInteractionJson, location);
  }

  /*
  swydgetComplete

  Marks the Swydget as complete for a user.

  POST /1/swydget/_/complete
  */
  swydgetComplete(userKey, swydgetId) {
    MeActions.swydgetComplete(this.serverUrl + ":" + this.wm_new_port, userKey, swydgetId);
  }

  /*
  registerAnonymousSurveyTaker

  Creates a new, anonymous survey taker. This will create a new user profile with a fresh MID.

  POST /1/swydget/register_anonymous_user
  */
  registerAnonymousSurveyTaker(eventId, versionId) {
    MeActions.registerAnonymousSurveyTaker(this.serverUrl + ":" + this.wm_new_port, eventId, versionId);
  }

  /*
  getSessionTimestamp

  Retrieve a timestamp from the back end.

  POST /1/swydget/get_session_timestamp
  */
  getSessionTimestamp(username, password, eventId, userKey=null) {
    MeActions.getSessionTimestamp(username, password, this.serverUrl + ":" + this.wm_new_port, eventId, userKey);
  }

  /*
  postForcedRankDecision

  Submits a user decision on a product in a Forced Rank Survey.

  POST /1/events/surveys/published/<EventId>
  */
  postForcedRankDecision(userKey, firstName, lastName, productId, eventId, decisionRaw, decisionValue, geolocation_info, competitors, roundNumber, trialNumber) {
    MeActions.postForcedRankDecision(this.serverUrl + ":" + this.wm_new_port, userKey, firstName, lastName, productId, eventId, decisionRaw, decisionValue, geolocation_info, competitors, roundNumber, trialNumber);
  }

  /*
  resolveKeyAndAcceptPrivacyPolicy

  Submit a user key to the backend for reconciliation with the backend user store. After that,
  explicitly accept the privacy policy for this swydget.

  POST /1/users/user/resolve_key
  */
  resolveKeyAndAcceptPrivacyPolicy(currentUserKey, newUserKey, eventId) {
    MeActions.resolveKeyAndAcceptPrivacyPolicy(this.serverUrl + ":" + this.wm_new_port, currentUserKey, newUserKey, eventId);
  }

  /*
  inviteSurveyTakers

  Invite the Users in the argument nonFriendsToInvite array to take the survey. It will also initiate a friend request.

  POST /1/events/surveys/published/<EventId>
  */
  inviteSurveyTakers(user, survey, invitee, friendsToInvite, nonFriendsToInvite) {
    MeActions.inviteSurveyTakers(user, this.serverUrl + ":" + this.wm_new_port, survey, invitee, friendsToInvite, nonFriendsToInvite);
  }

  /*
  inviteExistingFriend

  Invite a friend
  POST /event/add-user
  - OLD API
  - serverUrl
  - user,
  - friend
  */
  inviteEventMember(user, friend, event) {
    MeActions.inviteEventMember(this.serverUrl + ":" + this.wm_new_port, this.serverUrl + ":" + this.wm_old_port, user, friend, event);
  }

  /*
  acknowledgeEventInviteRequest

  Two things are happening here:
  1. Accepting/Rejecting the invitation
  2. Ackowledging the notification
  */
  acknowledgeEventInviteRequest(user, eventInviteNotification, accept) {
    MeActions.acknowledgeEventInviteRequest(this.serverUrl + ":" + this.wm_old_port, user, eventInviteNotification, accept);
  }

  /*
  acknowledgeEventInviteRequest

  Two things are happening here:
  1. Accepting/Rejecting the invitation
  2. Ackowledging the notification
  */
  acknowledgeNotification(user, notification, accept) {
    MeActions.acknowledgeNotification(this.serverUrl + ":" + this.wm_old_port, user, notification, accept);
  }

  /*
  removeSwydgetMember

  Removes a user from the list of Swydget admin membebrs.
  POST /event/remove-user
  - user-id
  - contact
  */
  removeSwydgetMember(user, friendToRemove, swydget) {
    MeActions.removeSwydgetMember(this.serverUrl + ":" + this.wm_new_port, user, friendToRemove, swydget);
  }


  /**
  * getDecisions
  *
  * Get Swydget decision counts for each product and each decision option in that product
  *
  * @param swydget
  */
  getDecisions(swydget) {
    MeActions.getDecisions(this.serverUrl + ":" + this.wm_new_port, swydget);
  }

  /**
  * auditDeicions
  *
  * @param swydget
  */
  auditDecisions(swydget, maxDiffResponsesArray) {
    MeActions.auditDecisions(this.serverUrl + ":" + this.wm_new_port, swydget, maxDiffResponsesArray);
  }



  /**
  * getDecisionsWithLocation
  *
  * Get Swydget decision counts for each product and each decision option in that product. Only return decisions
  * where the user has submitted their location info.
  *
  * @param eventId
  */
  getDecisionsWithLocation(eventId) {
    MeActions.getDecisionsWithLocation(this.serverUrl + ":" + this.wm_new_port, eventId);
  }


  /**
  * getDecisionCounts
  *
  * Get Swydget decision counts for each product and each decision option in that product
  *
  * @param swydgetId
  */
  getDecisionCounts(swydgetId) {
    MeActions.getDecisionCounts(this.serverUrl + ":" + this.wm_new_port, swydgetId);
  }

  /**
  * getDecisionCountsFiltered
  *
  * Get Swydget decision counts for each product and each decision option in that product
  *
  * @param user authenticated owner of the swydget
  * @param swydget
  */
  getDecisionCountsFiltered(user, swydget) {
    MeActions.getDecisionCountsFiltered(this.serverUrl + ":" + this.wm_new_port, user, swydget);
  }

  /**
  * getGeolocationData
  *
  * Get geolocation data for the decisions
  *
  * @param user authenticated owner of the swydget
  * @param swydget
  */
  getGeolocationData(user, swydget) {
    MeActions.getGeolocationData(this.serverUrl + ":" + this.wm_new_port, user, swydget);
  }

  /**
  * getFreeResponseAnswers
  *
  * Get free response answers data for the decisions for the argument wysh
  *
  * @param user authenticated owner of the swydget
  * @param swydget
  * @param wysh
  */
  getFreeResponseAnswers(user, swydget, wysh) {
    MeActions.getFreeResponseAnswers(this.serverUrl + ":" + this.wm_new_port, user, swydget, wysh);
  }

  /**
  * getCompletionInfo
  *
  * Get the completion info for the argument swydgets array
  *
  * @param user authenticated owner of the swydget
  * @param swydgets array
  */
  getCompletionInfo(user, swydgets) {
    MeActions.getCompletionInfo(this.serverUrl + ":" + this.wm_new_port, user, swydgets);
  }

  postSegmentationMapping(user, swydget, segmentationMapping) {
    MeActions.postSegmentationMapping(this.serverUrl + ":" + this.wm_new_port, user, swydget, segmentationMapping);
  }

  batchUploadStimuli(user, swydget, stimuliArrays, parentWysh=null) {
    MeActions.batchUploadStimuli(this.serverUrl + ":" + this.wm_new_port, user, swydget, stimuliArrays, parentWysh);
  }

  takeSnapshot(user, swydget, name="Everyone") {
    MeActions.takeSnapshot(this.serverUrl + ":" + this.wm_new_port, user, swydget, name);
  }

  importWysh(user, swydget, newWysh) {
    if (swydget && newWysh) {
      MeActions.importContent(this.cloneServerUrl + ":" + this.wm_new_port, user, swydget, newWysh.orderedWyshList, newWysh);
    }
  }

  importOrderedWyshList(user, swydget, owl) {
    if (swydget) {
      MeActions.importContent(this.cloneServerUrl + ":" + this.wm_new_port, user, swydget, owl);
    }
  }

  submitMaxDiffDecisions(userKey, swydget, maxDiffTrialSetsDecisionsMap, userInteractionJson) {
    if (swydget) {
      MeActions.submitMaxDiffDecisions(this.serverUrl + ":" + this.wm_new_port, userKey, swydget, maxDiffTrialSetsDecisionsMap, userInteractionJson);
    }
  }
}

export default new MeService()
