import styled from '@emotion/styled';
import { Select } from '@mui/material';

const SwytchbackSelect = styled(Select)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: "#aeaeae",
    color: "#aeaeae"
  },
  fontSize: 14,
  color: '#ffffff',
  border: '1px solid #ffffff',
  textAlign: 'center',
  borderRadius: 10
});

export default SwytchbackSelect;