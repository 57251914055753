export default class BlockType {

  constructor(blockTypeId, name) {
    this.blockTypeId = blockTypeId;
    this.name = name;
    this.topAvgScore = 1000;
    this.bottomAvgScore = -1000;
    this.isQuantitativeAndQualitativeReadOnly = false;
    this.lowScore = 33;
    this.highScore = 61;
  }

  toString() {
    return this.blockTypeId;
  }

  appendBlock(block, stimuliArray) {
    const myArray = [
      "Q" + block.questionNumber,
      "",
      block.getWyshPrompt(),
      block.getQuestionType(),
      "",
      ""
    ];

    stimuliArray.push(myArray);
    stimuliArray.push([]); // Insert extra row after appending myself.

    const fow = block.getFilteredOrderedWyshes();
    for (const stimulus of fow) {
      stimulus.appendMyself(stimuliArray);
    }
  }

  getBlockTypeLabel(block) {
    return block.getBlockType().name + " - " + block.questionType;
  }

  generateResponsesArray(block) {
    return [];
  }

  generateUtilityScoreArray(block) {

    // utilityScoresArray is the array utility scores generated for each stimuli for each respondent
    const utilityScoresArray = [];

    return utilityScoresArray;
  }

  calculateTurfThreshold(block, turfThreshhold) {
    const threshold = block.getHighScore() - (block.getHighScore() - block.getLowScore()) * (turfThreshhold + 1) / block.getBlockType().getThresholdLabels().length;
    return threshold;
  }

  generateTurfReachResults(block, turfThreshhold) {

    const results = {};

    return results;
  }

  recalculateUtilityScores(block, calculationMethod="best-worst-counting") {

    // utilityScoresArray is the array utility scores generated for each stimuli for each respondent
    const utilityScoresArray = [];

    const takerDict = this._generateTakerDict(block);
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();
    const stimuliProductIdArray = [];

    for (const stimulus of stimuliArray) {
      stimuliProductIdArray.push(stimulus.product.productId);
    }

    this.calculateIndividualUtilityScoresForAllTakers(stimuliArray, takerDict, calculationMethod);
  }

  calculateIndividualUtilityScoresForAllTakers(stimuliArray, takerDict, calculationMethod) {

  }

  // Called in Swydget toolbars
  generateIndividualUtilityScores(stimuliArray, trialSetsDecisionsMap) {

  }

  toJsonObject() {
    return {
      block_type: this.blockTypeId
    }
  }

  _getBestIndex(decArray, stimuliArray) {

    let bestDecision = null;

    for (const dec of decArray) {
      if (dec.resultNormalized === 1) {
        bestDecision = dec; 
      }
    }

    if (bestDecision) {
      return stimuliArray.indexOf(bestDecision.productId) + 1;
    }
  }

  _getWorstIndex(decArray, stimuliArray) {
    let bestDecision = null;

    for (const dec of decArray) {
      if (dec.resultNormalized === -1) {
        bestDecision = dec; 
      }
    }

    if (bestDecision) {
      return stimuliArray.indexOf(bestDecision.productId) + 1;
    }
  }

  getThresholdLabels() {
    return [
      "Top 10%",
      "Top 20%",
      "Top 30%",
      "Top 40%",
      "Top 50%",
      "Top 60%",
      "Top 70%",
      "Top 80%",
      "Top 90%",
      "All"
    ]
  }

  _generateTakerDict(block) {

    const takerDict = block.event.getTakersWithDecisions();
    const blockTakerDict = {};
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();
    const stimuliProductIdArray = [];
    let lowScore = 10000;
    let highScore = -10000;

    
    
    for (const stimulus of stimuliArray) {
      stimuliProductIdArray.push(stimulus.product.productId);
    }

    for (const mid in takerDict) {

      const takerDecArray = takerDict[mid];
      let versionId = -1;

      const takerMDDecArray = takerDecArray.filter(decision => {
        return stimuliProductIdArray.includes(decision.productId);
      });

      for (const dec of takerMDDecArray) {
        if (dec.versionId >= 0) {
          if (dec.score < lowScore) {
            lowScore = dec.score;
          }
          if (dec.score > highScore) {
            highScore = dec.score;
          }

          versionId = dec.versionId
          break; // Every decision will have the same versionId, so no point in going on
        }
      }

      if (takerMDDecArray.length > 0) {
        blockTakerDict[mid] = {

          // prune decisions for JUST max diff decisions
          "decisions": takerMDDecArray,
          "version_id": versionId
        }
      }
    }

    block.setBlockScoreRange(lowScore, highScore);

    return blockTakerDict

  }

  setBlockScoreRange(lowScore, highScore) {
    this.lowScore = lowScore;
    this.highScore = highScore;
  }

  _findTrialIndices(stimuli, trialSetsDecisionsMap) {

    const indicies = new Set();

    for (const trialSetIndex of trialSetsDecisionsMap.keys()) {
      const trialSet = trialSetsDecisionsMap.get(trialSetIndex);
      for (const decision of trialSet) {
        if (decision.wysh.equals(stimuli)) {
          indicies.add(trialSetIndex)
        }
      }
    }

    return indicies;
  }

  generateVersions(takerDict) {
    // go through taker by taker and generate their versions
    // Map of trials to their stimuli
    

    for (const takerMid in takerDict) {
      const takerVersionMap = new Map();

      for (const dec of takerDict[takerMid].decisions){
        
        // Load the trial sets with their Stimuli
        let trialSetDecisionArray = takerVersionMap.get(dec.trial);
        
        if (trialSetDecisionArray) {
          trialSetDecisionArray.push(dec);
        }
        else {
          trialSetDecisionArray = [];
          trialSetDecisionArray.push(dec);
          takerVersionMap.set(dec.trial, trialSetDecisionArray);
        }
      }

      // Right here I have the taker's pairwise version map
      // The keys of the map are the index of the trial set de-rotated
      // The keys are in the order they were taken by the user
      // The trial set keys map to a Map of the stimulus to the decision made
      // This should be all the data needed to generate the Importance Scores
      // The Importance Score is stored in the score field of the decision
      takerDict[takerMid].version = takerVersionMap;      
    }
  }

  toJsonString() {
    return JSON.stringify(this.toJsonObject());
  }

  equals(blockType) {
    return blockType && blockType.blockTypeId === this.blockTypeId;
  }
}