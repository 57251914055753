import React, { useState, useEffect } from "react";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import useHttpPost from "../utilities/AxiosHttpPost";
import MeService from "../../services/MeService";
import BlockTypes from "../../classes/BlockTypes";
import "../../css/swytchbackStyles.css";
import SwytchbackFrameButton from "./controls/SwytchbackFrameButton";

const centeredContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}


export default function AdvancedControls({ user, swydget, block, handleClose, fetchDecisions, snapshot = null, closeModal }) {

  const [localBlock, setLocalBlock] = useState(block);
  const { postData, postLoading, postError, post } = useHttpPost(user);
  const [requiredStimuli, setRequiredStimuli] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [turfThreshhold, setTurfThreshold] = useState(0);

  let turfReachResults = {};

  useEffect(() => {

  }, [swydget])

  const scoresUploadedCallback = (data) => {
    close();
    console.log(data);
  }

  const recalculateUtilityScores = () => {
    const bt = block.getBlockType();

    if (bt) {
      if (bt.equals(BlockTypes.MAXDIFF)) {
        _recalculateUtilityScores()
      }
      else if (bt.equals(BlockTypes.PAIRWISE)) {
        _recalculateUtilityScores()
      }
    }

  }

  const _recalculateUtilityScores = () => {

    const bt = block.getBlockType();

    const utilityScoresJsonArray = [];
    bt.recalculateUtilityScores(block);
    const utilityScoresArray = bt.generateUtilityScoreArray(block);

    if (utilityScoresArray.length > 0) {
      for (let i = 1; i < utilityScoresArray.length; i++) {
        const decArray = utilityScoresArray[i];
        const decJson = {};
        decJson["sys_RespNum"] = decArray[0];
        for (let j = 1; j < decArray.length; j++) {
          decJson["STIM_" + j] = decArray[j];
        }
        utilityScoresJsonArray.push(decJson);
      }
    }

    // I need to set the sitmuli data 
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();

    let stimulusIndex = 1;
    const stimuliJsonArray = []
    for (const s of stimuliArray) {
      stimuliJsonArray.push({
        index: stimulusIndex,
        prd_uuid: s.product.productId,
        description: s.product.description
      });

      stimulusIndex += 1;
    }

    const parametersJson = {
      "stimuli": stimuliJsonArray,
      "utility_scores": utilityScoresJsonArray
    }


    const endpoint = MeService.serverUrl + ":" + MeService.wm_new_port + "/1/events/event/" + block.event.eventId + "/wyshes/" + block.wyshId + "/max_diff_scores";
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    console.log(parametersJson);
    post(encodedEndpoint, parametersJson, scoresUploadedCallback);
  }

  
  
  return (
    <div className='columnContainer fullWidth'>
      <div className="widget fullWidth">
        <div className='titleContainer'>
          <div className='title'>
            Export Block Data:
          </div>
        </div>
        <div className='content'>
          <div style={centeredContainerStyle}>
            <SwytchbackFrameButton
              disabled={isWorking}
              style={{ width: '100%' }}
              onClick={() => fetchDecisions()}
            >
              Export
            </SwytchbackFrameButton>
          </div>
        </div>
      </div>
    </div>
  );
}
