import React, { useState, useEffect } from "react";
import { Delete } from "@mui/icons-material";
import { Button, Modal } from 'react-bootstrap';
import { HexColorPicker, HexColorInput} from 'react-colorful';
import classes from "../../css/modules/SnapshotManagementModal.module.css";
import '../../css/swytchbackModal.css';
import '../../css/swytchbackTable.css';
import SwytchbackTextField from "./controls/SwytchbackTextField";
import { Paper, Popover } from "@mui/material";
import { log10 } from "chart.js/helpers";
import MeService from "../../services/MeService";
import useHttpDelete from "../utilities/AxiosHttpDelete.js";



const SnapshotManagementModal = (props) => {

  const { user } = props;
  const { swydget } = props;
  const { showModal } = props;
  const { onHideAction } = props;
  const { deleteAction } = props;
  const { updateSnapshotAction } = props;
  const { saveSnapshotAction } = props;
  const { httpDelete, results, loading, error  } = useHttpDelete(user);
  

  const [editSurveyNameDisabled, setEditSurveyNameDisabled] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snapshotInFocus, setSnapshotInFocus] = useState(null);
  

  useEffect(() => {
    // code to run on component mount
  }, []);


  const cleanUp = () => {
    // anything else we might want to do before shutting down
    setAnchorEl(null)
    setShowPopover(false);

    onHideAction();
  }

  const changeSnapshotName = (evt, key, snapshot) => {
    snapshot.name = evt.target.value
    updateSnapshotAction(key, snapshot);
  }

  const handleColorPickerChangeMethod = (color) => {
    snapshotInFocus.color = color;
    updateSnapshotAction(snapshotInFocus);
  }

  const openColorPalette = (evt, k) => {
    let s = swydget.snapshotContainer.snapshots.get(k);
    setSnapshotInFocus(s);
    console.log(k);
    setAnchorEl(evt.currentTarget);
    setShowPopover(!showPopover);
  }

  const closeColorPalette = () => {
    setAnchorEl(null)
    setShowPopover(false);
  }

  const snapshotDeletedCallback = (results) => {
    console.log( results );
    MeService.getEvent(user.userId, user.password, swydget.eventId);
    
  }

  const deleteWyshSnapshots = (key) => {
    console.log(key);

    const endpoint = MeService.serverUrl + ":" + MeService.wm_new_port + "/1/events/event/" + swydget.eventId + "/snapshot/" + key;
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    httpDelete(encodedEndpoint, snapshotDeletedCallback);

    // console.log(snapshotInFocus);

    
    // const requestObjectsArray = MeService.generateWyshesUpdateRequests(swydget, swydget.wyshes);

    // console.log(requestObjectsArray);

    
    // const { loading, error, results } = useMultiPost(user, requestObjectsArray, snapshotDeletedCallback);
    // multiPost(requestObjectsArray, snapshotDeletedCallback);

    // deleteAction(key);
  }

  

  const generateSnapshotRows = (swydget) => {

    var rows = [];

    const keys = Array.from(swydget.snapshotContainer.snapshots.keys());
    keys.sort((a, b) => {

      const aCreated = Number(a);
      const bCreated = Number(b);

      return bCreated - aCreated;
      
    })

    for (let k of keys) {
      let s = swydget.snapshotContainer.snapshots.get(k);

      let d = s.getCreatedDate();
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      let createdDate = month + "/" + day + "/" + year;
      let createdTime = d.toTimeString().slice(0, 5);

      rows.push(
        <div className={`${classes.responsiveRow}`} key={s.created.toString()}>
          <div className={`${classes.responsiveCell}` + " " + `${classes.small}`}>
            <div className={`${classes.responsiveItem}`}>{createdDate}</div>
            <div className={`${classes.responsiveItem}`}>{createdTime}</div>
          </div>
          <div className={`${classes.responsiveCell}` + " " + `${classes.large}`}>
            <SwytchbackTextField
              label="Name"
              disabled={editSurveyNameDisabled}
              id={"snapshot" + k}
              style={{ width: '100%' }}
              value={s.name}
              onChange={(e) => changeSnapshotName(e, k, s)}
              margin="normal"
              variant="outlined"
            />
          </div>
          <div className={`${classes.responsiveCell}` + " " + `${classes.small}`}>
            <Button className={`${classes.spaced}`} onClick={(evt) => openColorPalette(evt, k)}>
              <div className={`${classes.colorCircle}`} style={{backgroundColor: s.color}}>

              </div>  
            </Button>
            <Button className={`${classes.spaced}`} onClick={() => deleteWyshSnapshots(k)}>
              <Delete className="iconButton" />
            </Button>
          </div>
        </div>
      );
    }

    if (rows.length === 0) {
      rows.push(
        <div className={`${classes.messageContainer}`} key="no-data-cuts">
          No data cuts

        </div>
      )
    }

    return rows;
  }

  return (
    <Modal
      size="xl"
      onHide={onHideAction}
      show={showModal || false}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Data Cut Management</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <Popover
          id={"color-palette-popover"}
          open={showPopover}
          anchorEl={anchorEl}
          onClose={() => {
            setShowPopover(false);
            setAnchorEl(null)
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          slotProps={{
            paper: {
              style: {
                borderRadius: 5, // Adjust the radius to your preference
              },
            },
          }}
        >
          <Paper className={`${classes.paperStyle}`}>
            <div className={`${classes.title}`}>Select Color</div>
            <div className={`${classes.buttonContainerStyle}` + " " + `${classes.colorPalette}`}>
              <HexColorPicker 
                color={snapshotInFocus ? snapshotInFocus.color : "#aeaeae"}
                onChange={(color) => handleColorPickerChangeMethod(color)}
              />    
              <HexColorInput
                color={snapshotInFocus ? snapshotInFocus.color : "#aeaeae"}
                onChange={(color) => handleColorPickerChangeMethod(color)}
              />
              <Button className={"swytchbackActiveButton " + `${classes.buttonStyle}`} style={{marginTop: 20}}onClick={closeColorPalette}>OK</Button>
            </div>
          </Paper>

        </Popover>  
        <div className={`${classes.container}` + " swytchbackGradientPane"} >
          <div className="fullPaneContainer" style={{width: '100%'}}>
            <div className="contentContainer" >
              <div className='columnCentredeContainer fullWidthPane tallModalHeight'>
                {generateSnapshotRows(swydget)}
              </div>
            </div>
          </div>      
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button className={"swytchbackActiveButton " + `${classes.buttonStyle}`} onClick={() => saveSnapshotAction()}>Apply</Button>
        <Button className={"swytchbackActiveButton " + `${classes.buttonStyle}`} onClick={() => cleanUp()}>Close</Button>
      </Modal.Footer>
    </Modal>

  );

}

export default SnapshotManagementModal;
