import React, { useState, useEffect } from "react";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import meStore from "../../stores/MeStore";
import Event from "../../classes/Event";
import MeService from "../../services/MeService";
import OrderedWyshList from "../../classes/OrderedWyshList";
import WyshMeProduct from "../../classes/WyshMeProduct";
import useHttpGet from "../../hooks/utilities/AxiosHttpGet";
import useHttpPut from "../../hooks/utilities/AxiosHttpPut";
import useHttpPost from "../../hooks/utilities/AxiosHttpPost";
import swytchbackSwydgetsLibrary from "../../assets/import_library/swydgets/swydget_library.json";
import swytchbackStyles from "../../css/swytchbackStyles.css";
import swytchbackModal from "../../css/swytchbackModal.css";
import { useNavigate } from "react-router";
import { CloudUpload, RadioButtonUnchecked } from "@mui/icons-material";



export default function SwydgetLibraryModal(props) {

  const navigate = useNavigate();
  const newServerUrl = MeService.cloneServerUrl + ":" + MeService.wm_new_port;
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [fileName, setFileName] = useState("");
  const [importingState, setImportingState] = useState("ready");
  const [swydgetImportJson, setSwydgetImportJson] = useState(null);
  

  const selectSwydget = (swydgetJson, idx) => {
    setFileName(swydgetJson.file_name);
    if (idx === selectedIdx) {
      setSelectedIdx(-1)
      setFileName("");
      setSwydgetImportJson(null);
    }
    else {
      setSelectedIdx(idx)
    }
  }

  const importSwydget = () => {

    setImportingState("importing");

    const endpoint = MeService.serverUrl + ":" + MeService.wm_old_port + "/event_list/create";

    var userJson = props.user.addressBookJson.contacts[0];

    props.user.addCamelCaseFieldsToUser(userJson);

    var parametersJson = {
      "address_book": props.user.addressBookJson,
      "user-id": userJson,
      "event_info": {
        name : "",
        note : "",
        invited : [

        ],
        when : "",
        where : ""
      }
    };

    // Going to hit an API here WITHOUT THE WHOLE REACT ROUTER THING
    put(endpoint, parametersJson, swydgetCreationCallback);
  }

  const swydgetCreationCallback = (swydgetData) => {

    if (swydgetImportJson && swydgetData && swydgetData.results && swydgetData.results.event) {
      // The Swydget was successfully created!!!
      const bakeryFreshSwydget = Event.createFromJson(swydgetData.results.event);
      setBakeryFreshSwydget(bakeryFreshSwydget);
      const completeSwydgetEndpoint = newServerUrl + "/1/events/event/" + bakeryFreshSwydget.eventId;
      const completeSwydgetEncodedEndpoint = completeSwydgetEndpoint.replace(/#/g, "%23");

      // Update the Swydget
      const updateSurveyJson = {
        "req_auth_token": "",
        "survey_name": swydgetImportJson.survey_name,
        "survey_type": swydgetImportJson.survey_type,
        "default_prompt": swydgetImportJson.default_prompt,
        "options": swydgetImportJson.options,
        "binary_icon_set_id": swydgetImportJson.binary_icon_set_id,
        "scalar_description": swydgetImportJson.scalarDescription,
        "mc_single_instruction": swydgetImportJson.mcSingleInstruction,
        "mc_multiple_instruction": swydgetImportJson.mcMultipleInstruction,
        "get_started_text": swydgetImportJson.get_started_text,
        "title": swydgetImportJson.title,
        "description": swydgetImportJson.description,
        "front_image": swydgetImportJson.front_image,
        "cover_photo_url": swydgetImportJson.cover_photo_url,
        "company_logo_url" : swydgetImportJson.company_logo_url,
        "wallpaper_url": swydgetImportJson.wallpaper_url,
        "reward_text": swydgetImportJson.reward_text,
        "reward_url": swydgetImportJson.reward_url,
        "reward_button_text": swydgetImportJson.reward_button_text,
        "social_sharing_channels": swydgetImportJson.social_sharing_channels,
        "call_to_action_instructions_text": swydgetImportJson.call_to_action_instructions_text,
        "contest_prize_text": swydgetImportJson.contest_prize_text,
        "call_to_action_submit_button_text": swydgetImportJson.call_to_action_submit_button_text,
        "tos_link": swydgetImportJson.tos_link,
        "randomize": swydgetImportJson.randomize,
        "issue_sub_groups": swydgetImportJson.issue_sub_groups,
        "sub_group_count": swydgetImportJson.sub_group_count,
        "limit_free_response": swydgetImportJson.limit_free_response,
        "limit_free_response_count": swydgetImportJson.limit_free_response_count,
        "show_results": swydgetImportJson.show_results,
        "location_enabled": swydgetImportJson.location_enabled,
        "default_product_str": JSON.stringify(swydgetImportJson.default_product),
      };

      // Going to hit an API here WITHOUT THE WHOLE REACT ROUTER THING
      post(completeSwydgetEncodedEndpoint, updateSurveyJson, creatingSwydgetCompletedCallback);
    }
  }

  const creatingSwydgetCompletedCallback = (data) => {

    // get the Swydget from the back end
    if (data && data.request_dict && data.request_dict.event_id) {
      const eventId = data.request_dict.event_id;
      const encodedEventId = eventId.replace("#", "%23");
      const endpoint = newServerUrl + "/1/events/event/" + encodedEventId;
      get(endpoint, fetchFreshEventCallback);
    }
  }

  const fetchFreshEventCallback = (data) => {
    const eventJson = data.result.event;
    const swydget = Event.createFromJsonAndResults(eventJson, []);
    const owl = OrderedWyshList.importOWLFromJson(swydget, swydgetImportJson['ordered_wysh_list']);
    const defaultProduct = WyshMeProduct.createFromJson(swydgetImportJson['default_product']);
    MeService.importOrderedWyshList(props.user, swydget, owl);
  }

  useEffect(() => {

    meStore.on("meStore-importStimuliSuccessful", uploadComplete);

    return () => {
      meStore.removeListener("meStore-importStimuliSuccessful", uploadComplete);
    }

  }, []);

  useEffect(() => {

    import('../../assets/import_library/swydgets/' + fileName)
      .then(data => {
        setSwydgetImportJson(data);
      })
      .catch(error => {

      });

  }, [fileName]);

  const uploadComplete = () => {
    setBakeryFreshSwydget(meStore.eventInFocus);
    setImportingState("done");
    setSelectedIdx(-1)
    setFileName("");
    setSwydgetImportJson(null);
    close();
    if (meStore.eventInFocus) {
      navigate("/survey/" + meStore.eventInFocus.eventId);  
    }
  }

  const uploadDisabled = importingState === "importing" ? true : false;
  
  const close = () => {
    setImportingState("ready");
    setSelectedIdx(-1);
    setFileName("");
    setSwydgetImportJson(null);
    props.handleClose();
  }

  const getGlyphicon = (idx) => {
    return idx === selectedIdx ? <CloudUpload /> : <RadioButtonUnchecked />;
  }

  return (
    <Modal
      onHide={close}
      size="xl"
      show={props.show}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">
          <div className="feedbackContainer"></div>
          <div>Swydget Library</div>
          <div className="feedbackContainer">
          {importingState === "importing" &&
            <CircularProgress size={20}/>
          }
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs 
            className="swytchbackModalTabs"
            id="stimuli-library-tabs-tabs"
            defaultActiveKey="global">
            <Tab eventKey="global" title="Global">
              <div className="fileTable">
                {swytchbackSwydgetsLibrary.map((swydgetJson, idx) => (
                  <div className="fileEntry" key={swydgetJson.file_name}>
                    <div className="title">{swydgetJson.name}</div>
                    <div className="button">
                      <Button onClick={() => selectSwydget(swydgetJson, idx)}>{getGlyphicon(idx)}</Button>
                    </div> 
                  </div>
                ))}
              </div>
            </Tab>   
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className={swydgetImportJson ? "swytchbackActiveButton" : "swytchbackInactiveButton"}  
          style={{margin: 5, width: 130}} 
          disabled={swydgetImportJson ? false : true} 
          onClick={importSwydget}>
          Import
        </Button>
        <Button 
          className={importingState === "importing"  ? "swytchbackInactiveButton" : "swytchbackActiveButton"}  
          style={{margin: 5, width: 130}} 
          disabled={importingState === "importing"} 
          onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
