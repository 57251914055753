export default class TurfCombinationBucket
{
  constructor(takerCount, stimuliCount, reachCount, frequency, combinations) {
    this.swydget=null;
    this.stim_count = stimuliCount;
    this.reach = reachCount;
    this.taker_count = takerCount;
    this.frequency = frequency;
    this.combo = combinations;
    this.stimuli = [];
    
  }

  getOpportunityIndex() {
    return this.reach * this.frequency;
  }

  getStimuli(productId) {
    return this.swydget.findWyshByProductId(productId);
  }

  getStimuliNames() {
    const names = this.combo.map((productId) => {
      return this.swydget.findWyshByProductId(productId).product.description
    });
    return names
    
  }

  setSwydget(swydget) {
    this.swydget = swydget;
  }

  static createFromJson(json, takerCount, swydget=null) {
    
    if (json) {
      const bucket = new TurfCombinationBucket(takerCount, json.stim_count, json.reach, json.frequency, json.combo);
      return bucket;
    }

    return null;
  }
}
