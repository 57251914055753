import TurfCombinationBucket from "./TurfCombinationBucket";

export default class TurfResults
{
  constructor(created, takerCount, message, success, includedStimuli=[], requiredStimuli=[], threshold=1, combinationsLimit=5, turfResultsDict) {
    
    this.block = null;
    this.swydget = null;
    this.created = created;
    this.turfResultsMap = new Map();
    this.includedStimuli = includedStimuli;
    this.requiredStimuli = requiredStimuli;
    this.threshold = threshold;
    this.combinationsLimit = combinationsLimit;
    this.message = message;
    this.success = success;
    this.takerCount = takerCount; 
    
    
    for (const key in turfResultsDict) {
      const turfResultsBucketArray = [];
      for (const resultJson of turfResultsDict[key]){
        turfResultsBucketArray.push(TurfCombinationBucket.createFromJson(resultJson, this.takerCount, this.swydget));
      }
      
      this.turfResultsMap.set(key, turfResultsBucketArray);
    }
  }

  setSwydget(swydget) {
    this.swydget = swydget;
    
    for (const key of this.turfResultsMap.keys()) {
      this.turfResultsMap.get(key).map((bucket) => {
        bucket.setSwydget(swydget);
      })
    }
  }

  setBlock(block) {
    this.block = block;
  }

  getRequiredStimuliNames() {

    const requiredStimuliNames = this.requiredStimuli.length === 0 ? ['None'] : this.requiredStimuli.map((productId) => {

      return this.swydget ? this.swydget.findWyshByProductId(productId).product.getUnformattedText() : ""
  
    })

    let namesString = ""

    for (const name of requiredStimuliNames) {
      namesString += name + ", "
    }
    
    return namesString.length > 1 ? namesString.slice(0, -2) : namesString;
  }

  getIncludedStimuli() {

    const stimuli = [];

    if (this.swydget) {
      this.includedStimuli.map((productId) => {
        stimuli.push(this.swydget.findWyshByProductId(productId));
      })
    }

    return stimuli;
  }

  getRequiredStimuli() {

    const stimuli = [];

    if (this.swydget) {
      this.requiredStimuli.map((productId) => {
        stimuli.push(this.swydget.findWyshByProductId(productId));
      })
    }

    return stimuli;
  }

   getCreatedStr() {
    
    const date = new Date(this.created * 1000);

    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long', // "short" for abbreviated month, or "2-digit" for numeric
      day: 'numeric',
    });
    
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return formattedDate + ", " + formattedTime;
  }

  toSummaryResultsArray() {

    const array = [];
    const headerArray = [
      "Set Size",
      "Reach",
      "Opportunity Index",
      "Frequency",
    ];

    for (const idx in this.block.orderedWyshList.wyshes) {
      headerArray.push("Stim " + (parseInt(idx) + 1))
    };
    
    array.push(headerArray);

    for (const key of this.turfResultsMap.keys()) {
      const turfComboBucketsArray = this.turfResultsMap.get(key);
      if (turfComboBucketsArray.length > 0) {
        const tcb = turfComboBucketsArray[0];
        const tcbArray = [
          tcb.stim_count,
          tcb.reach / tcb.taker_count,
          tcb.frequency * tcb.reach / tcb.taker_count,
          tcb.frequency
        ]
        
        tcb.combo.map((productId) => {
          const stimulus = this.swydget.findWyshByProductId(productId);
          tcbArray.push(this.block.orderedWyshList.findWyshIndex(stimulus));
        });

        array.push(tcbArray);
      }

      
    }

    return array;
  }

  toFullResultsArray() {

    const array = [];
    const headerArray = [
      "Set Size",
      "Reach",
      "Opportunity Index",
      "Frequency",
    ];

    for (const idx in this.block.orderedWyshList.wyshes) {
      headerArray.push("Stim " + (parseInt(idx) + 1))
    };
    
    array.push(headerArray);
    
    for (const key of this.turfResultsMap.keys()) {
      const turfComboBucketsArray = this.turfResultsMap.get(key);
      if (turfComboBucketsArray.length > 0) {

        for (const tcb of turfComboBucketsArray) {
          const tcbArray = [
            tcb.stim_count,
            tcb.reach / tcb.taker_count,
            tcb.frequency * tcb.reach / tcb.taker_count,
            tcb.frequency
          ]
          
          tcb.combo.map((productId) => {
            const stimulus = this.swydget.findWyshByProductId(productId);
            tcbArray.push(this.block.orderedWyshList.findWyshIndex(stimulus));
          });
          array.push(tcbArray);
        }
      }

      
    }

    return array;
  }

  toJson() {
    return {
      "created": this.created,
      "taker_count": this.takerCount,
      "turf_results_map": Object.fromEntries(this.turfResultsMap),
      "required_stimuli": this.requiredStimuli,
      "threshold": this.threshold,
      "combinations_limit": this.combinationsLimit
    }
  }

  log() {

    console.log(this.turfResultsMap.keys());
    

  }
}
