import React from "react";
import BinaryResults from "./BinaryResults";
import ScalarResults from "./ScalarResults";
import MultipleChoiceResults from "./MultipleChoiceResults";
import MultipleChoiceSingleResults from "./MultipleChoiceSingleResults";
import BlockTypes from "../../classes/BlockTypes";
import MaxDiffStimuliResults from "./MaxDiffStimuliResults";
import PairwiseStimuliResults from "./PairwiseStimuliResults";

export default class AdminWyshResults extends React.Component {
  render() {

    const { wysh } = this.props;
    const { snapshot } = this.props;
    const { width } = this.props;

    const { mode } = this.props;

    var resultComponent;

    let questionType = wysh.questionType;

    if (wysh.parentWysh && wysh.parentWysh.isBlock() === true && (wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) === true || wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE) === true)) {
      questionType = wysh.parentWysh.getBlockType().blockTypeId;
    }

    switch (questionType) {
      case "next":
        resultComponent = <div></div>
        break;
      case "free-response-only":
        resultComponent = <div></div>
        break;
      case "scalar":
        resultComponent = <ScalarResults wysh={wysh} snapshot={snapshot} width={width} mode={mode}/>;
        break;
      case "multiple-choice":
        resultComponent = <MultipleChoiceResults wysh={wysh} snapshot={snapshot} width={width} mode={mode}/>;
        break;
      case "single-select":
        resultComponent = <MultipleChoiceSingleResults wysh={wysh} snapshot={snapshot} width={width} mode={mode}/>;
        break;
      case "nps":
        resultComponent = <ScalarResults wysh={wysh} snapshot={snapshot} width={width} mode={mode}/>;
        break;
      case "csat":
        resultComponent = <ScalarResults wysh={wysh} snapshot={snapshot} width={width} mode={mode}/>;
        break;
      case BlockTypes.MAXDIFF.blockTypeId:
        resultComponent = <MaxDiffStimuliResults wysh={wysh} width={width} mode={mode}/>;
        break;  
      case BlockTypes.PAIRWISE.blockTypeId:
        resultComponent = <PairwiseStimuliResults wysh={wysh} width={width} mode={mode}/>;
        break;    
      default:
        resultComponent = <BinaryResults wysh={wysh} snapshot={snapshot} width={width} mode={mode}/>;
    }

    return (
      <div style={{width: '100%', height: '100%'}}>
      {resultComponent}
      </div>
    );
  }
}
