import React, { useState, useEffect } from 'react';
import classes from '../../css/modules/Stats.module.css';
import SwytchbackButton from '../../hooks/admin/controls/SwytchbackButton';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
} from 'chart.js';
import { Bar, Line } from "react-chartjs-2";
import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup } from '@mui/material';
import TurfModal from './turf/TurfModal';
import TurfResultsModal from './turf/TurfResultsModal';
import SwytchbackSelect from '../admin/controls/SwytchbackSelect';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const BlockBarAndLineCharts = ({
  user,
  block,
  mode = null,
  snapshot = null,
  snapshotsToDisplay = [],
  units = ""
}) => {

  // console.log(block.product.name + ": " + block.turfResults.length);
  
  const [selectedChart, setSelectedChart] = useState("line-all");
  const [sortOrder, setSortOrder] = useState("ordered");
  const [showLineChart, setShowLineChart] = useState(true);
  const [horizontal, setHorizontal] = useState(false);
  const [focusOnPrimaryCut, setFocusOnPrimaryCut] = useState(false);
  const [showTurfModal, setShowTurfModal] = useState(false);
  const [showTurfResultsModal, setShowTurfResultsModal] = useState(false);
  const [selectedTurfResultsCreated, setSelectedTurfResultsCreated] = useState(-1);

  const borderColor = mode ? (mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
  const textColor = mode ? (mode === "dark" ? "#ffffff" : "#000000") : "#000000";

  // useEffect(() => {
    
  //   console.log("useEffect...LOADED!!!");
    
    
  // }, [block.turfResults.length])

  const getWyshesSortedByScore = (snapshot, block) => {

    const filter = snapshot ? snapshot.filter : block.event.filter;

    switch (sortOrder) {
      case 'ordered':
        return block.orderedWyshList.getOrderedWyshes();

      case 'high-to-low':
        for (const stimulus of block.orderedWyshList.getOrderedWyshes()) {
          stimulus.applySnapshot(snapshot);
        }

        return block.orderedWyshList.getFilteredSortedWyshes("desc", filter);

      case 'low-to-high':
        for (const stimulus of block.orderedWyshList.getOrderedWyshes()) {
          stimulus.applySnapshot(snapshot);
        }

        return block.orderedWyshList.getFilteredSortedWyshes("asc", filter);

      default:
        return block.orderedWyshList.getOrderedWyshes();
    }

    return block.orderedWyshList.getOrderedWyshes();
  };

  const orderedStimuli = getWyshesSortedByScore(snapshot, block);

  const loadDatasets = (block) => {

    const datasets = [];

    if (block && block.event) {
      for (const s of snapshotsToDisplay) {
        if (s && s.equals(snapshot) === false) {
          const dataArray = [];
          const stimuliNamesArray = [];
          let takerCount = 0;
          for (const stimulus of orderedStimuli) {
            stimulus.applySnapshot(s);
            takerCount = stimulus.takerCount;
            dataArray.push(stimulus.getMetricForBlockChart());
            stimuliNamesArray.push(stimulus.product.getUnformattedText());
          }

          datasets.push({
            label: s.name + " (" + takerCount + ")",
            backgroundColor: s.color,
            borderColor: focusOnPrimaryCut ? "#aeaeae" : s.color,
            borderWidth: focusOnPrimaryCut ? 1 : 2,
            data: dataArray,
            stimuliNamesArray: stimuliNamesArray,
            snapshot: s
          })
        }
      }

      if (snapshot) {
        const dataArray = [];
        const stimuliNamesArray = [];
        let takerCount = 0;
        for (const stimulus of orderedStimuli) {
          stimulus.applySnapshot(snapshot);
          takerCount = stimulus.takerCount;
          dataArray.push(stimulus.getMetricForBlockChart());
          stimuliNamesArray.push(stimulus.product.getUnformattedText());
        }

        datasets.push({
          label: snapshot.name + " (" + takerCount + ")",
          borderColor: snapshot.color,
          backgroundColor: snapshot.color,
          borderWidth: focusOnPrimaryCut ? 4 : 2,
          data: dataArray,
          stimuliNamesArray: stimuliNamesArray,
          snapshot: snapshot,
        });
      }
      else {
        const dataArray = [];
        const stimuliNamesArray = [];
        let takerCount = 0;
        for (const stimulus of orderedStimuli) {
          takerCount = stimulus.takerCount;
          dataArray.push(stimulus.getMetricForBlockChart());
          stimuliNamesArray.push(stimulus.product.getUnformattedText());
        }

        datasets.push({
          label: "All Data (" + takerCount + ")",
          borderColor: "#0059ff",
          backgroundColor: "#0059ff",
          borderWidth: 2,
          data: dataArray,
          stimuliNamesArray: stimuliNamesArray,
          snapshot: null
        });
      }
    }

    return datasets;
  }

  const datasets = loadDatasets(block);
  
  const labelsArray = orderedStimuli.map((wysh) => {
    return "Q" + wysh.questionNumber;
  });


  const data = {
    labels: labelsArray,
    datasets: datasets,
  };


  const horizontalBarOptions = {
    indexAxis: 'y',
    elements: {
      bar: {},
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: textColor
        },
        grid: {
          color: borderColor
        }
      },
      x: {
        beginAtZero: true,
        grid: {
          color: borderColor
        },
        ticks: {
          color: textColor,
          callback: function (value) {
            return value + units;
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        padding: 14,
        displayColors: false,
        titleFont: {
          size: 20
        },
        callbacks: {
          title: (context) => {
            let label = context.length > 0 ? context[0].dataset.label : '';
            return label;
          },
          label: function (context) {
            const subContent = [];
            subContent.push(context.label);
            subContent.push("Score: " + context.raw + units);
            subContent.push(context.dataset.stimuliNamesArray[context.dataIndex]);
            return subContent;
          }
        }
      },
      title: {
        display: false
      },
    },
  };

  const verticalBarOptions = {
    indexAxis: 'x',
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + units;
          }
        },
        grid: {
          color: borderColor, // Grid line color
        }
      },
      x: {
        beginAtZero: true,
        grid: {
          color: borderColor, // Grid line color
        },
        ticks: {
          color: textColor,
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        padding: 14,
        displayColors: false,
        titleFont: {
          size: 20
        },
        callbacks: {
          title: (context) => {
            let label = context.length > 0 ? context[0].dataset.label : '';
            return label;
          },
          label: function (context) {
            const subContent = [];
            subContent.push(context.label);
            subContent.push("Score: " + context.raw + units);
            subContent.push(context.dataset.stimuliNamesArray[context.dataIndex]);
            return subContent;
          }
        }
      },
      title: {
        display: false
      },
    },
  };

  const lineOptions = {
    indexAxis: 'x',
    elements: {
      bar: {},
    },
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: textColor
        },
        grid: {
          color: borderColor
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: borderColor
        },
        ticks: {
          color: textColor,
          callback: function (value) {
            return value + units;
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        padding: 14,
        displayColors: false,
        titleFont: {
          size: 20
        },
        callbacks: {
          title: (context) => {
            let label = context.length > 0 ? context[0].dataset.label : '';
            return label;
          },
          label: function (context) {
            const subContent = [];
            subContent.push(context.label);
            subContent.push("Score: " + context.raw + units);
            subContent.push(context.dataset.stimuliNamesArray[context.dataIndex]);
            return subContent;
          }
        }
      },
      title: {
        display: false
      },
    },
  };

  const selectChartType = (e) => {
    const chartType = e.target.value;

    setSelectedChart(chartType);

    switch (chartType) {
      case 'bar-horizontal':
        setShowLineChart(false);
        setHorizontal(true);
        setFocusOnPrimaryCut(false);
        break;
      case 'bar-vertical':
        setShowLineChart(false);
        setHorizontal(false);
        setFocusOnPrimaryCut(false);
        break;
      case 'line-all':
        setShowLineChart(true);
        setFocusOnPrimaryCut(false);
        setHorizontal(false)
        break;
      case 'line-focus':
        setShowLineChart(true);
        setFocusOnPrimaryCut(true);
        setHorizontal(false)
        break;
      default:
        setShowLineChart(false);
        setHorizontal(true);
        setFocusOnPrimaryCut(false);
    }
  }

  return (
    <div className={`${classes.sectionContainer}`}>
      <div className={`${classes.chartContainer}`}>
        {showLineChart === true ?
          <Line options={lineOptions} data={data} />
          :
          <Bar options={horizontal ? horizontalBarOptions : verticalBarOptions} data={data} />
        }
      </div>
      <div className={`${classes.controlPanelContainer}`}>
        <div className={`${classes.centeredColumnContainer}`}>
          <div className={`${classes.centeredRowContainer}`}>
            <div className={`${classes.label}`}>
              Chart Type:
            </div>
            <FormControl>
              <RadioGroup
                row
                className={`${classes.buttonRowContainer}`}
                name="if-selected"
                value={selectedChart}
                onChange={(e) => { selectChartType(e) }}>
                <FormControlLabel
                  value={"bar-horizontal"}
                  style={{ color: textColor }}
                  label={"Horizontal Bar"}
                  control={
                    <Radio
                      color='primary'
                      disabled={false}
                      sx={{
                        color: textColor,
                        '&.Mui-checked': {
                          color: textColor,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={"bar-vertical"}
                  style={{ color: textColor }}
                  label={"Vertical Bar"}
                  control={
                    <Radio
                      color='primary'
                      disabled={false}
                      sx={{
                        color: textColor,
                        '&.Mui-checked': {
                          color: textColor,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={"line-all"}
                  style={{ color: textColor }}
                  label={"Line - All"}
                  control={
                    <Radio
                      color='primary'
                      disabled={false}
                      sx={{
                        color: textColor,
                        '&.Mui-checked': {
                          color: textColor,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={"line-focus"}
                  style={{ color: textColor }}
                  label={"Line - Focus"}
                  control={
                    <Radio
                      color='primary'
                      disabled={false}
                      sx={{
                        color: textColor,
                        '&.Mui-checked': {
                          color: textColor,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={`${classes.centeredRowContainer}`}>
            <div className={`${classes.label}`}>
              Sort:
            </div>
            <FormControl>
              <RadioGroup
                row
                className={`${classes.buttonRowContainer}`}
                name="if-selected"
                value={sortOrder}
                onChange={(e) => { setSortOrder(e.target.value) }}>
                <FormControlLabel
                  value={"ordered"}
                  style={{ color: textColor }}
                  label={"Ordered"}
                  control={
                    <Radio
                      color='primary'
                      disabled={false}
                      sx={{
                        color: textColor,
                        '&.Mui-checked': {
                          color: textColor,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={"high-to-low"}
                  style={{ color: textColor }}
                  label={"High to Low"}
                  control={
                    <Radio
                      color='primary'
                      disabled={false}
                      sx={{
                        color: textColor,
                        '&.Mui-checked': {
                          color: textColor,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={"low-to-high"}
                  style={{ color: textColor }}
                  label={"Low to High"}
                  control={
                    <Radio
                      color='primary'
                      disabled={false}
                      sx={{
                        color: textColor,
                        '&.Mui-checked': {
                          color: textColor,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
          { snapshot &&
          <div className={`${classes.centeredRowContainer}`}>
            <SwytchbackButton
              style={{ minWidth: 200, marginBottom: 40, marginTop: 40 }} 
              backgroundcolor="#0064FF"
              textcolor="#ffffff"
              bordercolor="#ffffff"
              hoverbordercolor="#0064FF"
              hoverbackgoundcolor="#ffffff"
              hovertextcolor="#0064FF"
              onClick={() => {
                setShowTurfModal(true);
              }}
            >
              Analysis
            </SwytchbackButton>
          </div>
          }
          
          
          <SwytchbackSelect
            style={{ color: '#000000', marginBottom: 10, marginTop: 20 }}
            id="select-turf-results"
            value={selectedTurfResultsCreated}
            onChange={(evt) => {
              setSelectedTurfResultsCreated(evt.target.value);
            }}
          >
            <MenuItem key={-1} value={-1}>Select a TURF to Display</MenuItem>
            { block.turfResults.map((tr) => {
                  return (
                    <MenuItem key={tr.created} value={tr.created}>{tr.getCreatedStr()}</MenuItem>
                  )
                }
              )
            }
          </SwytchbackSelect>
          <SwytchbackButton
              style={{ minWidth: 200, marginBottom: 20 }} 
              backgroundcolor="#0064FF"
              textcolor="#ffffff"
              bordercolor="#ffffff"
              hoverbordercolor="#0064FF"
              hoverbackgoundcolor="#ffffff"
              hovertextcolor="#0064FF"
              disabled={selectedTurfResultsCreated === -1}
              onClick={() => {
                setShowTurfResultsModal(true);
              }}
            >
              Show TURF results
          </SwytchbackButton>
        </div>
      </div>
      <TurfModal 
        user={user}
        block={block}
        snapshot={snapshot}
        showTurfModal={showTurfModal}
        closeTurfModal={() => setShowTurfModal(false)}
      />
      <TurfResultsModal
        user={user}
        block={block}
        snapshot={snapshot}
        showTurfModal={showTurfResultsModal}
        selectedTurfResults={block.getTurfResults(selectedTurfResultsCreated)}
        closeTurfModal={() => setShowTurfResultsModal(false)}
      />
    </div>
  );
}

export default BlockBarAndLineCharts;