export default class WyshOption
{
  constructor() {
    this.resultLiteral = "";
    this.resultNormalized = -1;
    this.decisionsArray = [];
    this.decisionsCount = 0;
  }

  static shuffle(array) {

    var arrayCopy = [];
    for (var i = 0; i < array.length; i++) {
      arrayCopy.push(array[i]);
    }

    var currentIndex = arrayCopy.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [arrayCopy[currentIndex], arrayCopy[randomIndex]] = [
        arrayCopy[randomIndex], arrayCopy[currentIndex]];
    }

    return arrayCopy;

  }

  static compareByResultNormalizedAsc(a, b) {
    return a.resultNormalized - b.resultNormalized;
  }

  static createWyshOption(resultLiteral, resultNormalized) {
    let wyshOption = new WyshOption();
    wyshOption.resultLiteral = resultLiteral;
    wyshOption.resultNormalized = resultNormalized;
    return wyshOption;
  }


}
