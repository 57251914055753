import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

const SwytchbackFormControlLabel = styled(FormControlLabel)(({textcolor="#ffffff", fontsize=20}) => ({
  '& .MuiFormControlLabel-label.Mui-disabled': {
    WebkitTextFillColor: "#aeaeae",
    color: "#aeaeae"
  },
  color: textcolor,
  fontSize: fontsize
}));


export default SwytchbackFormControlLabel;
