import { useState } from "react";
import axios from "axios";

const useHttpPost = (user) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  const config = {
    headers: {'Authorization': base64String}
  };

  const post = async (url, postData, callback) => {

    try {
      setLoading(true);
      const response = await axios.post(url, postData, config);
      setData(response.data);
      setError(null);

      // If the caller submitted a callback call it
      if (callback) {
        callback(response.data);
      }
    } catch (error) {
      console.log(error);
      setError(error);
      if (callback) {
        callback();
      }
    } finally {
      setLoading(false);
    }
  };

  const postAndForget = (url, postData, callback) => {
  
    axios.post(url, postData, config);

    callback();
  };

  return { data, loading, error, post, postAndForget };
};

export default useHttpPost;
