import React from 'react';
import moment from 'moment';
import * as Utilities from "../services/Utilities";
import ProductStyles from "./ProductStyles";

export default class WyshMeProduct
{
  constructor(defaultProduct=null) {
    this.productId = "";
    this.sku = "";
    this.networkSKU = "";
    this.description = defaultProduct ? defaultProduct.description : "";
    this.gtins = "";
    this.currency = defaultProduct ? defaultProduct.currency : "";
    this.imageUrl = defaultProduct ? defaultProduct.imageUrl : "";
    this.price = defaultProduct ? defaultProduct.price : 0.0;
    this.displayMerchant = defaultProduct ? defaultProduct.displayMerchant : "";
    this.score = defaultProduct ? defaultProduct.score : 0.0;
    this.added = "";
    this.updated = "";
    this.merchantID = "";
    this.buyPage = defaultProduct ? defaultProduct.buyPage : "";
    this.name = defaultProduct ? defaultProduct.name : "";
    this.creatorMid = defaultProduct ? defaultProduct.creatorMid : "";
    this.myParentWyshes = [];
    this.relevanceIndex = 0.0;
    this.attributes = {};
    this.stylesJson = {};
    this.styles = defaultProduct ? defaultProduct.styles : new ProductStyles();
  }

  getPrice(decimalPlaces) {
    var p = 0.0
    // if (typeof this.price === "string") {
    //   p = parseFloat(this.price);
    // }
    // else if (this.price !== null) {
    //   p = this.price.toFixed(decimalPlaces);
    // }

    return p;
  }

  determineFontSize(baseSize) {

    let smallSize = baseSize;
    let mediumSize = baseSize * 1.333333;
    let largeSize = baseSize * 1.666667;

    var fontSize = "2.0vh";

    switch (this.styles.fontSize) {
      case "small":
        fontSize = smallSize + "vh";
        break;
      case "medium":
        fontSize = mediumSize + "vh";
        break;
      case "large":
        fontSize = largeSize + "vh";
        break;
      default:
        fontSize = "2.0vh";
    }

    return fontSize;
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;

    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  getProductUrl() {

    var productUrl = this.imageUrl;

    var isYouTubeVideo = false;
    // are we talking youtube?
    if (this.buyPage) {
      if (this.buyPage.indexOf("youtube") > 0 || this.buyPage.indexOf("youtu.be") > 0) {
        isYouTubeVideo = true;
      }
    }

    if (isYouTubeVideo === true) {

      console.log("YOU TUBE!!!");
      if (this.buyPage.indexOf("embed") > 0){
        return this.buyPage;
      }
      else {
        if (this.buyPage.indexOf("youtu.be") > 0) {
          var anchorTag = document.createElement("a");
          anchorTag.href = this.buyPage;
          var videoId = anchorTag.pathname; // includes leading /
          productUrl = "https//www.youtube.com/embed" + videoId;
        }
        else {
          videoId = this.getParameterByName("v", this.buyPage);
          if (videoId) {
            return "https//www.youtube.com/embed/" + videoId;
          }
        }
      }
    }

    return productUrl;
  }

  static compareByNameDesc(a, b) {

    var aVal = (a.name) ? a.name : a.description;
    var bVal = (b.name) ? b.name : b.description;

    return bVal.localeCompare(aVal);
  }

  static compareByNameAsc(a, b) {

    var aVal = (a.name) ? a.name : a.description;
    var bVal = (b.name) ? b.name : b.description;

    return aVal.localeCompare(bVal);
  }

  static compareByDateUploadedAsc(a, b) {
    return moment(a.added).diff(moment(b.added));
  }

  static compareByDateUploadedDesc(a, b) {
    return moment(b.added).diff(moment(a.added));
  }

  static compareByDateUpdatedAsc(a, b) {
    return moment(a.updated).diff(moment(b.updated));
  }

  static compareByDateUpdatedDesc(a, b) {
    return moment(b.updated).diff(moment(a.updated));
  }

  static compareByRelevanceIndexAsc(a, b) {
    return a.relevanceIndex - b.relevanceIndex;
  }

  static compareByRelevanceIndexDesc(a, b) {
    return b.relevanceIndex - a.relevanceIndex;
  }

  static compareAttributesByNameDesc(a, b) {

    var aLabel = "";
    var bLabel = "";

    for (var aKey in a) {
      aLabel = aKey;
      if (aKey !== a[aKey]) {
        aLabel += ": " + a[aKey];
      }
    }

    for (var bKey in b) {
      bLabel = bKey;
      if (bKey !== b[bKey]) {
        bLabel += ": " + b[bKey];
      }
    }

    return aLabel.localeCompare(bLabel);
  }

  static generateGlobalAttributesArray(products) {
    var globalAttributesArray = [];

    for (var i = 0; i < products.length; i++) {
      for (var key in products[i].attributes) {

        var value = products[i].attributes[key];
        var attributeIsPresent = false;

        for (var j = 0; j < globalAttributesArray.length; j++) {
          if (globalAttributesArray[j][key] !== undefined) {
            if (globalAttributesArray[j][key] === value) {
              attributeIsPresent = true;
            }
          }
        }

        if (attributeIsPresent === false) {
          var newAttribute = {};
          newAttribute[key] = value
          globalAttributesArray.push(newAttribute);
        }
      }
    }

    globalAttributesArray.sort(WyshMeProduct.compareAttributesByNameDesc);

    return globalAttributesArray;
  }

  isUrlSecure(urlString) {
    if (Utilities.startsWith(urlString, "https:") === true) {
      return true;
    }
    else {
      return false;
    }
  }

  hasAttribute(key, value) {

    for (var k in this.attributes) {
      if (k === key && this.attributes[k] === value) {
        return true;
      }
    }

    return false;
  }

  getSecureUrl(urlString) {
    var secureUrl = urlString;

    if (Utilities.startsWith(secureUrl, "http:") === true) {
      let replaceRegEx = /http:/g;
      secureUrl = secureUrl.replace(replaceRegEx, "https:");
    }

    return secureUrl;
  }

  getSecureImageUrl(wysh=null) {

    var productImageUrl = this.getSecureUrl(this.imageUrl);

    if ((productImageUrl === null || productImageUrl.length === 0) && wysh && wysh.event) {
      // default background images are defined at the Swydget/Wysh level, not the product level
      productImageUrl = wysh.event.getDefaultCardBackgroundUrl();
    }

    return productImageUrl;
  }

  getSecureBuyPage() {
    return this.getSecureUrl(this.buyPage);
  }

  isImageUrlSecure() {
    return this.isUrlSecure(this.imageUrl);
  }

  isBuyPageSecure() {
    return this.isUrlSecure(this.buyPage);
  }

  hasImage() {
    return this.imageUrl && this.imageUrl.length > 0 ? true : false;
  }

  calculateRelevanceIndex(accumulatedAttributesDict) {
    var totalRelevanceIndex = 0.0;

    if (this.attributes === null) {
      return totalRelevanceIndex;
    }

    var productAttrKeys = Object.keys(this.attributes);
    var accumulatedAttrKeys = Object.keys(accumulatedAttributesDict);

    // Go through each of this product's attributes and try to find them in the
    // user's accumulatedAttributesDict.
    for (var i = 0; i < productAttrKeys.length; i++) {
      for (var j = 0; j < accumulatedAttrKeys.length; j++) {
        if (productAttrKeys[i] === accumulatedAttrKeys[j]) {
          var attributeScoresArray = accumulatedAttributesDict[accumulatedAttrKeys[j]].values;
          for (var k = 0; k < attributeScoresArray.length; k++) {
            if (attributeScoresArray[k].value === this.attributes[productAttrKeys[i]]) {
              var relevanceIndex = attributeScoresArray[k].score / (attributeScoresArray[k].numberOfOptions - 1);
              totalRelevanceIndex += relevanceIndex
            }
          }
        }
      }
    }

    this.relevanceIndex = totalRelevanceIndex;

    return totalRelevanceIndex;
  }

  initFromJson(productJson) {
    this.productId = productJson["product_id"];
    this.sku = productJson["sku"];
    this.networkSKU = productJson["networkSKU"];
    this.description = productJson["description"];
    this.gtins = productJson["gtins"];
    this.currency = productJson["currency"];
    this.imageUrl = productJson["imageUrl"];
    this.price = productJson["price"];
    this.displayMerchant = productJson["displayMerchant"];
    this.score = productJson["score"];
    this.added = productJson["added"];
    this.merchantID = productJson["merchantID"];
    this.buyPage = productJson["buyPage"];
    this.name = productJson["name"];

    if (productJson["attributes"]) {
      this.attributes = productJson["attributes"];
    }
    else {
      this.attributes = {};
    }

    if (productJson["stylesJson"]) {
      this.stylesJson = productJson["stylesJson"];
      this.styles = ProductStyles.createFromJson(productJson["stylesJson"]);
    }
    else {
      this.stylesJson = {};
    }
  }

  initFromRdbmsJson(productJson) {
    this.productId = productJson["prd_uuid"];
    this.sku = productJson["prd_sku"];
    this.networkSKU = productJson["prd_uuid"];
    this.description = productJson["prd_description"];
    this.gtins = productJson["prd_gtins"];
    this.currency = productJson["prd_currency"];
    this.imageUrl = productJson["prd_image_url"];
    this.price = productJson["prd_price"];
    this.displayMerchant = productJson["prd_merchant"];
    this.score = productJson["prd_es_score"];
    this.added = productJson["prd_created"];
    this.updated = productJson["prd_saved"];
    this.merchantID = productJson["prd_creator_mid"];
    this.buyPage = productJson["prd_buy_url"];
    this.name = productJson["prd_name"];

    if (this.updated === null) {
      this.updated = this.added;
    }

    if (productJson["prd_attributes_json"]) {
      this.attributes = productJson["prd_attributes_json"];
    }
    else {
      this.attributes = {};
    }

    if (productJson["prd_styles_json"]) {
      this.stylesJson = productJson["prd_styles_json"];
      this.styles = ProductStyles.createFromJson(productJson["prd_styles_json"]);
    }
    else {
      this.stylesJson = {};
    }

    if (!this.gtins){
      this.gtins = this.productId;
    }
  }

  toMarkup(paragraph, baseSize) {

    var distance = baseSize * 2;
    var distanceStyle = distance + "vh";

    return paragraph.split("\n").map((phrase, idx) => {
      if (idx === 0) {
        return (
          <div key={idx}>{phrase}</div>
        )
      }
      else {
        return (
          <div style={{marginTop: distanceStyle}} key={idx}>{phrase}</div>
        )
      }

    });
  }

  toParagraphsArray() {

    var paragraphs = [];

    if (this.description) {
      this.description.split("\n").map((phrase, idx) => {
        if (phrase && phrase.length > 0) {
          paragraphs.push(phrase);
        }
      });
    }

    return paragraphs;
  }

  getCardTextPlain() {
    return this.description.replace(/[\r\n]/g, " ");
  }

  getUnformattedText() {

    if (this.description && this.description.length > 0) {
      return this.getCardTextPlain()
    }
    else if (this.name && this.name.length > 0) {
      return this.name;
    }
    else {
      return "Unknown";
    }
  }

  toJsonString() {
    return JSON.stringify(this.toJsonObject());
  }

  toJsonObject() {

    return {
        product_id: this.productId,
        productId: this.productId,
        networkSKU: this.networkSKU,
        description: this.description,
        gtins: this.gtins,
        currency: this.currency,
        imageUrl: this.imageUrl,
        price: this.price,
        displayMerchant: this.displayMerchant,
        score: this.score,
        added: this.added,
        merchantID: this.merchantID,
        buyPage: this.buyPage,
        name: this.name,
        attributes: this.attributes,
        stylesJson: this.styles
      };

  }

  isBuyPageRerenderable() {
    if (this.buyPage &&
        this.buyPage.length > 0 &&
        this.buyPage.indexOf("/civicscience") < 0) {

      return true;
    }

    return false;
  }

  static getCardHeightAsPct(cardSize) {
    switch (cardSize) {
      case "small":
        return 10;
      case "medium":
        return 20;
      case "large":
        return 30;
      default:
        return 20;
    }
  }

  static createFromRdbmsJson(productJson) {
    var product = new WyshMeProduct();
    product.initFromRdbmsJson(productJson);
    return product;
  }

  static createFromJson(productJson) {
    var product = new WyshMeProduct();
    product.initFromJson(productJson);
    return product;
  }
}
