import React from 'react';
import { Container, Row, Col, Modal, Button, Tabs, Tab } from 'react-bootstrap';
// import { FacebookIcon, TwitterIcon, LinkedinIcon, PinterestIcon, EmailIcon } from 'react-share';
import { Switch, TextField } from '@mui/material';
import classes from '../css/modules/FinalizeSurvey.module.css';
import Event from "../classes/Event";
import Wysh from '../classes/Wysh';
import SwytchbackServiceFactory from "../classes/services/SwytchbackServiceFactory";
import "../css/swytchbackStyles.css";
import "../css/swytchbackModal.css";
import SwydgetWelcome from "../components/swydget/SwydgetWelcome";
import SwydgetComplete from "../components/swydget/SwydgetComplete";
import SwydgetContestEntry from "../components/swydget/SwydgetContestEntry";
import SocialSharingChannel from "../components/SocialSharingChannel";
import ImageUploadDialog from "../components/admin/ImageUploadDialog";
import CardEditor from "../components/admin/CardEditor";
import loginStore from "../stores/LoginStore";
import meStore from "../stores/MeStore";
import MeService from "../services/MeService";
import "../css/controlStyles.css";
import WelcomePageModal from '../hooks/admin/swydget/WelcomePageModal';
import ThankYouPageModal from '../hooks/admin/swydget/ThankYouPageModal';
import IncentiveModal from '../hooks/admin/swydget/IncentiveModal';
import AdvancedModal from '../hooks/admin/swydget/AdvancedModal';
import LookAndFeelModal from '../hooks/admin/swydget/LookAndFeelModal';


class FinalizeSurvey extends React.Component {

  constructor() {
    super();

    this.user = loginStore.getLoggedInUser();
    this.eventLoaded = this.eventLoaded.bind(this);
    this.handleSurveyNameChange = this.handleSurveyNameChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleDefaultPromptChange = this.handleDefaultPromptChange.bind(this);
    this.handlePreviewTitleChange = this.handlePreviewTitleChange.bind(this);
    this.handlePreviewDescriptionChange = this.handlePreviewDescriptionChange.bind(this);
    this.handleRewardTextChange = this.handleRewardTextChange.bind(this);
    this.handleContestPrizeTextChange = this.handleContestPrizeTextChange.bind(this);
    this.handleGetStartedTextChange = this.handleGetStartedTextChange.bind(this);
    this.handleTosLinkChange = this.handleTosLinkChange.bind(this);
    this.handlePrivacyLinkChange = this.handlePrivacyLinkChange.bind(this);
    this.handleRewardButtonTextChange = this.handleRewardButtonTextChange.bind(this);
    this.handleCallToActionInstructionsTextChange = this.handleCallToActionInstructionsTextChange.bind(this);
    this.handleCallToActionSubmitButtonTextChange = this.handleCallToActionSubmitButtonTextChange.bind(this);
    this.handleRewardUrlChange = this.handleRewardUrlChange.bind(this);
    this.handleRandomizeClicked = this.handleRandomizeClicked.bind(this);
    this.handleIssueSubGroupClicked = this.handleIssueSubGroupClicked.bind(this);
    this.handleLimitFreeResponseClicked = this.handleLimitFreeResponseClicked.bind(this);
    this.handleShowResultsClicked = this.handleShowResultsClicked.bind(this);
    this.handleShowRecommendationsClicked = this.handleShowRecommendationsClicked.bind(this);
    this.handleEnableSocialChannelClicked = this.handleEnableSocialChannelClicked.bind(this);
    this.handleSubGroupCountChanged = this.handleSubGroupCountChanged.bind(this);
    this.handleLimitFreeResponseCountChanged = this.handleLimitFreeResponseCountChanged.bind(this);

    // Update Handlers
    this.handleApplyPreQuizInfoClicked = this.handleApplyPreQuizInfoClicked.bind(this);
    this.handleApplyWelcomeInfoClicked = this.handleApplyWelcomeInfoClicked.bind(this);
    this.handleApplyThankYouPageInfoClicked = this.handleApplyThankYouPageInfoClicked.bind(this);
    this.handleApplyLookAndFeelClicked = this.handleApplyLookAndFeelClicked.bind(this);
    this.handleApplyIncentiveClicked = this.handleApplyIncentiveClicked.bind(this);

    // Modal Operators
    this.openIncentiveModal = this.openIncentiveModal.bind(this);
    this.closeIncentiveModal = this.closeIncentiveModal.bind(this);
    this.openAddDefaultCardBackgroundModal = this.openAddDefaultCardBackgroundModal.bind(this);
    this.closeAddDefaultCardBackgroundModal = this.closeAddDefaultCardBackgroundModal.bind(this);
    this.openThankYouPageModal = this.openThankYouPageModal.bind(this);
    this.closeAddWallpaperModal = this.closeAddWallpaperModal.bind(this);
    this.openLookAndFeelModal = this.openLookAndFeelModal.bind(this);
    this.closeLookAndFeelModal = this.closeLookAndFeelModal.bind(this);
    this.openAdvancedModal = this.openAdvancedModal.bind(this);
    this.closeAdvancedModal = this.closeAdvancedModal.bind(this);

    // Callback Methods
    this.clearImage = this.clearImage.bind(this);
    this.clearDefaultCardBackgroundImage = this.clearDefaultCardBackgroundImage.bind(this);
    this.clearWallpaperImage = this.clearWallpaperImage.bind(this);
    this.imageUploading = this.imageUploading.bind(this);
    this.wallpaperImageUploading = this.wallpaperImageUploading.bind(this);
    this.eventUpdated = this.eventUpdated.bind(this);


    this.state = {
      eventInFocus: new Event(),
      coverPhotoUrl: "",
      wallpaperUrl: "",
      title: "",
      description: "",
      defaultPrompt: "",
      previewTitle: "",
      previewDescription: "",
      rewardText: "",
      rewardUrl: "",
      contestPrizeText: "",
      callToActionInstructionText: "",
      callToActionSubmitButtonText: "",
      getStartedText: "",
      tosLink: "",
      privacyLink: "",
      rewardButtonText: "",
      subGroupCount: 0,
      limitFreeResponseCount: 0,
      limitFreeResponseDisabled: true,
      issueSubGroupsDisabled: true,
      isAddingReward: false,
      isLoading: false,
      isWallpaperLoading: false,
      isDefaultCardBackgroundLoading: false,
      showIncentiveModal: false,
      showAddDefaultCardBackgroundModal: false,
      showThankYouPageModal: false,
      showLookAndFeelModal: false,
      showAdvancedModal: false,
      coverImageFiles: [],
      wallpaperImageFiles: [],
      defaultCardBackgroundImageFiles: []
    };
  }

  componentDidMount() {

    if (this.props.params) {
      this.eventId = this.props.params.eventId;
    }
    else {
      this.eventId = this.props.eventId
    }

    MeService.getEvent(this.user.userId, this.user.password, this.eventId);
  }

  componentWillMount() {
    meStore.on("change-coverPhotoUploading", this.imageUploading);
    meStore.on("change-eventLoaded", this.eventLoaded);
    meStore.on("change-eventUpdated", this.eventUpdated);

  }

  componentWillUnmount() {
    meStore.removeListener("change-coverPhotoUploading", this.imageUploading);
    meStore.removeListener("change-eventLoaded", this.eventLoaded);
    meStore.removeListener("change-eventUpdated", this.eventUpdated);
  }

  openIncentiveModal() {
    this.setState({ showIncentiveModal: true });
  }

  closeIncentiveModal() {
    this.setState({ showIncentiveModal: false });
  }

  openAddDefaultCardBackgroundModal() {
    this.setState({ showAddDefaultCardBackgroundModal: true });
  }

  closeAddDefaultCardBackgroundModal() {
    this.setState({ showAddDefaultCardBackgroundModal: false });
  }

  openThankYouPageModal() {
    this.setState({ showThankYouPageModal: true });
  }

  closeAddWallpaperModal() {
    this.setState({ showThankYouPageModal: false });
  }

  openLookAndFeelModal() {
    this.setState({ showLookAndFeelModal: true });
  }

  closeLookAndFeelModal() {
    this.setState({ showLookAndFeelModal: false });
  }

  openAdvancedModal() {
    this.setState({ showAdvancedModal: true });
  }

  closeAdvancedModal() {
    this.setState({ showAdvancedModal: false });
  }

  imageUploading() {
    this.setState({
      isLoading: true
    });
  }

  wallpaperImageUploading() {
    this.setState({
      isWallpaperLoading: true
    });
  }

  eventLoaded() {

    var privacyLink = meStore.getEventInFocus().privacyLink;

    if (privacyLink.trim() === "" && this.user.privacyPolicyLink !== "") {
      privacyLink = this.user.privacyPolicyLink;
    }

    this.setState({
      eventInFocus: meStore.getEventInFocus(),
      surveyName: meStore.getEventInFocus().surveyName,
      title: meStore.getEventInFocus().title,
      description: meStore.getEventInFocus().description,
      defaultPrompt: meStore.getEventInFocus().defaultPrompt,
      previewTitle: meStore.getEventInFocus().previewTitle,
      previewDescription: meStore.getEventInFocus().previewDescription,
      rewardText: meStore.getEventInFocus().rewardText,
      rewardUrl: meStore.getEventInFocus().rewardUrl,
      contestPrizeText: meStore.getEventInFocus().contestPrizeText,
      callToActionInstructionText: meStore.getEventInFocus().callToActionInstructionText,
      callToActionSubmitButtonText: meStore.getEventInFocus().callToActionSubmitButtonText,
      coverPhotoUrl: meStore.getEventInFocus().coverPhotoUrl,
      wallpaperUrl: meStore.getEventInFocus().wallpaperUrl,
      subGroupCount: meStore.getEventInFocus().subGroupCount,
      getStartedText: meStore.getEventInFocus().getStartedText,
      tosLink: meStore.getEventInFocus().tosLink,
      privacyLink: privacyLink,
      rewardButtonText: meStore.getEventInFocus().rewardButtonText,
      limitFreeResponseCount: meStore.getEventInFocus().limitFreeResponseCount,
      limitFreeResponseDisabled: !meStore.getEventInFocus().randomize,
      issueSubGroupsDisabled: !meStore.getEventInFocus().randomize,
      isLoading: false,
      isWallpaperLoading: false,
      isDefaultCardBackgroundLoading: false,
      coverImageFiles: [],
      wallpaperImageFiles: [],
      defaultCardBackgroundImageFiles: [],
    });
  }

  eventUpdated() {

    var updatedEvent = this.state.eventInFocus;
    updatedEvent.title = this.state.title;
    updatedEvent.contestPrizeText = this.state.contestPrizeText;
    updatedEvent.callToActionInstructionText = this.state.callToActionInstructionText;
    updatedEvent.callToActionSubmitButtonText = this.state.callToActionSubmitButtonText;
    updatedEvent.rewardText = this.state.rewardText
    updatedEvent.rewardUrl = this.state.rewardUrl;
    updatedEvent.rewardButtonText = this.state.rewardButtonText;
    this.setState({
      eventInFocus: updatedEvent,
      // showIncentiveModal: false,
      showAddDefaultCardBackgroundModal: false,
      showThankYouPageModal: false,
      showLookAndFeelModal: false
    });
  }

  handleSurveyNameChange(event) {
    this.setState({ surveyName: event.target.value });
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleGetStartedTextChange(event) {
    this.setState({ getStartedText: event.target.value });
  }

  handleTosLinkChange(event) {
    this.setState({ tosLink: event.target.value });
  }

  handlePrivacyLinkChange(event) {
    this.setState({ privacyLink: event.target.value });
  }

  handleRewardButtonTextChange(event) {
    this.setState({ rewardButtonText: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleDefaultPromptChange(event) {
    var evt = this.state.eventInFocus;
    evt.defaultPrompt = event.target.value;
    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleScalarDescriptionChange = (event) => {
    var evt = this.state.eventInFocus;
    evt.scalarDescription = event.target.value;
    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleMcSingleInstructionChange = (event) => {
    var evt = this.state.eventInFocus;
    evt.mcSingleInstruction = event.target.value;
    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleMcMultipleInstructionChange = (event) => {
    var evt = this.state.eventInFocus;
    evt.mcMultipleInstruction = event.target.value;
    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handlePreviewTitleChange(event) {
    this.setState({ previewTitle: event.target.value });
  }

  handlePreviewDescriptionChange(event) {
    this.setState({ previewDescription: event.target.value });
  }

  handleRewardTextChange(event) {
    this.setState({ rewardText: event.target.value });
  }

  handleRewardUrlChange(event) {
    this.setState({ rewardUrl: event.target.value });
  }

  handleContestPrizeTextChange(event) {
    this.setState({ contestPrizeText: event.target.value });
  }

  handleCallToActionInstructionsTextChange(event) {
    this.setState({ callToActionInstructionText: event.target.value });
  }

  handleCallToActionSubmitButtonTextChange(event) {
    this.setState({ callToActionSubmitButtonText: event.target.value });
  }

  handleRandomizeClicked(event) {
    var evt = this.state.eventInFocus;
    evt.randomize = event.target.checked;

    if (evt.randomize === false) {
      evt.issueSubGroups = false;
      evt.limitFreeResponse = false;
      evt.limitFreeResponseCount = 1;
      evt.subGroupCount = 1;
      this.setState({
        eventInFocus: evt,
        limitFreeResponseDisabled: true,
        issueSubGroupsDisabled: true,
        limitFreeResponseCount: 1,
        subGroupCount: 1
      });
    }
    else {
      this.setState({
        eventInFocus: evt,
        limitFreeResponseDisabled: false,
        issueSubGroupsDisabled: false
      });
    }

    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);

    // clear the WyshRouter for each WYSH
    var wyshes = this.state.eventInFocus.getWyshes();
    for (var i = 0; i < wyshes.length; i++) {
      wyshes[i].wyshRouter.clearAllBranchLogicStements();
    }

    MeService.updateWyshes(this.user, this.state.eventInFocus, wyshes);
  }

  handleIssueSubGroupClicked(event) {

    var evt = this.state.eventInFocus;
    evt.issueSubGroups = event.target.checked;
    if (evt.issueSubGroups === true && this.state.limitFreeResponseCount > this.state.subGroupCount) {
      evt.limitFreeResponseCount = this.state.subGroupCount;
      this.setState({ eventInFocus: evt, limitFreeResponseCount: this.state.subGroupCount });
    }
    else {
      this.setState({ eventInFocus: evt });
    }

    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleLimitFreeResponseClicked(event) {
    var evt = this.state.eventInFocus;
    evt.limitFreeResponse = event.target.checked;
    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleShowResultsClicked(event) {
    var evt = this.state.eventInFocus;

    if (evt.showResults === true) {
      evt.showResults = false;
    }
    else {
      evt.showResults = true;
    }

    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleLocationEnabledClicked = (evt) => {
    var eif = this.state.eventInFocus;

    if (eif.locationEnabled === true) {
      eif.locationEnabled = false;
    }
    else {
      evt.locationEnabled = true;
    }

    this.setState({ eventInFocus: eif });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleShowRecommendationsClicked(event) {
    var evt = this.state.eventInFocus;

    if (evt.showRecommendations === true) {
      evt.showRecommendations = false;
    }
    else {
      evt.showRecommendations = true;
    }

    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleEnableSocialChannelClicked(channelCode) {
    var evt = this.state.eventInFocus;

    if (this.state.eventInFocus.isSocialChannelEnabled(channelCode) === true) {
      evt.disableSocialChannel(channelCode);
    }
    else {
      evt.enableSocialChannel(channelCode);
    }

    this.setState({ eventInFocus: evt });
  }

  handleSubGroupCountChanged(event) {

    var fieldValue = event.target.value;

    var count = 0;

    if (fieldValue.trim() !== "") {
      count = parseInt(fieldValue, 10);

      if (this.state.eventInFocus.wyshes.length > 2) {
        if (count < 1) {
          count = 1;
        }
        else if (count > Math.floor(this.state.eventInFocus.wyshes.length, 10)) {
          count = Math.floor(this.state.eventInFocus.wyshes.length, 10);
        }
      }
      else {
        count = 1;
      }

      this.setState({
        subGroupCount: count.toString()
      });

      var evt = this.state.eventInFocus;
      evt.subGroupCount = count;
      this.setState({ eventInFocus: evt });
      MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
    }
    else {
      this.setState({
        subGroupCount: ""
      });
    }
  }

  handleLimitFreeResponseCountChanged(event) {

    var fieldValue = event.target.value;

    var count = 0;

    if (fieldValue.trim() !== "") {
      count = parseInt(fieldValue, 10);

      if (this.state.eventInFocus.wyshes.length > 2) {
        if (count < 1) {
          count = 1;
        }
        else if (this.state.eventInFocus.issueSubGroups === true && this.state.subGroupCount > 0 && count > this.state.subGroupCount) {
          count = this.state.subGroupCount;
        }
        else if (count > Math.floor(this.state.eventInFocus.wyshes.length, 10)) {
          count = Math.floor(this.state.eventInFocus.wyshes.length, 10);
        }
      }
      else {
        count = 1;
      }

      this.setState({
        limitFreeResponseCount: count.toString()
      });

      var evt = this.state.eventInFocus;
      evt.limitFreeResponseCount = count;
      this.setState({ eventInFocus: evt });
      MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
    }
    else {
      this.setState({
        limitFreeResponseCount: ""
      });
    }
  }

  handleShowSwytchbackTOSClicked = (event) => {
    var evt = this.state.eventInFocus;
    evt.showSwytchbackTOS = event.target.checked;
    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  shouldIncentiveBeDisabled() {

    if (this.state.contestPrizeText === this.state.eventInFocus.contestPrizeText &&
      this.state.callToActionInstructionText === this.state.eventInFocus.callToActionInstructionText &&
      this.state.callToActionSubmitButtonText === this.state.eventInFocus.callToActionSubmitButtonText &&
      this.state.tosLink === this.state.eventInFocus.tosLink) {
      return true;
    }
    else {
      return false;
    }
  }

  shouldAddContestBeDisabled() {
    if (this.state.contestPrizeText === '') {
      return true;
    }
    else {
      return false;
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      coverImageFiles: acceptedFiles,
      coverPhotoUrl: URL.createObjectURL(acceptedFiles[0])
    });
  }

  onWallpaperDrop(acceptedFiles, rejectedFiles) {
    // we need to set the wallpaperUrl as well.

    if (acceptedFiles && acceptedFiles.length > 0) {
      this.setState({
        wallpaperImageFiles: acceptedFiles,
        wallpaperUrl: URL.createObjectURL(acceptedFiles[0])
      });
    }
  }

  onDefaultCardBackgroundDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      defaultCardBackgroundImageFiles: acceptedFiles
    });
  }

  clearImage() {
    this.setState({
      coverPhotoUrl: "",
      coverImageFiles: []
    });
  }

  clearWallpaperImage() {
    this.setState({
      wallpaperUrl: "",
      wallpaperImageFiles: []
    });
  }

  clearDefaultCardBackgroundImage() {
    this.setState({
      defaultCardBackgroundImageFiles: []
    });
  }

  handleApplyPreQuizInfoClicked(e) {
    e.preventDefault();

    if (
      this.state.title !== this.state.eventInFocus.title ||
      this.state.surveyName !== this.state.eventInFocus.surveyName ||
      this.state.description !== this.state.eventInFocus.description
    ) {

      var evt = this.state.eventInFocus;
      evt.surveyName = this.state.surveyName;
      evt.title = this.state.title;
      evt.description = this.state.description;
      this.setState({ eventInFocus: evt });

      MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
    }

    if (this.state.coverImageFiles.length > 0) {
      MeService.uploadCoverPhoto(this.user, this.state.eventInFocus, this.state.coverImageFiles[0]);
    }
  }

  handleApplyLookAndFeelClicked(e) {
    e.preventDefault();

    // if (
    //     this.state.previewTitle !== this.state.eventInFocus.previewTitle ||
    //     this.state.wallpaperUrl !== this.state.eventInFocus.wallpaperUrl ||
    //     this.state.previewDescription !== this.state.eventInFocus.previewDescription ||
    //     this.state.wallpaperImageFiles.length > 0 ||
    //     this.state.defaultCardBackgroundImageFiles.length > 0
    //   ) {

    var evt = this.state.eventInFocus;
    evt.previewTitle = this.state.previewTitle;
    evt.previewDescription = this.state.previewDescription;
    evt.wallpaperUrl = this.state.wallpaperUrl;
    this.setState({
      eventInFocus: evt
    });

    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);

    if (this.state.wallpaperImageFiles.length > 0) {
      MeService.uploadWallpaper(this.user, this.state.eventInFocus, this.state.wallpaperImageFiles[0]);
    }

    if (this.state.defaultCardBackgroundImageFiles.length > 0) {
      MeService.uploadDefaultCardBackground(this.user, this.state.eventInFocus, this.state.defaultCardBackgroundImageFiles[0]);
    }
    // }

    this.closeLookAndFeelModal();
  }

  shouldApplyLookAndFeelBeDisabled() {

    // KLUDGE: Punting on this for now. Need to take a look at the eventInFocus vs what is in the MeStore I guess to determine if things have changed

    return false;

    // if (false || this.state.previewTitle !== this.state.eventInFocus.previewTitle ||
    //     this.state.wallpaperUrl !== this.state.eventInFocus.wallpaperUrl ||
    //     this.state.previewDescription !== this.state.eventInFocus.previewDescription ||
    //     this.state.wallpaperImageFiles.length > 0 ||
    //     this.state.defaultCardBackgroundImageFiles.length > 0) {
    //
    //   return false;
    // }
    // else {
    //   return true;
    // }
  }

  handleApplyWelcomeInfoClicked(e) {

    e.preventDefault();

    if ((this.state.title !== this.state.eventInFocus.title ||
      this.state.description !== this.state.eventInFocus.description ||
      this.state.coverPhotoUrl !== this.state.eventInFocus.coverPhotoUrl ||
      this.state.getStartedText !== this.state.eventInFocus.getStartedText) && this.state.isLoading === false) {

      var evt = this.state.eventInFocus;
      evt.title = this.state.title;
      evt.description = this.state.description;
      evt.getStartedText = this.state.getStartedText;
      evt.coverPhotoUrl = this.state.coverPhotoUrl;
      this.setState({ eventInFocus: evt });

      MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
    }

    if (this.state.coverImageFiles.length > 0) {
      MeService.uploadCoverPhoto(this.user, this.state.eventInFocus, this.state.coverImageFiles[0]);
    }
  }

  handleApplyThankYouPageInfoClicked(e) {
    e.preventDefault();

    var evt = this.state.eventInFocus;
    evt.rewardText = this.state.rewardText;
    evt.rewardUrl = this.state.rewardUrl;
    evt.rewardButtonText = this.state.rewardButtonText;
    this.setState({ eventInFocus: evt });

    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleApplyIncentiveClicked(e) {
    e.preventDefault();

    if (
      this.state.contestPrizeText !== this.state.eventInFocus.contestPrizeText ||
      this.state.callToActionInstructionText !== this.state.eventInFocus.callToActionInstructionText ||
      this.state.tosLink !== this.state.eventInFocus.getTosLink() ||
      this.state.privacyLink !== this.state.eventInFocus.privacyLink ||
      this.state.callToActionSubmitButtonText !== this.state.eventInFocus.callToActionSubmitButtonText
    ) {

      var evt = this.state.eventInFocus;
      evt.callToActionInstructionText = this.state.callToActionInstructionText;
      evt.callToActionSubmitButtonText = this.state.callToActionSubmitButtonText;
      evt.contestPrizeText = this.state.contestPrizeText;
      evt.tosLink = this.state.tosLink;
      evt.privacyLink = this.state.privacyLink;
      this.setState({ eventInFocus: evt });

      MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
    }
  }


  shouldSaveImageBeDisabled() {
    if ((
      this.state.coverImageFiles.length > 0 ||
      this.state.defaultCardBackgroundImageFiles.length > 0 ||
      this.state.wallpaperImageFiles.length > 0 ||
      this.state.surveyName !== this.state.eventInFocus.surveyName ||
      this.state.title !== this.state.eventInFocus.title ||
      this.state.description !== this.state.eventInFocus.description ||
      this.state.previewTitle !== this.state.eventInFocus.previewTitle ||
      this.state.previewDescription !== this.state.eventInFocus.previewDescription
    ) && this.state.isLoading === false) {
      return false;
    }
    else {
      return true;
    }
  }

  shouldUpdateSwydgetViewBeDisabled = () => {
    if ((
      this.state.coverImageFiles.length > 0 ||
      this.state.title !== this.state.eventInFocus.title ||
      this.state.coverPhotoUrl !== this.state.eventInFocus.coverPhotoUrl ||
      this.state.getStartedText !== this.state.eventInFocus.getStartedText ||
      this.state.description !== this.state.eventInFocus.description) && this.state.isLoading === false) {
      return false;
    }
    else {
      return true;
    }
  }

  shouldApplyThankYouPageBeDisabled() {
    // use to be this, but it wasn't considering the social sharing switches. Let's just have the button always enabled unless it is updating already.
    // if ((
    //       this.state.rewardText !== this.state.eventInFocus.rewardText ||
    //       this.state.rewardUrl !== this.state.eventInFocus.rewardUrl ||
    //       this.state.rewardButtonText !== this.state.eventInFocus.rewardButtonText
    //     ) && this.state.isLoading === false) {
    //   return false;
    // }
    // else {
    //   return true;
    // }
    if (this.state.isLoading === false) {
      return false;
    }
    else {
      return true;
    }
  }

  handleProductDescriptionChange = (evt) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.description = evt.target.value;
    this.setState({ eventInFocus: eif });
  }

  handleThemeRadioBoxChange = (evt) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.textShadow = evt.target.value;
    this.setState({ eventInFocus: eif });
  }

  handleJustificationChange = (value) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.justification = value;
    this.setState({ eventInFocus: eif });
  }

  handleVerticalAlignChange = (value) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.verticalAlign = value;
    this.setState({ eventInFocus: eif });
  }

  handleFontSizeChange = (value) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.fontSize = value;
    this.setState({ eventInFocus: eif });
  }

  handleColorPickerChange = (color) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.textColor = color.hex;
    this.setState({ eventInFocus: eif });
  }

  handleFontFamilyChange = (evt) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.fontFamily = evt.target.value;
    this.setState({ eventInFocus: eif });
  }

  handleIsBoldChange = () => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.isBold = !eif.defaultProduct.styles.isBold;
    this.setState({ eventInFocus: eif });
  }

  handleIsItalicizedChange = () => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.isItalicized = !eif.defaultProduct.styles.isItalicized;
    this.setState({ eventInFocus: eif });
  }

  handleObjectFitChange = (objectFit) => {

    var eif = this.state.eventInFocus;
    eif.defaultProduct.styles.objectFit = objectFit;
    this.setState({ eventInFocus: eif });
  }


  render() {

    const { navToBuildHandler } = this.props;
    const stimulus = new Wysh();
    stimulus.product = this.state.eventInFocus.defaultProduct;

    const socialSharingChannels = {
      FACEBOOK: new SocialSharingChannel("facebook", "Facebook"),
      TWITTER: new SocialSharingChannel("twitter", "Twitter"),
      LINKED_IN: new SocialSharingChannel("linked_in", "LinkedIn"),
      PINTEREST: new SocialSharingChannel("pinterest", "Pinterest"),
      EMAIL: new SocialSharingChannel("email", "Email"),
    };

    let dividerStyle = {
      height: 74,
      borderBottom: 2,
      borderBottomColor: '#666',
      borderBottomStyle: 'solid',
      marginBottom: 0,
      paddingLeft: 0,
      textAlign: 'center'
    };

    let toggleRowDivStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: 60,
      width: '100%',
      padding: 5
    }

    let iconRowDivStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '50%',
      paddingRight: 30

    }

    let buttonGroupRowDivStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '50%'
    }

    let addContentFormDivStyle = {
      minHeight: 400,
      marginTop: 40,
      marginBottom: 0,
      marginLeft: 30,
      marginRight: 30
    };

    let fullWidthWidgetContainerStyle = {
      marginTop: 0,
      marginBottom: 0,
      paddingRight: 5,
      paddingLeft: 5
    }

    let centeredContainerDivStyle = {
      minHeight: 400,
      marginTop: 0,
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    };

    let tableContainerStyle = {
      border: 1,
      borderColor: '#afb8c8',
      borderStyle: 'solid',
      backgroundColor: '#ffffff'
    };

    let sectionTitleDivStyle = {
      padding: 5,
      marginTop: 30,
      color: '#53585F',
      fontSize: 20,
      fontWeight: 300
    }

    let colBoxStyle = {
      padding: 5,
      marginTop: 20
    }

    let oneButtonBoxStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f1f2f6',
      textAlign: 'center',
      border: 1,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      height: '24vh',
      margin: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10
    }

    let surveyTypeNameStyle = {
      width: '90%',
      margin: 10,
      paddingBottom: 10,
      fontSize: 17,
      fontWeight: 500,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#545561'
    }

    let horizontallyAndVerticallyCenteredContainerStyle = {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }

    let buttonContainerStyle = {
      width: '80%',
      padding: 5,
    }

    let enabledButtonStyle = {
      width: '100%',
      height: 50,
      fontSize: 18,
      fontWeight: 500,
      backgroundColor: '#1994fc',
      borderRadius: 8,
      border: 0
    }

    return (
      <div>
        <div>
          <Container>
            <Row>
              <Col md={12} style={sectionTitleDivStyle}>
                Build & Preview
              </Col>
            </Row>
            <Row>
              <Col md={4} style={colBoxStyle}>
                <div style={oneButtonBoxStyle}>
                  <div style={surveyTypeNameStyle}>Survey Cards</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={navToBuildHandler}>Build</Button></div>
                  </div>
                </div>
              </Col>
              <Col md={4} style={colBoxStyle}>
                <div style={oneButtonBoxStyle}>
                  <div style={surveyTypeNameStyle}>Welcome Page</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openAddDefaultCardBackgroundModal}>Build</Button></div>
                  </div>
                </div>
              </Col>
              <Col md={4} style={colBoxStyle}>
                <div style={oneButtonBoxStyle}>
                  <div style={surveyTypeNameStyle}>Thank You Page</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openThankYouPageModal}>Build</Button></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={sectionTitleDivStyle}>
                Customize
              </Col>
            </Row>
            <Row>
              <Col md={4} style={colBoxStyle}>
                <div style={oneButtonBoxStyle}>
                  <div style={surveyTypeNameStyle}>Look & Feel</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openLookAndFeelModal}>Edit</Button></div>
                  </div>
                </div>
              </Col>
              <Col md={4} style={colBoxStyle}>
                <div style={oneButtonBoxStyle}>
                  <div style={surveyTypeNameStyle}>Incentive</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openIncentiveModal}>Add</Button></div>
                  </div>
                </div>
              </Col>
              <Col md={4} style={colBoxStyle}>
                <div style={oneButtonBoxStyle}>
                  <div style={surveyTypeNameStyle}>Advanced</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openAdvancedModal}>Edit</Button></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} style={fullWidthWidgetContainerStyle}>

              </Col>
              <Col md={6} style={fullWidthWidgetContainerStyle}>

              </Col>
            </Row>
            <Row>
              <Col md={12} style={fullWidthWidgetContainerStyle}>
                <div style={dividerStyle}></div>
              </Col>
            </Row>
          </Container>
        </div>
        <WelcomePageModal
          showWelcomePageModal={this.state.showAddDefaultCardBackgroundModal}
          closeWelcomePageModal={this.closeAddDefaultCardBackgroundModal}
          primaryText={this.state.title}
          handlePrimaryTextChange={this.handleTitleChange}
          secondaryText={this.state.description}
          handleSecondaryTextChange={this.handleDescriptionChange}
          getStartedText={this.state.getStartedText}
          handleGetStartedTextChange={this.handleGetStartedTextChange}
          coverImageFiles={this.state.coverImageFiles}
          coverPhotoUrl={this.state.coverPhotoUrl}
          onDrop={this.onDrop}
          clearImage={this.clearImage}
          swydget={this.state.eventInFocus}
          shouldUpdateSwydgetViewBeDisabled={this.shouldUpdateSwydgetViewBeDisabled}
          handleApplyWelcomeInfoClicked={this.handleApplyWelcomeInfoClicked}
          isLoading={this.state.isLoading}
        />
        <ThankYouPageModal
          showThankYouPageModal={this.state.showThankYouPageModal}
          closeAddWallpaperModal={this.closeAddWallpaperModal}
          rewardText={this.state.rewardText}
          handleRewardTextChange={this.handleRewardTextChange}
          rewardUrl={this.state.rewardUrl}
          handleRewardUrlChange={this.handleRewardUrlChange}
          rewardButtonText={this.state.rewardButtonText}
          handleRewardButtonTextChange={this.handleRewardButtonTextChange}
          handleEnableSocialChannelClicked={this.handleEnableSocialChannelClicked}
          swydget={this.state.eventInFocus}
          handleApplyThankYouPageInfoClicked={this.handleApplyThankYouPageInfoClicked}
        />
        <LookAndFeelModal
          showLookAndFeelModal={this.state.showLookAndFeelModal}
          closeLookAndFeelModal={this.closeLookAndFeelModal}
          stimulus={stimulus}
          imageUrl={this.state.eventInFocus.defaultProduct.imageUrl}
          imageFiles={this.state.defaultCardBackgroundImageFiles}
          onDropMethod={this.onDefaultCardBackgroundDrop.bind(this)}
          handleDescriptionChangeMethod={this.handleProductDescriptionChange}
          handleThemeRadioBoxChangeMethod={this.handleThemeRadioBoxChange}
          handleJustificationChangeMethod={this.handleJustificationChange}
          handleVerticalAlignChangeMethod={this.handleVerticalAlignChange}
          handleFontSizeChangeMethod={this.handleFontSizeChange}
          handleColorPickerChangeMethod={this.handleColorPickerChange}
          handleFontFamilyChangeMethod={this.handleFontFamilyChange}
          handleIsBoldChangeMethod={this.handleIsBoldChange}
          handleIsItalicizedChangeMethod={this.handleIsItalicizedChange}
          handleObjectFitChangeMethod={this.handleObjectFitChange}
          resetImageMethod={this.clearDefaultCardBackgroundImage}
          wallpaperImageFiles={this.state.wallpaperImageFiles}
          wallpaperUrl={this.state.wallpaperUrl}
          onWallpaperDrop={this.onWallpaperDrop.bind(this)}
          clearWallpaperImage={this.clearWallpaperImage}
          previewTitle={this.state.previewTitle}
          handlePreviewTitleChange={this.handlePreviewTitleChange}
          previewDescription={this.state.previewDescription}
          handlePreviewDescriptionChange={this.handlePreviewDescriptionChange}
          handleApplyLookAndFeelClicked={this.handleApplyLookAndFeelClicked}
        />
        <Modal dialogClassName="swytchbackModal90w" show={false} onHide={this.closeLookAndFeelModal} backdrop="static">
          <Modal.Header className="swytchbackModalHeader" closeButton>
            <Modal.Title className="swytchbackModalTitle">Look & Feel</Modal.Title>
          </Modal.Header>
          <Modal.Body className="swytchbackModalBody coloredBackground">
            <div className="divRowStyle">
              <div className="divColumnStyle" style={{width: '100%'}}>
                <Tabs 
                  className="swytchbackModalTabs" 
                  defaultActiveKey={1} 
                  id="uncontrolled-tab-example"
                  style={{width: '100%'}}>
                  <Tab eventKey={1} title="Card">
                    <CardEditor
                      readOnly={false}
                      stimulus={stimulus}
                      sampleCardText="Card Text"
                      productLink={this.state.eventInFocus.defaultProduct.imageUrl}
                      imageFiles={this.state.defaultCardBackgroundImageFiles}
                      onDropMethod={this.onDefaultCardBackgroundDrop.bind(this)}
                      handleDescriptionChangeMethod={this.handleProductDescriptionChange}
                      handleThemeRadioBoxChangeMethod={this.handleThemeRadioBoxChange}
                      handleJustificationChangeMethod={this.handleJustificationChange}
                      handleVerticalAlignChangeMethod={this.handleVerticalAlignChange}
                      handleFontSizeChangeMethod={this.handleFontSizeChange}
                      handleColorPickerChangeMethod={this.handleColorPickerChange}
                      handleFontFamilyChangeMethod={this.handleFontFamilyChange}
                      handleIsBoldChangeMethod={this.handleIsBoldChange}
                      handleIsItalicizedChangeMethod={this.handleIsItalicizedChange}
                      handleObjectFitChangeMethod={this.handleObjectFitChange}
                      resetImageMethod={this.clearDefaultCardBackgroundImage}
                      handleApplyLookAndFeelClicked={this.handleApplyLookAndFeelClicked}
                    />
                  </Tab>
                  <Tab eventKey={2} title="Wallpaper">
                    <ImageUploadDialog
                      caption="Wallpaper Image"
                      imageFiles={this.state.wallpaperImageFiles}
                      imageUrl={this.state.wallpaperUrl}
                      onDrop={this.onWallpaperDrop.bind(this)}
                      clearImage={this.clearWallpaperImage}
                    />
                  </Tab>
                  <Tab eventKey={3} title="Link Preview">
                    <div style={tableContainerStyle}>
                      <div style={addContentFormDivStyle}>
                        <form className={`${classes.container}`} noValidate autoComplete="off">
                          <TextField
                            id="preview_title"
                            label="Primary Text"
                            className={`${classes.textField}`}
                            InputProps={{
                              classes: {
                                input: `${classes.swytchbackInputStyles}`,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: `${classes.swytchbackInputLabelStyles}`
                              },
                            }}
                            value={this.state.previewTitle}
                            onChange={this.handlePreviewTitleChange}
                            margin="normal"
                            variant="outlined"
                          />
                          <TextField
                            id="preview_description"
                            label="Secondary Text"
                            className={`${classes.textField}`}
                            InputProps={{
                              classes: {
                                input: `${classes.swytchbackInputStyles}`,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: `${classes.swytchbackInputLabelStyles}`
                              },
                            }}
                            value={this.state.previewDescription}
                            onChange={this.handlePreviewDescriptionChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </form>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>  
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.shouldApplyLookAndFeelBeDisabled()}  bsStyle="primary" onClick={this.handleApplyLookAndFeelClicked}>
              {this.state.isLoading ? 'Saving...' : 'Apply'}
            </Button>
          </Modal.Footer>
        </Modal>
        <AdvancedModal
          showAdvancedModal={this.state.showAdvancedModal}
          closeAdvancedModal={this.closeAdvancedModal}
          randomize={this.state.eventInFocus.randomize}
          handleRandomizeClicked={this.handleRandomizeClicked}
          issueSubGroupsDisabled={this.state.issueSubGroupsDisabled}
          issueSubGroups={this.state.eventInFocus.issueSubGroups}
          handleIssueSubGroupClicked={this.handleIssueSubGroupClicked}
          subGroupCount={this.state.subGroupCount}
          handleSubGroupCountChanged={this.handleSubGroupCountChanged}
          limitFreeResponseDisabled={this.state.limitFreeResponseDisabled}
          limitFreeResponse={this.state.eventInFocus.limitFreeResponse}
          handleLimitFreeResponseClicked={this.handleLimitFreeResponseClicked}
          limitFreeResponseCount={this.state.limitFreeResponseCount}
          handleLimitFreeResponseCountChanged={this.handleLimitFreeResponseCountChanged}
          showResults={this.state.eventInFocus.showResults}
          handleShowResultsClicked={this.handleShowResultsClicked}
          locationEnabled={this.state.eventInFocus.locationEnabled}
          handleLocationEnabledClicked={this.handleLocationEnabledClicked}
          defaultPrompt={this.state.eventInFocus.defaultPrompt}
          handleDefaultPromptChange={this.handleDefaultPromptChange}
          scalarDescription={this.state.eventInFocus.scalarDescription}
          handleScalarDescriptionChange={this.handleScalarDescriptionChange}
          mcSingleInstruction={this.state.eventInFocus.mcSingleInstruction}
          handleMcSingleInstructionChange={this.handleMcSingleInstructionChange}
          mcMultipleInstruction={this.state.eventInFocus.mcMultipleInstruction}
          handleMcMultipleInstructionChange={this.handleMcMultipleInstructionChange}
          showRecommendations={this.state.eventInFocus.showRecommendations}
          handleShowRecommendationsClicked={this.handleShowRecommendationsClicked}
          swydget={this.state.eventInFocus}
          user={this.user}
        />
        <Modal show={false} onHide={this.closeAdvancedModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>Advanced</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 0, margin: 0 }}>
            <div style={{ backgroundColor: '#f7f8fa' }}>
              <Tabs className="productCreationModal" defaultActiveKey={1} id="uncontrolled-tab-example">
                <Tab eventKey={1} title="Logic">
                  <div style={tableContainerStyle}>
                    <div style={centeredContainerDivStyle}>
                      <form className={`${classes.container}`} noValidate autoComplete="off">
                        <div style={toggleRowDivStyle}>
                          <div style={iconRowDivStyle}>
                            Randomize
                          </div>
                          <div style={buttonGroupRowDivStyle}>
                            <Switch
                              checked={this.state.eventInFocus.randomize}
                              onChange={this.handleRandomizeClicked}
                              color="primary"
                            />
                          </div>
                        </div>
                        <div style={toggleRowDivStyle}>
                          <div style={iconRowDivStyle}>
                            Batch
                          </div>
                          <div style={buttonGroupRowDivStyle}>
                            <Switch
                              disabled={this.state.issueSubGroupsDisabled}
                              checked={this.state.eventInFocus.issueSubGroups}
                              onChange={this.handleIssueSubGroupClicked}
                              color="primary"
                            />
                          </div>
                        </div>
                        <div style={toggleRowDivStyle}>
                          <div style={iconRowDivStyle}>

                          </div>
                          <div className={`${classes.swytchbackLeftJustifiedContainer}`} style={{ width: '50%' }}>
                            <TextField
                              id="subGroupCount"
                              className={`${classes.numberTextField}`}
                              label="Count"
                              InputProps={{
                                classes: {
                                  input: `${classes.swytchbackInputStyles}`,
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: `${classes.swytchbackInputLabelStyles}`
                                },
                              }}
                              value={this.state.subGroupCount}
                              type="number"
                              onChange={this.handleSubGroupCountChanged}
                              margin="normal"
                              variant="outlined"
                            />
                          </div>
                        </div>
                        <div style={toggleRowDivStyle}>
                          <div style={iconRowDivStyle}>
                            Limit Free Response
                          </div>
                          <div style={buttonGroupRowDivStyle}>
                            <Switch
                              disabled={this.state.limitFreeResponseDisabled}
                              checked={this.state.eventInFocus.limitFreeResponse}
                              onChange={this.handleLimitFreeResponseClicked}
                              color="primary"
                            />
                          </div>
                        </div>
                        <div style={toggleRowDivStyle}>
                          <div style={iconRowDivStyle}>

                          </div>
                          <div className={`${classes.swytchbackLeftJustifiedContainer}`} style={{ width: '50%' }}>
                            <TextField
                              id="limitFreeResponseCount"
                              className={`${classes.numberTextField}`}
                              label="Count"
                              InputProps={{
                                classes: {
                                  input: `${classes.swytchbackInputStyles}`,
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: `${classes.swytchbackInputLabelStyles}`
                                },
                              }}
                              value={this.state.limitFreeResponseCount}
                              type="number"
                              onChange={this.handleLimitFreeResponseCountChanged}
                              margin="normal"
                              variant="outlined"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey={2} title="Results">
                  <div style={tableContainerStyle}>
                    <div style={centeredContainerDivStyle}>
                      <div style={toggleRowDivStyle}>
                        <div style={iconRowDivStyle}>
                          Display Swydget Results
                        </div>
                        <div style={buttonGroupRowDivStyle}>
                          <Switch
                            checked={this.state.eventInFocus.showResults}
                            onChange={this.handleShowResultsClicked}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey={3} title="Location">
                  <div style={tableContainerStyle}>
                    <div style={centeredContainerDivStyle}>
                      <div style={toggleRowDivStyle}>
                        <div style={iconRowDivStyle}>
                          Gather Taker Location
                        </div>
                        <div style={buttonGroupRowDivStyle}>
                          <Switch
                            checked={this.state.eventInFocus.locationEnabled}
                            onChange={this.handleLocationEnabledClicked}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey={4} title="Prompt">
                  <div style={tableContainerStyle}>
                    <div style={addContentFormDivStyle}>
                      <form className={`${classes.container}`} noValidate autoComplete="off">
                        <TextField
                          id="default_prompt"
                          label="Enter Default Prompt"
                          className={`${classes.textField}`}
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={this.state.eventInFocus.defaultPrompt}
                          onChange={this.handleDefaultPromptChange}
                          margin="normal"
                          variant="outlined"
                        />
                        <TextField
                          id="scalar_description"
                          label="Scalar Instruction"
                          className={`${classes.textField}`}
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={this.state.eventInFocus.scalarDescription}
                          onChange={this.handleScalarDescriptionChange}
                          margin="normal"
                          variant="outlined"
                        />
                        <TextField
                          id="mc_single_instruction"
                          label="Single Select Instruction"
                          className={`${classes.textField}`}
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={this.state.eventInFocus.mcSingleInstruction}
                          onChange={this.handleMcSingleInstructionChange}
                          margin="normal"
                          variant="outlined"
                        />
                        <TextField
                          id="mc_multiple_instruction"
                          label="Multiple Choice Instruction"
                          className={`${classes.textField}`}
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={this.state.eventInFocus.mcMultipleInstruction}
                          onChange={this.handleMcMultipleInstructionChange}
                          margin="normal"
                          variant="outlined"
                        />
                      </form>
                    </div>
                  </div>
                </Tab>
                {this.user.isAuthorized(SwytchbackServiceFactory.getService("service_internal")) &&
                  <Tab eventKey={5} title="Recommendations">
                    <div style={tableContainerStyle}>
                      <div style={centeredContainerDivStyle}>
                        <div style={toggleRowDivStyle}>
                          <div style={iconRowDivStyle}>
                            Display Recommendations
                          </div>
                          <div style={buttonGroupRowDivStyle}>
                            <Switch
                              checked={this.state.eventInFocus.showRecommendations}
                              onChange={this.handleShowRecommendationsClicked}
                              color="primary"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                }
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={false} bsStyle="primary" onClick={this.closeAdvancedModal}>Done</Button>
          </Modal.Footer>
        </Modal>
        <IncentiveModal
          showIncentiveModal={this.state.showIncentiveModal}
          closeIncentiveModal={this.closeIncentiveModal}
          contestPrizeText={this.state.contestPrizeText}
          handleContestPrizeTextChange={this.handleContestPrizeTextChange}
          callToActionInstructionText={this.state.callToActionInstructionText}
          handleCallToActionInstructionsTextChange={this.handleCallToActionInstructionsTextChange}
          callToActionSubmitButtonText={this.state.callToActionSubmitButtonText}
          handleCallToActionSubmitButtonTextChange={this.handleCallToActionSubmitButtonTextChange}
          showSwytchbackTOS={this.state.eventInFocus.showSwytchbackTOS}
          handleShowSwytchbackTOSClicked={this.handleShowSwytchbackTOSClicked}
          swydget={this.state.eventInFocus}
          handleApplyIncentiveClicked={this.handleApplyIncentiveClicked}
          swydgetTOSLink={this.state.tosLink}
          handleSwydgetTOSLinkChange={this.handleTosLinkChange}
        />
      </div>
    );
  }
}

export default FinalizeSurvey;
