import React from 'react';
import { Container, Row, Col, FormControl, FormGroup, Modal, Button }  from 'react-bootstrap';
import { Edit, Settings } from '@mui/icons-material';
import { IconButton, TextField, Popover, Paper } from '@mui/material';
import classes from '../css/modules/Survey.module.css';
import "../css/swytchback-glyphicons.css";
import "../css/swytchbackStyles.css";
import Event from "../classes/Event";
import Wysh from "../classes/Wysh";
import WyshMeProduct from "../classes/WyshMeProduct";
// import GetSurveyLinkModal from "../components/admin/GetSurveyLinkModal"
import GetSurveyLinkModal from "../hooks/admin/swydget/GetSurveyLinkModal";
import ExportSwydgetModal from "../hooks/admin/ExportSwydgetModal";
import meStore from "../stores/MeStore";
import loginStore from "../stores/LoginStore";
import MeService from '../services/MeService';
import FinalizeSurvey from './FinalizeSurvey';
import WyshTable from "../components/WyshTable";
import { useNavigate, useParams } from 'react-router';
import DeleteSwydgetModal from '../hooks/admin/swydget/DeleteSwydgetModal';
import ResolveConflictModal from '../hooks/admin/swydget/ResolveConflictModal';
import SwytchbackButton from '../hooks/admin/controls/SwytchbackButton';


class Survey extends React.Component {

  constructor() {
    super();
    this.user = loginStore.getLoggedInUser();
    this.eventId = "";
    this.inviteesToAdd = [];
    this.logout = this.logout.bind(this);
    // this.setWorkspace = this.setWorkspace.bind(this);
    this.addRewardCLicked = this.addRewardCLicked.bind(this);
    this.eventLoaded = this.eventLoaded.bind(this);
    this.productUploading = this.productUploading.bind(this);
    this.productLibraryLoaded = this.productLibraryLoaded.bind(this);
    this.addBlock = this.addBlock.bind(this);
    this.handlePublishClicked = this.handlePublishClicked.bind(this);
    this.handleDeleteSwydgetClicked = this.handleDeleteSwydgetClicked.bind(this);
    this.handleCustomizedClicked = this.handleCustomizedClicked.bind(this);
    this.handleContentClicked = this.handleContentClicked.bind(this);
    this.handleRandomizeClicked = this.handleRandomizeClicked.bind(this);
    this.handleOrderedClicked = this.handleOrderedClicked.bind(this);
    this.handleCloneSwydgetClicked = this.handleCloneSwydgetClicked.bind(this);
    this.publishSurvey = this.publishSurvey.bind(this);
    this.surveyPublished = this.surveyPublished.bind(this);
    this.surveyInvitationSent = this.surveyInvitationSent.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleSurveyInviteesSet = this.handleSurveyInviteesSet.bind(this);
    this.handleRewardTextChange = this.handleRewardTextChange.bind(this);
    this.handleRewardUrlChange = this.handleRewardUrlChange.bind(this);
    this.handleSurveyNameChange = this.handleSurveyNameChange.bind(this);
    this.handleNewBlockCardTextChange = this.handleNewBlockCardTextChange.bind(this);
    this.handleNewBlockPromptChange = this.handleNewBlockPromptChange.bind(this);
    this.toggleEditSurveyNameDisabled = this.toggleEditSurveyNameDisabled.bind(this);
    this.eventUpdated = this.eventUpdated.bind(this);
    this.cloneSwydgetStarted = this.cloneSwydgetStarted.bind(this);
    this.cloneSwydgetSuccessful = this.cloneSwydgetSuccessful.bind(this);
    this.addNewFriendInvitee = this.addNewFriendInvitee.bind(this);
    this.inviteSurveyTakers = this.inviteSurveyTakers.bind(this);
    this.openComingSoonModal = this.openComingSoonModal.bind(this);
    this.closeComingSoonModal = this.closeComingSoonModal.bind(this);
    this.openCloneSwydgetModal = this.openCloneSwydgetModal.bind(this);
    this.closeCloneSwydgetModal = this.closeCloneSwydgetModal.bind(this);
    this.openAddRewardModal = this.openAddRewardModal.bind(this);
    this.closeAddRewardModal = this.closeAddRewardModal.bind(this);
    this.openSurveyLinkModal = this.openSurveyLinkModal.bind(this);
    this.closeSurveyLinkModal = this.closeSurveyLinkModal.bind(this);
    this.openDeleteSwydgetModal = this.openDeleteSwydgetModal.bind(this);
    this.closeDeleteSwydgetModal = this.closeDeleteSwydgetModal.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.addSurveyTakersHandler = this.addSurveyTakersHandler.bind(this);
    this.wyshUpdated = this.wyshUpdated.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.resetToDefaultScalarOptions = this.resetToDefaultScalarOptions.bind(this);
    this.updateWyshes = this.updateWyshes.bind(this);
    this.previewSurvey = this.previewSurvey.bind(this);


    // Set up Draft Swydget state
    var workspace = "customize";

    // if (localStorage.getItem("draft-workspace")) {
    //   workspace = localStorage.getItem("draft-workspace");
    // }

    this.orderedWyshes = [];

    this.state = {
      myProducts: meStore.getMyProducts(),
      workspace: workspace,
      isLoading: false,
      isUpdating: false,
      eventInFocus: new Event(),
      editSurveyNameDisabled: true,
      newProductName: "",
      newProductPrice: "",
      newProductDescription: "",
      newProductLink: "",
      newProductAttributes: {},
      newProductAttribute: "",
      selectedProductIndex: -1,
      isPublished: false,
      showModal: false,
      imageFiles: [],
      surveyTakerDict: {},
      showComingSoonModal: false,
      showAddRewardModal: false,
      showSurveyLinkModal: false,
      showCloneSwydgetModal: false,
      showDeleteSwydgetModal: false,
      showExportJsonModal: false,
      showSettingsModal: false,
      anchorEl: null,
      cloneSwydgetMessage: '',
      rewardText: "",
      rewardUrl: "",
      scalarOptions: [],
      mode: "customize",
      newBlockCardText: "",
      newBlockPrompt: "",
      isSwydgetVerified: true
    };

    this.modalTitle = "Title";
    this.modalMessage = "Message";
  }

  componentDidMount() {
    this.eventId = this.props.params.eventId;
    MeService.getEvent(this.user.userId, this.user.password, this.eventId);
    // right here, we want to check to see if we have products from the library. If not,
    // send a request.
    MeService.getProducts(this.user, this.user.password);
  }

  componentWillMount() {
    loginStore.on("change-logout", this.logout);
    meStore.on("change-eventLoaded", this.eventLoaded);
    meStore.on("change-wyshesUpdated", this.eventLoaded);
    meStore.on("change-productUploading", this.productUploading);
    meStore.on("change-productLibraryLoaded", this.productLibraryLoaded);
    meStore.on("change-wyshUpdated", this.wyshUpdated);
    meStore.on("change-surveyPublished", this.surveyPublished);
    meStore.on("change-surveyInvitationSent", this.surveyInvitationSent);
    meStore.on("change-surveyInviteesSet", this.handleSurveyInviteesSet);
    meStore.on("change-eventUpdated", this.eventUpdated);
    meStore.on("change-cloneSwydgetStarted", this.cloneSwydgetStarted);
    meStore.on("change-cloneSwydgetSuccessful", this.cloneSwydgetSuccessful);
  }

  componentWillUnmount() {
    loginStore.removeListener("change-logout", this.logout)
    meStore.removeListener("change-eventLoaded", this.eventLoaded);
    meStore.removeListener("change-wyshesUpdated", this.eventLoaded);
    meStore.removeListener("change-productUploading", this.productUploading);
    meStore.removeListener("change-productLibraryLoaded", this.productLibraryLoaded);
    meStore.removeListener("change-wyshUpdated", this.wyshUpdated);
    meStore.removeListener("change-surveyPublished", this.surveyPublished);
    meStore.removeListener("change-surveyInvitationSent", this.surveyInvitationSent);
    meStore.removeListener("change-surveyInviteesSet", this.handleSurveyInviteesSet);
    meStore.removeListener("change-eventUpdated", this.eventUpdated);
    meStore.removeListener("change-cloneSwydgetStarted", this.cloneSwydgetStarted);
    meStore.removeListener("change-cloneSwydgetSuccessful", this.cloneSwydgetSuccessful);
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  handleNameChange(event) {
    this.setState({newProductName: event.target.value});
  }

  handlePriceChange(event) {
    this.setState({newProductPrice: event.target.value});
  }

  handleDescriptionChange(event) {
    this.setState({newProductDescription: event.target.value});
  }

  handleLinkChange(event) {
    this.setState({newProductLink: event.target.value});
  }

  handleRewardTextChange(event) {
    this.setState({rewardText: event.target.value});
  }

  handleRewardUrlChange(event) {
    this.setState({rewardUrl: event.target.value});
  }

  handleSurveyNameChange(event) {
    var evt = this.state.eventInFocus;
    evt.surveyName = event.target.value;
    this.setState({eventInFocus: evt});
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleNewBlockCardTextChange(evt) {
    this.setState({ newBlockCardText: evt.target.value});
  }

  handleNewBlockPromptChange(evt) {
    this.setState({ newBlockPrompt: evt.target.value});
  }

  handleRandomizeClicked(event) {
    var evt = this.state.eventInFocus;
    evt.randomize = true;
    this.setState({eventInFocus: evt});
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleOrderedClicked(event) {
    var evt = this.state.eventInFocus;
    evt.randomize = false;
    this.setState({eventInFocus: evt});
    MeService.updateEvent(this.user,this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleCloneSwydgetClicked() {
    this.openCloneSwydgetModal();
    MeService.cloneSwydget(this.user, this.state.eventInFocus);
  }

  handlePublishClicked() {
    if (this.state.eventInFocus.published === true) {
      this.inviteSurveyTakers();
    }
    else {
      this.publishSurvey();
    }
  }

  handleDeleteSwydgetClicked() {
    this.closeDeleteSwydgetModal();
    MeService.deleteSwydget(this.user, this.props.navigationHandler, this.state.eventInFocus);
  }

  handleCustomizedClicked(workspace) {
    this.setState({mode: "customize", workspace: workspace})
  }

  handleContentClicked(workspace) {
    this.setState({mode: "content", workspace: workspace});
  }

  toggleEditSurveyNameDisabled() {
    if (this.state.editSurveyNameDisabled === true) {
      this.setState({editSurveyNameDisabled: false});
    }
    else {
      this.setState({editSurveyNameDisabled: true});
    }
  }

  eventUpdated() {
    this.closeAddRewardModal();
  }

  logout() {
    this.user = null;
  }

  // setWorkspace(workspace) {
  //   localStorage.setItem("draft-workspace", workspace);
  //   this.setState({ workspace: workspace });
  // }

  getButtonStyleName(workspace) {

    if (workspace === this.state.workspace) {
      return "swytchbackActiveButton";
    }
    else {
      return "swytchbackInactiveButton";
    }
  }

  clearImage() {
    this.setState({
      newProductName: "",
      newProductPrice: "",
      newProductLink: "",
      newProductDescription: "",
      imageFiles: []
    });
  }

  openCloneSwydgetModal() {
    this.setState({ cloneSwydgetMessage: "Cloning Swydget. This could take a minute...", showCloneSwydgetModal: true });
  }

  closeCloneSwydgetModal() {
    this.setState({ cloneSwydgetMessage: "", showCloneSwydgetModal: false });
  }

  cloneSwydgetStarted() {
    this.openCloneSwydgetModal();
  }

  cloneSwydgetSuccessful() {
    this.setState({cloneSwydgetMessage: "Clone complete!"});
  }

  openDeleteSwydgetModal(index) {
    this.setState({ showDeleteSwydgetModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeDeleteSwydgetModal() {
    this.setState({ showDeleteSwydgetModal: false });
  }

  updateWyshes(wysh, mutatedWyshes) {

    var mutWyshes = Wysh.removeDuplicates(mutatedWyshes);

    var updatedWyshes = wysh.event.wyshes;
    var e = this.state.eventInFocus;
    e.orderedWyshList.setWyshes(updatedWyshes);
    this.setState({eventInFocus: e});
    MeService.updateWyshes(this.user, this.state.eventInFocus, mutWyshes);
  }

  wyshUpdated() {
    this.binaryIconSetID = null;

    this.setState({
      isUpating: false,
      binaryIconSetID: null
    });

  }

  addBlock() {
    MeService.createBlock(this.user,
                            this.state.eventInFocus,
                            this.state.newBlockCardText,
                            this.state.newBlockPrompt);
  }

  addRewardCLicked(e) {
    e.preventDefault();
    // Call the api
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.eventInFocus.contestPrizeText);
  }



  openComingSoonModal() {
    this.modalTitle = "Coming Soon";
    this.modalMessage = "You will be able to specify a reward for this survey."
    this.setState({ showComingSoonModal: true });
  }

  closeComingSoonModal() {
    this.setState({ showComingSoonModal: false });
  }

  openAddRewardModal() {
    this.setState({ showAddRewardModal: true });
  }

  closeAddRewardModal() {
    this.setState({ showAddRewardModal: false });
  }

  openSurveyLinkModal() {
    this.setState({ showSurveyLinkModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeSurveyLinkModal() {
    this.setState({ showSurveyLinkModal: false });
  }

  openExportJsonModal = () => {
    this.setState({ showExportJsonModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeExportJsonModal = () =>{
    this.setState({ showExportJsonModal: false });
  }

  handleSurveyInviteesSet() {
    console.log("Survey invitees set: F[" + meStore.friendsToInvite.length + "], N:[" + meStore.nonFriendsToInvite.length + "]");

    // To publish, the non-friend wyshme users and non-wyshme users need to be added to my address book, because it
    // needs to be submitted to the backend.

    // On iOS, we publish our friends as survey takers BEFORE the survey is published
    // Afterwards, the WyshMe users that are NOT our friends are invited as well as NON-WyshMe users.
    // - this requires the mutation of the address book to accomodate our new WysheMe friends AND NON-WyshMe friends

    // Tackle the NON-WyshMe users


  }

  previewSurvey() {
    window.open(this.state.eventInFocus.generateSwydgetUrl(), '');
  }

  inviteSurveyTakers() {
    for (var i = 0; i < meStore.friendsToInvite.length; i++) {
      this.addNewFriendInvitee(meStore.friendsToInvite[i]);
    }
    for (i = 0; i < meStore.nonFriendsToInvite.length; i++) {
      this.addNewFriendInvitee(meStore.nonFriendsToInvite[i]);
    }
    for (i = 0; i < meStore.friendsToInvite.length; i++) {
      MeService.inviteSurveyTakers(this.user, this.state.eventInFocus, meStore.friendsToInvite[i], meStore.friendsToInvite, meStore.nonFriendsToInvite);
    }
    for (i = 0; i < meStore.nonFriendsToInvite.length; i++) {
      MeService.inviteSurveyTakers(this.user, this.state.eventInFocus, meStore.nonFriendsToInvite[i], meStore.friendsToInvite, meStore.nonFriendsToInvite);
    }
  }

  /*
    addNewFriendInvitee
  */
  addNewFriendInvitee(newFriend) {
    this.user.addUserToMyAddressBook(newFriend, this.user.addressBookJson["next_cix"]);
  }

  eventLoaded() {

    this.orderedWyshes = meStore.getEventInFocus().getOrderedWyshes();

    this.setState({
      eventInFocus: meStore.getEventInFocus(),
      isLoading: false,
      isUpating: false,
      newProductName: "",
      newProductPrice: "",
      newProductDescription: "",
      newProductLink: "",
      rewardText: meStore.getEventInFocus().rewardText,
      rewardUrl: meStore.getEventInFocus().rewardUrl,
      imageFiles: [],
      scalarOptions: meStore.getEventInFocus().options,
      isSwydgetVerified: meStore.getEventInFocus().orderedWyshList.verify()
    });
  }

  productUploading() {
    this.setState({
      isLoading: true
    });
  }

  productLibraryLoaded() {
    this.setState({
      myProducts: meStore.getMyProducts()
    });
  }

  publishSurvey() {

    if (this.orderedWyshes === null) {
      this.props.navigationHandler("/");
    }
    if (this.state.eventInFocus.isEligibleForPublishing() === false) {
      this.modalTitle = "Insufficient Stimuli";
      this.modalMessage = "The Swydget must have content and all Max Diff Blocks must have at least 8 stimuli.";
      this.setState({ showComingSoonModal: true });
    }
    else {
      MeService.publishSurvey(this.user, this.state.eventInFocus, meStore.friendsToInvite, meStore.nonFriendsToInvite);
    }
  }

  surveyPublished() {

    var e = this.state.eventInFocus
    e.published = true

    this.setState({
      eventInFocus: e,
      isPublished: true
    });

    // this.props.navigationHandler("/create_survey/publish_survey/" + this.state.eventInFocus.eventId);
    this.props.navigationHandler("/results/" + this.state.eventInFocus.eventId);
  }

  surveyInvitationSent() {
    this.props.navigationHandler("/results/" + this.state.eventInFocus.eventId);
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      imageFiles: acceptedFiles
    });
  }

  uploadProgress() {

  }

  addSurveyTakersHandler(userDict) {
    this.setState({surveyTakerDict: userDict});
  }

  generateModal() {
    return (
      <Modal show={this.state.showComingSoonModal} onHide={this.closeComingSoonModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
            {this.modalMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="swytchbackActiveButton" onClick={this.closeComingSoonModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  shouldUploadBeDisabled() {
    if (this.state.imageFiles.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  shouldRemoveContentBeDisabled() {
    // if (this.state.imageFiles.length > 0) {
    //   return false;
    // }
    // else {
      return false;
    // }
  }

  shouldAddRewardBeDisabled() {
    if (this.state.rewardText === '' && this.state.rewardUrl === '') {
      return true;
    }
    else {
      return false;
    }
  }

  generateProductPriceLabel() {
    if (this.state.newProductPrice > 0) {
      return "$" + this.state.newProductPrice;
    }
  };

  resetToDefaultScalarOptions() {
    var newOptionsArray = [];
    for (var i = 0; i < this.state.eventInFocus.options.length; i++) {
      newOptionsArray.push(this.state.eventInFocus.options[i]);
    }
    this.setState({scalarOptions: newOptionsArray});
  }

  removeOption(index) {

    var newOptionsArray = [];
    for (var i = 0; i < this.state.scalarOptions.length; i++) {
      if (i !== index) {
        newOptionsArray.push(this.state.scalarOptions[i]);
      }
    }

    this.setState({scalarOptions: newOptionsArray});
  }

  _generateWyshTable(wyshes, swydget) {

    var key = "root"
    var blockPrompt = swydget.surveyName;
    var blockCardText = "";
    var parentWysh = null;

    let rightWidgetContainerStyle = {
      padding: 5,
      marginTop: 20
    };

    var wt = <WyshTable
      myProducts={this.state.myProducts}
      title={blockPrompt}
      parentWysh={parentWysh}
      eventInFocus={swydget}
      orderedWyshList={swydget.orderedWyshList}
      onHandleFreeResponseQuestionChange={this.onHandleFreeResponseQuestionChange}
      surveyTitle={swydget.title}
      mode="draft"
      blockCardText={blockCardText}
      blockPrompt={blockPrompt}/>;

    if (parentWysh) {
      // parentWysh.wyshTable = wt;
    }

    return (
          <div key={key}>
            {wt}
          </div>
    );
  };

  generateContentViewRow(wyshes) {

    return (
      <div>
        {this._generateWyshTable(wyshes, this.state.eventInFocus)}
      </div>
    );

  }

  isSwydgetReadOnly() {
    if (typeof this.state.eventInFocus !== undefined && this.state.eventInFocus !== null) {
      if (this.user.userId === this.state.eventInFocus.creator.userId) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true
    }
  }

  onHandleFreeResponseQuestionChange = (evt, wysh) => {

    var eif = this.state.eventInFocus;
    var w = eif.findWysh(wysh.wyshId);
    w.freeResponseQuestions[0] = evt.target.value

    this.setState({ eventInFocus: eif });
  }

  verifySwydgetIntegrity = (evt) => {

    var eif = this.state.eventInFocus;
    if (eif.orderedWyshList.verify() === false) {
      eif.orderedWyshList.repair();
      MeService.updateWyshes(this.user, eif, eif.wyshes);
    }
  }

  render() {

    var isLoading = this.state.isLoading;
    var question = "";
    var freeResponseQuestion = "";
    var binaryIconSetID = "";

    if (this.state.selectedProductIndex > -1 && this.orderedWyshes[this.state.selectedProductIndex]) {
      question = this.orderedWyshes[this.state.selectedProductIndex].caption;
      let freeResponseQuestionArray = this.orderedWyshes[this.state.selectedProductIndex].freeResponseQuestions;
      if (freeResponseQuestionArray.length > 0) {
        freeResponseQuestion = freeResponseQuestionArray[0];
      }

      binaryIconSetID = this.orderedWyshes[this.state.selectedProductIndex].binaryIconSetID;
    }
    else {
      binaryIconSetID = this.state.eventInFocus.binaryIconSetID
    }

    const product = ((this.orderedWyshes.length > 0) && (this.state.selectedProductIndex > -1) && (this.state.selectedProductIndex !== this.orderedWyshes.length)) ? this.orderedWyshes[this.state.selectedProductIndex].product : new WyshMeProduct();

    let headerRowStyle = {
      borderBottom: 2,
      borderBottomColor: '#1994fc',
      borderBottomStyle: 'solid'
    };

    let titleStyle = {
      color: '#53585f',
      textAlign: 'left',
      fontSize: 20,
      fontWeight: 100
    };

    var buttonStyle = {
      marginLeft: 5,
      marginRight: 5
    };

    let toolbarStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10
    }

    let leftWidgetContainerStyle = {
      padding: 5,
      marginTop: 20
    }

    let inputStyle = {
      height: 50,
      backgroundColor: '#f1f2f6',
      border: 2,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      fontWeight: 300,
      fontSize: 18
    };

    let addContentFormDivStyle = {
      width: '90%',
      marginTop: 40,
      marginBottom: 40
    };

    let toolbarGlyphStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10,
      margin: 0
    };

    var topButtonStyle = {
      color: '#53585f',
      border: 0,
      fontSize: 30,
      backgroundColor: '#ffffff',
      padding: 0,
      margin: 0,
    };

    /*
    const moreFunctionsPopover = (
      <Popover id="popover-trigger-click" title="Advanced">
        <SwytchbackButton style={{width: '100%', marginBottom: 6}} onClick={this.openSurveyLinkModal}>
          Link
        </Button>
        { this.isSwydgetReadOnly() === false &&
          <SwytchbackButton style={{width: '100%', marginBottom: 6}} onClick={this.handleCloneSwydgetClicked}>
            Clone
          </Button>
        }
        { this.isSwydgetReadOnly() === false  &&
          <Button className="swytchbackAlertButton" style={{width: '100%', marginBottom: 6}}   onClick={this.openDeleteSwydgetModal}>
            Delete
          </Button>
        }
      </Popover>
    );
    */

    if (this.state.eventInFocus.eventId === "") {
      return (
        <div>
          <div>
            <Container>
              <Row style={headerRowStyle}>
                <Col md={6} style={toolbarStyle}>
                  <div style={titleStyle}>Loading...</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
    else if (this.state.eventInFocus.isPermitted(this.user) === true) {
      return (
        <div>
          <div>
            <Container>
              <Row style={headerRowStyle}>
                <Col md={9} style={toolbarStyle}>
                  <form className={`${classes.container}`} noValidate autoComplete="off">
                    <TextField
                      disabled={this.state.editSurveyNameDisabled}
                      id="surveyName"
                      value={this.state.eventInFocus.surveyName}
                      onChange={this.handleSurveyNameChange}
                      margin="normal"
                      variant="standard"
                      sx={{
                        '& .MuiInputBase-root': {
                          // Apply styles to the root element of the input
                          backgroundColor: 'transparent'
                        },
                        '& .MuiInputBase-input': {
                          // Apply styles to the input element
                          color: '#53585f',
                          textAlign: 'left',
                          fontSize: 24,
                          fontWeight: 100
                        }
                      }}
                    />
                  </form>
                  <div className={`${classes.iconButtonContainer}`}>
                    <IconButton className={`${classes.spaced}`} onClick={this.toggleEditSurveyNameDisabled} >
                      <Edit className={`${classes.icon}`} />
                    </IconButton>
                  </div>
                </Col>
                { this.state.workspace === "customize" ?
                <React.Fragment>
                  <Col md={1} style={toolbarStyle}>
                    <SwytchbackButton style={{width: '100%', marginLeft: 5, marginRight: 5}} onClick={this.previewSurvey}>Preview</SwytchbackButton>
                  </Col>
                  <Col md={1} style={toolbarStyle}>
                    <SwytchbackButton 
                      bordercolor="#0059ff"
                      backgroundcolor="#0059ff"
                      textcolor="#ffffff"
                      hoverbackgoundcolor="#1579fb"
                      hoverbordercolor="#0059ff"
                      style={{width: '100%', marginLeft: 5, marginRight: 5}} 
                      onClick={this.handlePublishClicked}
                    >
                      Publish
                    </SwytchbackButton>
                  </Col>
                  <Col md={1} style={toolbarGlyphStyle}>
                    <SwytchbackButton
                      onClick={
                        (evt) => {
                          this.setState({
                            showSettingsModal: true,
                            anchorEl: evt.currentTarget
                          });
                        }
                      }
                      style={{width: '100%'}}
                    >
                      Survey Menu
                    </SwytchbackButton>
                    <Popover 
                      id={"survey-advanced-popover"} 
                      open={this.state.showSettingsModal} 
                      anchorEl={this.state.anchorEl}
                      onClose={() => {
                        this.setState({showSettingsModal: false});
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      slotProps={{
                        paper: {
                          style: {
                            borderRadius: 5, // Adjust the radius to your preference
                          },
                        },
                      }}
                    >   
                      <Paper sx={{ backgroundColor: '#ffffff', width: 300 }}>
                        <div className={`${classes.title}`}>Advanced</div>
                        <div className={`${classes.buttonContainerStyle}`}>
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openSurveyLinkModal}>
                            Survey Link(s)
                          </SwytchbackButton>
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openExportJsonModal}>
                            Survey Doc (JSON)
                          </SwytchbackButton>
                          { false && this.isSwydgetReadOnly() === false &&
                            <SwytchbackButton style={{width: '100%', marginBottom: 6}} onClick={this.handleCloneSwydgetClicked}>
                              Clone
                            </SwytchbackButton>
                          }
                          { this.isSwydgetReadOnly() === false  &&
                            <SwytchbackButton 
                              backgroundcolor="#ff0000"
                              hoverbackgoundcolor="#eb4646"
                              textcolor="#ffffff"
                              style={{width: '100%', marginBottom: 6}}   
                              onClick={this.openDeleteSwydgetModal}>
                              Delete
                            </SwytchbackButton>
                          }
                        </div>
                      </Paper>
                    </Popover>
                  </Col>
                </React.Fragment>
                :
                <React.Fragment>
                  <Col md={1} style={toolbarStyle}>
                    
                  </Col>
                  <Col md={2} style={toolbarStyle}>
                    <Button className={this.getButtonStyleName("build") + " fullWidth"} style={buttonStyle} onClick={() => this.handleCustomizedClicked("customize")}>Done</Button>
                  </Col>
                </React.Fragment>
                }

              </Row>
              { this.state.mode === 'content' ?
                this.generateContentViewRow(this.orderedWyshes)
                :

                <Row>
                  <Col md={12} style={leftWidgetContainerStyle}>
                    <FinalizeSurvey eventId={this.state.eventInFocus.eventId} navToBuildHandler={() => this.handleContentClicked("build")}/>
                  </Col>
                </Row>
              }

            </Container>
          </div>

          {this.generateModal()}
          <Modal show={this.state.showCloneSwydgetModal} onHide={this.closeCloneSwydgetModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Cloning Swydget...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.cloneSwydgetMessage}
            </Modal.Body>
            <Modal.Footer>
              <Button className="swytchbackActiveButton" style={{width: '50%'}} onClick={this.closeCloneSwydgetModal}>Done</Button>
            </Modal.Footer>
          </Modal>

          <GetSurveyLinkModal showModal={this.state.showSurveyLinkModal} onHideAction={this.closeSurveyLinkModal} eventInFocus={this.state.eventInFocus}/>
          <ExportSwydgetModal
            swydget={this.state.eventInFocus}
            showModal={this.state.showExportJsonModal}
            onHideAction={this.closeExportJsonModal}
          />

          <DeleteSwydgetModal
            showDeleteSwydgetModal={this.state.showDeleteSwydgetModal}
            closeDeleteSwydgetModal={this.closeDeleteSwydgetModal}
            swydgetName={this.state.eventInFocus.surveyName}
            handleDeleteSwydgetClicked={this.handleDeleteSwydgetClicked}
          />
          <ResolveConflictModal
            show={!this.state.isSwydgetVerified}
            swydget={this.state.eventInFocus}
            resolveConflict={this.verifySwydgetIntegrity}
          />  
          

          <Modal show={this.state.showAddRewardModal} onHide={this.closeAddRewardModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Add Reward</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={addContentFormDivStyle}>
                <FieldGroup
                  style={inputStyle}
                  id="rewardText"
                  value={this.state.rewardText}
                  type="text"
                  placeholder="Reward Text (Optional)..."
                  onChange={this.handleRewardTextChange}
                />
                <FieldGroup
                  style={inputStyle}
                  id="rewardUrl"
                  value={this.state.rewardUrl}
                  type="text"
                  placeholder="Link to Reward from Reward System (Optional)..."
                  onChange={this.handleRewardUrlChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeAddRewardModal}>Cancel</Button>
              <Button bsStyle="primary" disabled={this.shouldAddRewardBeDisabled() || isLoading} onClick={this.addRewardCLicked}>
                {isLoading ? 'Loading...' : 'Add'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
    else {
      return (
        <div>
          <div>
            <Container>
              <Row style={headerRowStyle}>
                <Col md={6} style={toolbarStyle}>
                  <div style={titleStyle}>Not Authorized</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }
}

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup controlId={id}>
      <FormControl {...props} />
    </FormGroup>
  );
}

const SurveyWrapper = () => {

  const navigate = useNavigate();
  const params = useParams();
  
  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <Survey navigationHandler={handleNavigation} params={params} />
}

export default SurveyWrapper;
