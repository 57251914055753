import React from 'react';
import { Select, MenuItem } from '@mui/material';
import Dropzone from 'react-dropzone';
import { Tabs, Tab, Button, Form } from 'react-bootstrap';
import { TwitterPicker } from 'react-color';
import classes from "../../css/modules/CardEditor.module.css";
import StimulusQuantitativeComponent from '../../hooks/admin/stimuli/StimulusQuantitativeComponent';
import StimulusVerbatimComponent from '../../hooks/admin/stimuli/StimulusVerbatimComponent';
import StimulusAttributesComponent from '../../hooks/admin/stimuli/StimulusAttributesComponent';
import StimulusBranchLogicManagerComponent from '../../hooks/admin/stimuli/StimulusBranchLogicManagerComponent';
import "../../css/swytchbackModal.css";
import swytchbackDefaultWallpaper from '../../SwytchbackDefaultWallpaper-1200.png';
import SwytchbackTextField from '../../hooks/admin/controls/SwytchbackTextField';
import SwytchbackTextArea from '../../hooks/admin/controls/SwytchbackTextArea';




class CardEditor extends React.Component {

  constructor(props) {
    super();
  }

  handleChangeComplete = (color) => {
    this.setState({ textColor: color.hex });
  };

  getPreviewCard = (stimulus, imageFiles, dropzoneStyle, imagePreviewStyle, cardHeightStyle, cardWidthStyle, imageStyle, cardHeight) => {

    if (imageFiles.length > 0) {
      return (
        <div style={dropzoneStyle}>
          <div style={imagePreviewStyle}>
            <div style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              height: cardHeightStyle,
              width: cardWidthStyle,
              background: 'linear-gradient(#0167EC, #00B6F9)',
              overflow: 'hidden'
            }}>
              {imageFiles.map(
                (file) => {
                  const previewUrl = URL.createObjectURL(file);
                  return <img key={0} alt='' src={previewUrl} style={imageStyle} />
                }
              )}
              {CardEditor.toMarkup(stimulus.product, cardHeight, this.props.sampleCardText)}
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div style={dropzoneStyle}>
          <div style={imagePreviewStyle}>
            <div style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              height: cardHeightStyle,
              width: cardWidthStyle,
              background: 'linear-gradient(#0167EC, #00B6F9)',
              overflow: 'hidden'
            }}>
              {(stimulus.getStimuliImageUrl() && stimulus.getStimuliImageUrl() !== "") &&
                <img src={stimulus.getStimuliImageUrl()} style={imageStyle} alt='edit content' />
              }
              {CardEditor.toMarkup(product, cardHeight, this.props.sampleCardText)}
            </div>
          </div>
        </div>
      );
    }
  }

  static toMarkup(product, cardHeight, sampleCardText = null) {

    var cardWidth = cardHeight * (2.5 / 3.5); // standard card width
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';

    let textBoxContainerStyle = {
      margin: 0,
      padding: product.styles.determinePadding(cardHeight),
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: product.styles.verticalAlign,
      flexDirection: 'column',
      textAlign: product.styles.justification,
      top: 0,
      left: 0,
      width: cardWidthStyle,
      height: cardHeightStyle,
      color: product.styles.textColor,
      fontSize: product.styles.determineFontSize(cardHeight),
      fontFamily: product.styles.getFontFamily(),
      fontWeight: product.styles.getFontWeight(),
      fontStyle: product.styles.getFontStyle(),
      lineHeight: product.styles.determineLineHeight(cardHeight),
    };


    let paragraphDivs = product.toParagraphsArray().map((phrase, idx) => {
      if (idx === 0) {
        return (
          <div key={idx}>{phrase}</div>
        )
      }
      else {
        return (
          <div style={{ marginTop: product.styles.determineParagraphSpacing(cardHeight) }} key={idx}>{phrase}</div>
        )
      }
    });

    // here is the deal if there is ANYTHING, just do that. If there is nothing and there is not defaultText, do nothing
    // otherwise, display the default text

    if (paragraphDivs.length === 0 && sampleCardText) {
      return (
        <div style={textBoxContainerStyle}>
          <div style={{ width: '100%', textShadow: product.styles.getTextShadow() }}>
            {sampleCardText}
          </div>
        </div>
      );
    }
    else {
      return (
        <div style={textBoxContainerStyle}>
          <div style={{ width: '100%', textShadow: product.styles.getTextShadow() }}>
            {paragraphDivs}
          </div>
        </div>
      );
    }
  }

  getCardHeight = () => {
    var h = window.innerHeight;
    var w = window.innerWidth;
    return h >= w ? 20 : 30;
  }

  render() {

    const { readOnly,
      swydget,
      stimulus,
      imageFiles,
      onDropMethod,
      handleNameChange,
      handleProductLinkChange,
      handleDescriptionChangeMethod,
      handleThemeRadioBoxChangeMethod,
      handleJustificationChangeMethod,
      handleVerticalAlignChangeMethod,
      handleFontSizeChangeMethod,
      handleColorPickerChangeMethod,
      handleFontFamilyChangeMethod,
      handleIsBoldChangeMethod,
      handleIsItalicizedChangeMethod,
      updateStimulusTypeCallback,
      updateStimulusVerbatimCallback,
      updateStimulusProductAttributesCallback,
      updateStimulusBranchLogicCallback,
      handleObjectFitChangeMethod,
      resetImageMethod } = this.props;

    var cardHeight = this.getCardHeight();
    var cardWidth = cardHeight * (2.5 / 3.5); // standard card width
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';
    var textColor = "#000000";

    var cardLabel = (readOnly === true) ? "Card Preview" : "Add Imagery";
    var imageInstructionLabel = (readOnly === true) ? "" : "click card above to select imagery";

    let dropzoneStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: cardWidthStyle,
      height: cardHeightStyle,
      textColorColor: '#ffffff',
      color: '#000000',
      borderRadius: '5%',
      padding: 0,
      marginTop: 0,
      marginBottom: 10,
      marginLeft: 40,
      marginRight: 40,
      // WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      // MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      // boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let dropzoneActiveStyle = {
      borderStyle: 'solid',
      backgroundColor: '#eee'
    };

    const dzStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 100,
      fontSize: 16,
      fontWeight: 900
    };

    const dzLabelStyle = {

    }

    const activeDzStyle = {


    }

    let imageDropStyle = {
      width: '15vh',
      height: '15vh',
      backgroundColor: '#ffffff',
      color: '#000000',
      borderRadius: '5%',
      padding: 0,
      margin: 0,
      overflow: 'hidden'
    };

    let imagePreviewStyle = {
      width: 'auto',
      height: cardHeightStyle,
      backgroundColor: '#ffffff',
      color: '#000000',
      borderRadius: '5%',
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      overflow: 'hidden'
    };

    let imageStyle = {
      width: cardWidthStyle,
      height: cardHeightStyle,
      objectFit: stimulus.product.styles.objectFit
    };

    let textBoxContainerStyle = {
      color: stimulus.product.styles.textColor,
      fontFamily: stimulus.product.styles.getFontFamily(),
      margin: 0,
      padding: '1.5vw',
      position: 'absolute',
      display: 'flex',
      justifyContent: stimulus.product.styles.verticalAlign,
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: stimulus.product.styles.justification,
      fontWeight: stimulus.product.styles.getFontWeight(),
      fontStyle: stimulus.product.styles.getFontStyle(),
      top: 0,
      left: 0,
      width: cardWidthStyle,
      height: cardHeightStyle,
      fontSize: stimulus.product.styles.determineFontSize(cardHeight)
    };

    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (this.props.swydget && this.props.swydget.wallpaperUrl !== '') {
      bgImgUrl = this.props.swydget.getWallpaperUrl();
      bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }

    const cardPreviewStyle = {
      background: bgAttributeValue,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    };

    return (
      <div id="cardPreviewDiv" className="rowContentContainer">
        <div className="leftPaneContainer" style={cardPreviewStyle}>
          <div style={{height: 54, width: '100%', backgroundColor: '#dee1e6'}}>
            
          </div>
          <div className="columnCenteredContainer fullWidth standardModalHeight leftPane">
            <div className='topThird'>
              <div className={`${classes.previewTitle}`}>
                {stimulus.caption}
              </div>
            </div>
            <div className='bottomTwoThirds'>
            {imageFiles.length > 0 || readOnly === true ?
              this.getPreviewCard(stimulus, imageFiles, dropzoneStyle, imagePreviewStyle, cardHeightStyle, cardWidthStyle, imageStyle, cardHeight)
              :
              <div style={imagePreviewStyle}>
                <div style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexDirection: 'column',
                  height: cardHeightStyle,
                  width: cardWidthStyle,
                  background: 'linear-gradient(#0167EC, #00B6F9)',
                  overflow: 'hidden'
                }}>
                  {stimulus.getStimuliImageUrl(swydget) && stimulus.getStimuliImageUrl(swydget) !== "" && 
                    <img src={stimulus.getStimuliImageUrl(swydget)} style={imageStyle} alt='edit content' />
                  }
                  {CardEditor.toMarkup(stimulus.product, cardHeight, this.props.sampleCardText)}
                </div>
              </div>
            }
            </div>
          </div>  
        </div>
        <div className="rightPaneContainer swytchbackGradientPane">
          <div className='rowContainer fullWidthPane'>
            <Tabs className="swytchbackModalTabs"
                    id="uncontrolled-tab-example"
                    defaultActiveKey={this.props.activeTabKey}
                    style={{width: '100%'}}
            >
              <Tab eventKey={1} title="Stimulus">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <div className='rowContainer fullWidthPane standardModalHeight'>
                      <div className='columnContainer halfPane'>
                        <div className="widget fullWidth">
                          <div className='content'>
                            <div className='gradientContainer'>
                              <SwytchbackTextArea 
                                onChange={handleDescriptionChangeMethod}
                                value={stimulus.product.description}
                              />
{/*                               
                              <SwytchbackTextField
                                disabled={readOnly}
                                id="productDescription"
                                className={`${classes.textField}`}
                                value={stimulus.product.description}
                                onChange={handleDescriptionChangeMethod}
                                margin="normal"
                                placeholder="Type a question here..."
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="widget fullWidth">
                          <div className='titleContainer'>
                            <div className='title'>
                              Font:
                            </div>  
                          </div>
                          <div className='content'>
                            <div className='rowContainerSpaced marginBottom'>
                              <Select
                                className="swytchbackSelect fullBorder"
                                style={{width: '63%'}}
                                disabled={false}
                                inputProps={{ IconComponent: () => null }}
                                id="font-family-select"
                                value={stimulus.product.styles.fontFamily}
                                onChange={(evt) => handleFontFamilyChangeMethod(evt)}
                              >
                                <MenuItem key={"arial"} className={`${classes.swytchbackMenuItem}`} value={"arial"}>Arial</MenuItem>
                                <MenuItem key={"courier"} className={`${classes.swytchbackMenuItem}`} value={"courier"}>Courier</MenuItem>
                                <MenuItem key={"helvetica"} className={`${classes.swytchbackMenuItem}`} value={"helvetica"}>Helvetica</MenuItem>
                                <MenuItem key={"sans-serif"} className={`${classes.swytchbackMenuItem}`} value={"sans-serif"}>Sans-Serif</MenuItem>
                                <MenuItem key={"times"} className={`${classes.swytchbackMenuItem}`} value={"times"}>Times</MenuItem>
                              </Select>
                              <Button
                                className={stimulus.product.styles.isBold ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                                style={{width: '35%', height: 58}}
                                active={stimulus.product.styles.isBold} 
                                onClick={() => handleIsBoldChangeMethod()}>
                                Bold
                              </Button>
                              
                            </div>
                            <div className='rowContainerSpaced marginBottom'>
                              <Select
                                className="swytchbackSelect fullBorder"
                                style={{width: '49%'}}
                                disabled={false}
                                inputProps={{ IconComponent: () => null }}
                                id="font-size-select"
                                value={stimulus.product.styles.fontSize}
                                onChange={(evt) => handleFontSizeChangeMethod(evt.target.value)}
                              >
                                <MenuItem key={"small"} className={`${classes.swytchbackMenuItem}`} value={"small"}>Smallest</MenuItem>
                                <MenuItem key={"medium"} className={`${classes.swytchbackMenuItem}`} value={"medium"}>Small</MenuItem>
                                <MenuItem key={"large"} className={`${classes.swytchbackMenuItem}`} value={"large"}>Medium</MenuItem>
                                <MenuItem key={"larger"} className={`${classes.swytchbackMenuItem}`} value={"larger"}>Large</MenuItem>
                                <MenuItem key={"largest"} className={`${classes.swytchbackMenuItem}`} value={"largest"}>Largest</MenuItem>
                              </Select>
                              <Select
                                className="swytchbackSelect fullBorder"
                                style={{width: '49%'}}
                                disabled={false}
                                id="shadow"
                                value={stimulus.product.styles.textShadow ? stimulus.product.styles.textShadow : "none"}
                                onChange={(evt) => handleThemeRadioBoxChangeMethod(evt)}
                              >
                                <MenuItem key={"arial"} className={`${classes.swytchbackMenuItem}`} value={"none"}>No Shadow</MenuItem>
                                <MenuItem key={"courier"} className={`${classes.swytchbackMenuItem}`} value={"light"}>Light</MenuItem>
                                <MenuItem key={"helvetica"} className={`${classes.swytchbackMenuItem}`} value={"dark"}>Dark</MenuItem>
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div className="widget fullWidth">
                          <div className='titleContainer'>
                            <div className='title'>
                              Alignment:
                            </div>
                          </div>  
                          <div className='content'>
                            <div className='rowContainer marginBottom'>
                              <Button 
                                className={stimulus.product.styles.isJustificationActive("left") ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                                style={{width: '33%'}}
                                active={stimulus.product.styles.isJustificationActive("left")} 
                                onClick={() => handleJustificationChangeMethod("left")}>
                                Left
                              </Button>
                              <Button 
                                className={stimulus.product.styles.isJustificationActive("center") ? "swytchbackButton swytchbackButtonActive middle" : "swytchbackButton middle"} 
                                style={{width: '33%'}}
                                active={stimulus.product.styles.isJustificationActive("center")} 
                                onClick={() => handleJustificationChangeMethod("center")}>
                                Center
                              </Button>
                              <Button 
                                className={stimulus.product.styles.isJustificationActive("right") ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                                style={{width: '33%'}}
                                active={stimulus.product.styles.isJustificationActive("right")} 
                                onClick={() => handleJustificationChangeMethod("right")}>
                                Right
                              </Button>
                            </div>
                            <div className='rowContainer fullWidthPane'>
                              <Button 
                                className={stimulus.product.styles.isVerticalAlignActive("flex-start") ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                                style={{width: '33%'}}
                                active={stimulus.product.styles.isVerticalAlignActive("flex-start")} 
                                onClick={() => handleVerticalAlignChangeMethod("flex-start")}>
                                Top
                              </Button>
                              <Button 
                                className={stimulus.product.styles.isVerticalAlignActive("center") ? "swytchbackButton swytchbackButtonActive middle" : "swytchbackButton middle"} 
                                style={{width: '33%'}}
                                active={stimulus.product.styles.isVerticalAlignActive("center")} 
                                onClick={() => handleVerticalAlignChangeMethod("center")}>
                                Middle
                              </Button>
                              <Button 
                                className={stimulus.product.styles.isVerticalAlignActive("flex-end") ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                                style={{width: '33%'}}
                                active={stimulus.product.styles.isVerticalAlignActive("flex-end")} 
                                onClick={() => handleVerticalAlignChangeMethod("flex-end")}>
                                Bottom
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="widget fullWidth">
                          <div className='titleContainer'>
                            <div className='title'>
                              Color:
                            </div>
                          </div>
                          <div className='content'>
                            <div className='rowContainer marginBottom'>    
                              <TwitterPicker 
                                width="100%"
                                triangle="hide"
                                color={stimulus.product.styles.textColor}
                                onChangeComplete={handleColorPickerChangeMethod}
                                colors={["#000000", "#666666", "#B3B3B3", "#FFFFFF", "#9F0500", "#FB9E00", "#FCDC00", "#68BC00", "#0C797D", "#0062b1"]}
                              />    
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='columnContainer halfPane'>
                        <div className="widget fullWidth">
                          <div className='content'>
                            <div className="gradientContainer">
                              <Dropzone
                                onDrop={onDropMethod}
                                onDropRejected={() => {console.log("drop rejected")}}
                                multiple={false}
                                accept={{
                                  'image/png': ['.png'],
                                  'image/jpg': ['.jpg', '.jpeg'],
                                  'image/gif': ['.gif']
                                }}
                              >
                                {({getRootProps, getInputProps}) => (
                                  <section className="swytchbackDropzoneContainer">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                      <input {...getInputProps()} />
                                      <div className="swytchbackDropzoneMessage">
                                        + Click here to add media
                                      </div>  
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                              

                            </div>  
                          </div>
                        </div>
                        <div className="widget fullWidth">
                          <div className='titleContainer'>
                            <div className='title'>
                              Display:
                            </div>
                          </div>
                          <div className='content'>
                            <div className='rowContainer marginBottom'>
                              <Button 
                                className={stimulus.product.styles.isObjectFitActive("cover") ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                                style={{width: '50%'}}
                                active={stimulus.product.styles.isObjectFitActive("cover")} 
                                onClick={() => handleObjectFitChangeMethod("cover")}>
                                Crop
                              </Button>
                              <Button 
                                className={stimulus.product.styles.isObjectFitActive("contain") ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                                style={{width: '50%', borderLeft: '1px solid white'}}
                                active={stimulus.product.styles.isObjectFitActive("contain")} 
                                onClick={() => handleObjectFitChangeMethod("contain")}>
                                Un-Crop
                              </Button>
                            </div>  
                            <div className='rowContainer'>
                              <Button 
                                className="swytchbackButton" 
                                disabled={false} 
                                style={{width: '100%'}}
                                onClick={() => resetImageMethod(stimulus)}>
                                Remove Media
                              </Button>
                            </div>  
                          </div>
                        </div>
                        <div className="widget fullWidth">
                          <div className='titleContainer'>
                            <div className='title'>
                              Hosted Media:
                            </div>
                          </div>
                          <div className='content'>    
                            <SwytchbackTextField
                              disabled={false}
                              id="inputLink"
                              className={`${classes.textField}`}
                              value={stimulus.product.buyPage}
                              onChange={handleProductLinkChange}
                              margin="normal"
                              variant="outlined"
                              placeholder="Enter a URL here..."
                            />
                          </div>
                        </div>
                        <div className="widget fullWidth">
                          <div className='titleContainer'>
                            <div className='title'>
                              Name:
                            </div>
                          </div>
                          <div className='content'>    
                            <SwytchbackTextField
                              disabled={false}
                              id="productNameLink"
                              className={`${classes.textField}`}
                              value={stimulus.product.name}
                              onChange={handleNameChange}
                              margin="normal"
                              variant="outlined"
                              placeholder="Enter name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Quant">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <div className='rowContainer fullWidthPane standardModalHeight'>
                      <StimulusQuantitativeComponent
                        swydget={swydget}
                        stimulus={stimulus}
                        updateStimulusCallback={updateStimulusTypeCallback}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={3} title="Qual">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <StimulusVerbatimComponent
                      swydget={swydget}
                      stimulus={stimulus}
                      updateStimulusCallback={updateStimulusVerbatimCallback}
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey={4} title="Variables">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <StimulusAttributesComponent
                      swydget={swydget}
                      stimulus={stimulus}
                      updateStimulusCallback={updateStimulusProductAttributesCallback}
                    />
                  </div>
                </div>
              </Tab> 
              { this.props.showSkipRules === true &&
              <Tab eventKey={5} title="Flow Logic">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <StimulusBranchLogicManagerComponent
                      swydget={swydget}
                      stimulus={stimulus}
                      updateStimulusCallback={updateStimulusBranchLogicCallback}
                    />
                  </div>
                </div>
              </Tab> 
              }
            </Tabs>
          </div>          
          
        </div>  
      </div>
    );
  };

}

export default CardEditor;
